import React, { FC } from 'react';

import { size } from 'lodash';
import { warningSharp } from 'ionicons/icons';
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';

import Tags from '../../../../components/Tags';
import Texto from '../../../../components/Texto';
import LinkProcesso from '../../../../components/LinkProcesso';

import './index.css';

const Acao: FC<AcaoType> = ({ acao, cor }: AcaoType) => {
  const primeiraAcao = acao[0];

  return (
    <IonGrid className="grid-acoes-calendario">
      <IonRow className="container-titulo-acoes">
        <Texto texto={primeiraAcao.acao.nome} tipo="h3" />
      </IonRow>

      {acao.map((a: any, index: number) => {
        const {
          processoDoGrupo,
          processoDoGrupo: { tags },
        } = a;

        return (
          <div key={a.id || index}>
            <IonRow className="container-linha-acoes">
              <div className="coluna-acoes-sem-padding coluna-acoes-img-poligono">
                <IonIcon
                  icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_poligono_minerario.svg`}
                  className="ga-estilo-icone-poligono-acoes"
                  style={{ fill: cor }}
                />
              </div>
              <div className="coluna-texto-acoes coluna-acoes-num-processos coluna-acoes-sem-padding">
                <LinkProcesso
                  processoMinerario={{
                    processoId: '',
                    eMinerario: true,
                    processoIdFormatado: '',
                    ...processoDoGrupo,
                  }}
                />
              </div>
              <div className="coluna-texto-acoes coluna-tag-acoes coluna-acoes-sem-padding">
                {size(tags) > 0 && <Tags tags={tags} cor={cor} />}
              </div>
            </IonRow>
            {a.sugestaoDeFechamento && (
              <IonRow className="gm-container-linha-acoes-sugestao-fechamento">
                <IonCol size="1" className="gm-container-linha-acoes-sugestao-fechamento">
                  <IonIcon icon={warningSharp} className="gm-icone-sugestao-fechamento" />
                </IonCol>
                <IonCol size="11" className="gm-container-linha-acoes-sugestao-fechamento">
                  <Texto
                    tipo="sugestao-fechamento"
                    texto={`${a.sugestaoDeFechamento?.evento?.descricaoFormatada} | ${a.sugestaoDeFechamento.dataDePublicacaoFormatada}`}
                  />
                </IonCol>
              </IonRow>
            )}
          </div>
        );
      })}
    </IonGrid>
  );
};

type AcaoType = {
  acao: any;
  cor?: string;
};

export default Acao;
