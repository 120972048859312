import { combineReducers } from 'redux';

// import calendarioReducer, * as fromCalendario from './calendario';
import calendarioReducer from './calendario';
import monitorarReducer, * as fromMonitorar from './monitorar';
import modaisReducer, * as fromModais from './modais';
import minerarioReducer, * as fromMinerario from './minerario';

export default combineReducers({
  modais: modaisReducer,
  monitorar: monitorarReducer,
  minerario: minerarioReducer,
  calendario: calendarioReducer,
});

// Modais
export const estaExibindoModalDetalhesDoProcesso: (state: Interface) => boolean = (state: Interface) =>
  fromModais.estaExibindoModalDetalhesDoProcesso(state.modais);
export const obterDadosDaModalDetalhesDoProcesso = (state: Interface) =>
  fromModais.obterDadosDaModalDetalhesDoProcesso(state.modais);

// Monitorar
export const obterProcessoParaAdicionar = (state: Interface) =>
  fromMonitorar.obterProcessoParaAdicionar(state.monitorar);

// Minerario
export const obterGruposMinerario = (state: Interface) => fromMinerario.obterGruposMinerario(state.minerario);
export const estaCarregandoGrupoMinerario = (state: Interface) =>
  fromMinerario.estaCarregandoGrupoMinerario(state.minerario);
export const estaRemovendoProcessoDoGrupoMinerario = ({ state, processoId }: any) =>
  fromMinerario.estaRemovendoProcessoDoGrupoMinerario({ state: state.minerario, processoId });
export const estaCarregandoProcessosDoGrupoMinerario = (state: Interface) =>
  fromMinerario.estaCarregandoProcessosDoGrupoMinerario(state.minerario);
export const obterProcessosDoGruposMinerario = (state: Interface, grupoId: number) =>
  fromMinerario.obterProcessosDoGruposMinerario(state.minerario, grupoId);
