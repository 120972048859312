import React, { FC } from 'react';

import { IonList, IonSkeletonText, IonListHeader, IonItem } from '@ionic/react';

import './index.css';
import { map } from 'lodash';

const ArrayTotalProcessos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Carregando: FC = () => (
  <IonList className="carregando-processos">
    <IonListHeader>
      <IonSkeletonText className="titulo" animated />
    </IonListHeader>

    {map(ArrayTotalProcessos, (item: number) => (
      <IonItem key={`item-${item}`} lines="none">
        <IonSkeletonText className="icone-processo" animated />

        <IonSkeletonText className="processo" animated />

        <IonSkeletonText className="separador" animated />

        <IonSkeletonText className="processo-titular" animated />
      </IonItem>
    ))}
  </IonList>
);

export default Carregando;
