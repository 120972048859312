import React, { FC, useState } from 'react';

import { IonRow, IonItem, IonGrid, IonButton, IonRippleEffect, IonSpinner } from '@ionic/react';

import './index.css';

interface Organizacao {
  onSelecionarOrganizacao: (organizacao: any) => void;
  organizacao: {
    nome: string;
    planoAtivo: string;
    subDominio: string;
    carregando: boolean;
  };
}

const Organizacao: FC<Organizacao> = ({ organizacao, onSelecionarOrganizacao }: Organizacao) => {
  const [carregando, setCarregando] = useState(false);
  return (
    <IonItem
      key={organizacao.nome}
      lines="none"
      className="organizacao"
      onClick={() => {
        setCarregando(true);
        onSelecionarOrganizacao(organizacao);
      }}
    >
      <IonGrid className="background-transparent">
        <IonRow className="background-transparent">
          <IonButton size="large" className="botao" expand="full">
            {carregando ? (
              <IonSpinner name="crescent" />
            ) : organizacao.planoAtivo ? (
              'Conta Pessoal'
            ) : (
              `Organização - ${organizacao.nome}`
            )}
            <IonRippleEffect type="unbounded"></IonRippleEffect>
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default Organizacao;
