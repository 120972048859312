import { combineReducers } from 'redux';

import dadosReducer from './dados';
import estaExibindoReducer from './estaExibindo';

import { EXIBIR_DETALHES_DO_PROCESSO, OCULTAR_DETALHES_DO_PROCESSO } from '../../../types';

const detalhesDoProcessoReducer = combineReducers({
  estaExibindo: estaExibindoReducer(EXIBIR_DETALHES_DO_PROCESSO, OCULTAR_DETALHES_DO_PROCESSO),
  dados: dadosReducer(EXIBIR_DETALHES_DO_PROCESSO, OCULTAR_DETALHES_DO_PROCESSO, ['processo']),
});

export default combineReducers({
  detalhesDoProcesso: detalhesDoProcessoReducer,
});

export const estaExibindoModalDetalhesDoProcesso = (state: any) => state.detalhesDoProcesso.estaExibindo;

export const obterDadosDaModalDetalhesDoProcesso = (state: any) => state.detalhesDoProcesso.dados;
