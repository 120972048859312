import React, { FC } from 'react';

import { IonSkeletonText, IonGrid, IonRow } from '@ionic/react';

import './index.css';

const Carregando: FC = () => (
  <>
    <IonGrid className="background-padrao">
      <IonRow style={{ justifyContent: 'center' }}>
        {/* <IonImg src={mapaUrl} /> */}
        <IonSkeletonText className="skeleton-imagem" animated />
      </IonRow>
    </IonGrid>

    <IonGrid className="background-padrao">
      <div className="dados">
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-pequeno" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-grande" animated />
          <IonSkeletonText className="skeleton-texto skeleton-medio" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-pequeno" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-medio" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-grande" animated />
          <IonSkeletonText className="skeleton-texto skeleton-pequeno" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-pequeno" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-grande" animated />
          <IonSkeletonText className="skeleton-texto skeleton-medio" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-pequeno" animated />
          <IonSkeletonText className="skeleton-texto skeleton-grande" animated />
        </div>
        <div className="item">
          <IonSkeletonText className="skeleton-label skeleton-medio" animated />
          <IonSkeletonText className="skeleton-texto skeleton-pequeno" animated />
        </div>
      </div>
    </IonGrid>
    <IonSkeletonText />
  </>
);

export default Carregando;
