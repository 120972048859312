import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
  IonRow,
  IonMenu,
  IonList,
  IonItem,
  IonIcon,
  IonGrid,
  IonText,
  IonTitle,
  IonLabel,
  IonHeader,
  IonAvatar,
  IonToolbar,
  IonContent,
  IonMenuToggle,
  // isPlatform,
} from '@ionic/react';
import {
  logOutOutline,
  calendarOutline,
  constructOutline,
  newspaperOutline,
  lockClosedOutline,
  chatbubbleOutline,
} from 'ionicons/icons';

import { recuperarIniciaisNome } from '../../utils';
import AuthService from '../../services/AuthService';
import { logoutUsuario as logoutUsuarioAction } from '../../actions';

interface Usuario {
  nomeAbreviado: string;
  avatar_url: {
    medio: string;
  };
}

const initialState = {
  nomeAbreviado: '',
  // eslint-disable-next-line @typescript-eslint/camelcase
  avatar_url: {
    medio: '',
  },
};

const MENU_HABILITAR = ['/calendar', '/gerenciar-processos'];

const Menu: React.FC = (props: any) => {
  const { history, location, logoutUsuario } = props;
  const dispatch = useDispatch();

  const [isLogged, setIsLogged] = useState(false);
  const [usuario, setUsuario] = useState<Usuario>(initialState);
  const [imagemError, setImagemError] = useState(false);
  const Auth = new AuthService();

  const carregarUsuario = async () => {
    const usuario = await Auth.getUsuario();
    setUsuario(usuario);
  };

  return (
    <IonMenu
      side="start"
      type="overlay"
      contentId="main"
      disabled={!MENU_HABILITAR.includes(location?.pathname)}
      onIonWillOpen={async () => {
        setIsLogged(await Auth.isLogged());
        carregarUsuario();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines="none">
          {isLogged && (
            <IonGrid style={{ padding: '20px' }}>
              <IonRow style={{ justifyContent: 'center' }}>
                <IonAvatar align-items-center>
                  <IonText>
                    {imagemError || !usuario.avatar_url ? (
                      <div
                        style={{
                          backgroundColor: '#2bb673',
                          color: 'white',
                          cursor: 'default',
                          textAlign: 'center',
                          userSelect: 'none',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          width: '50px',
                          height: '50px',
                          borderRadius: '100%',
                          lineHeight: '50px',
                          fontWeight: 'bold',
                        }}
                      >
                        {recuperarIniciaisNome({ usuario })}
                      </div>
                    ) : (
                      <img
                        onError={() => setImagemError(true)}
                        src={usuario.avatar_url.medio}
                        alt={usuario.nomeAbreviado}
                      />
                    )}
                  </IonText>
                </IonAvatar>
              </IonRow>

              <IonRow style={{ justifyContent: 'center' }}>
                <IonText>Olá, {usuario.nomeAbreviado}</IonText>
              </IonRow>
            </IonGrid>
          )}

          {isLogged && (
            <>
              <IonMenuToggle key="calendario" auto-hide="true">
                <IonItem
                  onClick={() => {
                    props.history.replace('/calendar');
                  }}
                >
                  <IonIcon slot="start" icon={calendarOutline} />
                  <IonLabel>Calendário</IonLabel>
                </IonItem>
              </IonMenuToggle>

              <IonMenuToggle key="gerenciar" auto-hide="true">
                <IonItem
                  onClick={() => {
                    props.history.replace('/gerenciar-processos');
                  }}
                >
                  <IonIcon slot="start" icon={constructOutline} />
                  <IonLabel>Gerenciar Processos</IonLabel>
                </IonItem>
              </IonMenuToggle>

              {/* <IonMenuToggle key="gerenciar" auto-hide="true">
                <IonItem
                  onClick={() => {
                    props.history.replace('/monitorar-processos');
                  }}
                >
                  <IonIcon slot="start" icon={constructOutline} />
                  <IonLabel>Monitorar Processos</IonLabel>
                </IonItem>
              </IonMenuToggle> */}
            </>
          )}

          <IonMenuToggle key="assistente-virtual" auto-hide="true">
            <IonItem
              onClick={() => {
                window.open('https://wa.link/jc6j8w', '_system', 'location=yes');
              }}
            >
              <IonIcon slot="start" icon={chatbubbleOutline} />
              <IonLabel>Assistente Virtual</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle key="termo-de-uso" auto-hide="true">
            <IonItem
              onClick={() => {
                window.open('https://www.jazida.com/termos-de-uso', '_system', 'location=yes');
              }}
            >
              <IonIcon slot="start" icon={newspaperOutline} />
              <IonLabel>Termo de uso</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle key="politica-de-privacidade" auto-hide="true">
            <IonItem
              onClick={() => {
                window.open('https://www.jazida.com/privacidade', '_system', 'location=yes');
              }}
            >
              <IonIcon slot="start" icon={lockClosedOutline} />
              <IonLabel>Políticas de Privacidade</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {isLogged && (
            <>
              <IonMenuToggle key="sair" auto-hide="false">
                <IonItem
                  onClick={() => {
                    logoutUsuario({ dispatch }).then(
                      () => {
                        Auth.logout();
                        setTimeout(() => {
                          history.replace('/login');
                        }, 200);
                      },
                      () => {
                        Auth.logout();
                        setTimeout(() => {
                          history.replace('/login');
                        }, 200);
                      },
                    );
                  }}
                >
                  <IonIcon slot="start" icon={logOutOutline} />
                  <IonLabel>Sair</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  logoutUsuario: logoutUsuarioAction,
});

export default withRouter(connect(mapStateToProps, mapActionToProps)(Menu));
