import BaseService from './BaseService';

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default class OnboardingService extends BaseService {
  async getFirstAccess() {
    const { value } = await Storage.get({ key: 'onboarding' });

    return value;
  }

  async setFirstAccess() {
    await Storage.set({
      value: 'true',
      key: 'onboarding',
    });
  }
}
