import { Plugins } from '@capacitor/core';

import BaseService from './BaseService';

const { Storage } = Plugins;

interface AssinaturaPremiumTypes {
  data: {
    origem: string;
  };
}

// export const obterDadosUsuario = dados =>
//   axiosAuthorization({
//     data: dados,
//     method: 'get',
//     url: '/usuarios/logado',
//   });

export default class UsuarioService extends BaseService {
  async assinaturaPremium({ data }: AssinaturaPremiumTypes) {
    return this.post({ path: '/interesse', data });
  }

  async obterDadosDoUsuarioLogado() {
    return this.get({ path: '/usuarios/logado' });
  }

  async adicionarPlanoAoUsuario({ data }: any) {
    return this.post({ path: '/planos/app-store', data });
  }

  async validateInAppProduto() {
    return this.post({
      data: {},
      fullpath:
        'https://validator.fovea.cc/v1/validate?appName=com.jazida.app&apiKey=2ac33221-96e4-4a72-bf7a-fe7f8c6f244b',
    });
  }

  async getAssinante() {
    const { value } = await Storage.get({
      key: 'eAssinante',
    });

    return value;
  }

  async setAssinante(assinante: any) {
    await Storage.set({
      key: 'eAssinante',
      value: JSON.stringify(assinante),
    });
  }

  async removerAssinate() {
    await Storage.remove({ key: 'eAssinante' });
  }
}
