

import {
  BAIXAR_DOCUMENTO,
  BAIXAR_DOCUMENTO_SUCCESS,
  BAIXAR_DOCUMENTO_FAILURE,
} from '../../types';

import DocumentosService from '../../services/DocumentosService';

const documentosService = new DocumentosService();

interface BaixarDocumentoInterface {
  erros: any;
  url: string;
  dispatch: any;
}

export const baixarDocumento = ({
  url,
  dispatch,
}: BaixarDocumentoInterface) => {
  dispatch({ type: BAIXAR_DOCUMENTO });

  return documentosService.baixarDocumento({ url }).then(resp => {
    dispatch({
      payload: resp.data,
      type: BAIXAR_DOCUMENTO_SUCCESS,
    });

    return Promise.resolve(resp);
  }, (erros) => {
    dispatch({
      erro: erros,
      type: BAIXAR_DOCUMENTO_FAILURE,
    });

    return Promise.reject(erros);
  });
}