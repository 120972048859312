import React, { FC } from 'react';

import './index.css';

const Tag: FC<TagTypes> = ({ tag, cor }: TagTypes) => {
  const { nome } = tag;

  return (
    <p
      style={{ border: `1px solid ${cor}`, color: cor }}
      className='componente-tag-p'
    >
      {nome || tag?.tag?.nome}
    </p>
  );
};

type TagTypes = {
  tag: any;
  cor: any;
};
export default Tag;
