import { combineReducers } from 'redux';

import gruposReducer, * as fromGrupos from './grupos';

export default combineReducers({
  grupos: gruposReducer,
});

export const obterGruposMinerario = (state: MinerarioInterface) => fromGrupos.obterGruposMinerario(state.grupos);
export const estaRemovendoProcessoDoGrupoMinerario = ({ state, processoId }: any) =>
  fromGrupos.estaRemovendoProcessoDoGrupoMinerario({ state: state.grupos, processoId });
export const estaCarregandoGrupoMinerario = (state: MinerarioInterface) =>
  fromGrupos.estaCarregandoGrupoMinerario(state.grupos);
export const estaCarregandoProcessosDoGrupoMinerario = (state: MinerarioInterface) =>
  fromGrupos.estaCarregandoProcessosDoGrupoMinerario(state.grupos);
export const obterProcessosDoGruposMinerario = (state: MinerarioInterface, grupoId: number) =>
  fromGrupos.obterProcessosDoGruposMinerario(state.grupos, grupoId);
