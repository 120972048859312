import { ActionInterface } from '../../../../../interfaces';

import {
  OBTER_GRUPOS_MINERARIO,
  OBTER_GRUPOS_MINERARIO_SUCCESS,
  OBTER_GRUPOS_MINERARIO_FAILURE,
} from '../../../../../types';

const INITIAL_STATE = false;

export default (state = INITIAL_STATE as any, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_GRUPOS_MINERARIO:
      return true;

    case OBTER_GRUPOS_MINERARIO_SUCCESS:
    case OBTER_GRUPOS_MINERARIO_FAILURE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const estaCarregandoGrupoMinerario = (state: Boolean) => state;