import React, { FC } from 'react';
import moment from 'moment';
import { IonRow, IonCol, IonLabel, IonIcon, IonButton } from '@ionic/react';
import { documentTextSharp } from 'ionicons/icons';
import Texto from '../../../components/Texto';

import './index.css';

const TAMANHO_FONTE_GRANDE = 20;

const SubHeader: FC<SubHeaderProps> = ({ data, linkDou }: SubHeaderProps) => (
  <IonRow className='linha-data-acoes-eventos'>
    <IonLabel className='container-titulo-data'>
      <Texto style={{ fontSize: TAMANHO_FONTE_GRANDE }} texto='• ' />
      <Texto
        texto={`${moment(data).format('DD/MM/YYYY')} • ${moment(data).format('dddd')}`}
        tipo='titulo-data'
        style={{ lineHeight: `${TAMANHO_FONTE_GRANDE}px` }}
      />
    </IonLabel>
    {linkDou.download_url && (
      <IonButton
        fill='clear'
        className='btn-dou-subheader'
        download={linkDou.download_url}
        onClick={() => window.open(linkDou.download_url, '_system', 'location=yes')}
      >
        <IonRow>
          <IonCol size='3' className='coluna-dou-subheader'>
            <IonIcon
              color='#000'
              icon={documentTextSharp}
              className='btn-icon-subheader'
            />
          </IonCol>
          <IonCol size='9' className='coluna-dou-subheader'>
            <Texto texto='VER DOU' className="btn-dou" tipo='texto-ver-dou' />
          </IonCol>
        </IonRow>
      </IonButton>
    )}
  </IonRow>
);

interface SubHeaderProps {
  data: Date;
  linkDou: any;
}

export default SubHeader;
