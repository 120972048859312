import { combineReducers } from 'redux';

import dadosReducer, * as fromDados from './dados';
import assinaturaReducer, * as fromAssinatura from './assinatura';

export default combineReducers({
  dados: dadosReducer,
  assinatura: assinaturaReducer,
});

export const obterDadosDoUsuarioLogado = (state: Usuario) => fromDados.obterDadosDoUsuarioLogado(state.dados);
export const estaAssinando = (state: Usuario) => fromAssinatura.estaAssinando(state.assinatura);
