import React, { FC } from 'react';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';

import './index.css';

const CardEmBranco: FC<CardEmBranco> = ({
  children,
  className,
}: CardEmBranco) => <div className={`fundo-card ${className}`}>{children}</div>;

const CardDeTexto: FC<CardDeTextoProps> = ({
  titulo,
  conteudo,
  subtitulo,
}: CardDeTextoProps) => (
  <div className='fundo-card'>
    <IonCardHeader className='fundo-header'>
      <IonCardTitle className='titulo-card'>{titulo}</IonCardTitle>
      <IonCardSubtitle className='subtitulo-card'>{subtitulo}</IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent className='conteudo-card'>{conteudo}</IonCardContent>
  </div>
);

interface CardEmBranco {
  children: React.ReactNode;
  className?: string;
}

interface CardDeTextoProps {
  titulo: string;
  conteudo: string;
  subtitulo?: string;
  className?: string;
}

export default CardEmBranco;
export { CardDeTexto, CardEmBranco };
