import BaseService from './BaseService';

interface BaixarDocumentoTypes {
  url: string;
}

export default class DocumentosService extends BaseService {
  async baixarDocumento({ url }: BaixarDocumentoTypes) {
    return this.get({ path: url, params: {} });
  }
}
