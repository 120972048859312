import { ActionInterface } from '../../../interfaces';
import {
  OBTER_LOGIN_SUCCESS,
  OBTER_LOGIN_FAILURE,
  SALVAR_DADOS_DO_USUARIO_SUCCESS,
  SALVAR_DADOS_DO_USUARIO_FAILURE,
  OBTER_DADOS_CONSUMO_DO_USUARIO_SUCCESS,
  LOGOUT_USUARIO_SUCCESS,
} from '../../../types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_LOGIN_SUCCESS:
    case SALVAR_DADOS_DO_USUARIO_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case OBTER_DADOS_CONSUMO_DO_USUARIO_SUCCESS: {
      return {
        ...state,
        ...{
          consumo: action.payload.data,
        },
      };
    }

    case OBTER_LOGIN_FAILURE:
    case SALVAR_DADOS_DO_USUARIO_FAILURE: {
      return INITIAL_STATE;
    }

    case LOGOUT_USUARIO_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const obterDadosDoUsuarioLogado = (state: State) => state;
