export const CRIAR_GRUPO_MINERARIO = 'CRIAR_GRUPO_MINERARIO';
export const CRIAR_GRUPO_MINERARIO_SUCCESS = 'CRIAR_GRUPO_MINERARIO_S';
export const CRIAR_GRUPO_MINERARIO_FAILURE = 'CRIAR_GRUPO_MINERARIO_F';

export const ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO = 'ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO';
export const ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_SUCCESS = 'ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_S';
export const ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_FAILURE = 'ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_F';

export const OBTER_GRUPOS_MINERARIO = 'OBTER_GRUPOS_MINERARIO'
export const OBTER_GRUPOS_MINERARIO_SUCCESS = 'OBTER_GRUPOS_MINERARIO_S'
export const OBTER_GRUPOS_MINERARIO_FAILURE = 'OBTER_GRUPOS_MINERARIO_F'