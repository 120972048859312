import BaseService from './BaseService';

export default class PushNotificationService extends BaseService {
  async postCadastrarDevice(dados: PostPushNotificationInterface) {
    return this.post({ path: '/usuarios/notificacao', data: dados, params: {} });
  }

  async deleteRegistroNotificacao() {
    return this.delete({ path: '/usuarios/notificacao' });
  }
}

interface PostPushNotificationInterface {
  token: string;
}
