/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import {
  IonCol,
  IonRow,
  IonPage,
  IonList,
  IonItem,
  IonIcon,
  IonText,
  IonGrid,
  IonLabel,
  IonToast,
  IonInput,
  IonSlide,
  IonButton,
  IonToggle,
  IonSlides,
  IonContent,
  IonSpinner,
  isPlatform,
  IonRippleEffect,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react';

import { connect, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import cn from 'classnames';
import {
  autenticar as autenticarAction,
  obterDadosConsumoUsuarioLogado as obterDadosConsumoUsuarioLogadoAction,
} from '../../actions';

import AuthService from '../../services/AuthService';
import OrganizacaoItem from './Organizacao';

import './index.css';

let slidesRef = {
  on: () => {},
  update: () => {},
  slideNext: () => {},
  slidePrev: () => {},
  allowSlideNext: false,
  allowSlidePrev: false,
  options: {
    allowSlideNext: false,
  },
};

let inputEmail: any = null;

const INITIAL_STATE = {
  loading: false,
  organizacoes: [],
  revealPass: false,
  mostrarErro: false,
  salvarUsuario: false,
  inputEmailBloqueado: false,
  mensagemErro: 'Ocorreu um erro ao tentar efetuar o Login! ',
  data: {
    email: '',
    senha: '',
  },
};

const Login: React.FC = (props: any) => {
  const { history, autenticar, obterDadosConsumoUsuarioLogado } = props;
  const dispatch = useDispatch();
  const Auth = new AuthService();

  const { control, handleSubmit, reset, formState, errors, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { ...INITIAL_STATE.data },
  });

  const [data, setData] = useState(INITIAL_STATE.data);
  const [loading, setLoading] = useState(INITIAL_STATE.loading);
  const [revealPass, setRevealPass] = useState(INITIAL_STATE.revealPass);
  const [mostrarErro, setMostrarErro] = useState(INITIAL_STATE.mostrarErro);
  const [mensagemErro, setMensagemErro] = useState(INITIAL_STATE.mensagemErro);
  const [salvarUsuario, setSalvarUsuario] = useState(INITIAL_STATE.salvarUsuario);
  const [organizacoes, setOrganizacoes] = useState<Array<any>>(INITIAL_STATE.organizacoes);
  const [inputEmailBloqueado, setInputEmailBloqueado] = useState(INITIAL_STATE.inputEmailBloqueado);

  /**
   *
   * @param data
   */
  const onSubmit = (data: any) => {
    if (formState.isValid === false) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('login_submit_form_invalid');
      }

      return;
    }

    setData(data);
    setLoading(true);

    autenticar(data, dispatch, true).then(
      (resp: any) => {
        const usuario = resp.data;

        if (window.firebase) {
          window.firebase.analytics().logEvent('login_submit_success');
        }

        if (usuario?.organizacoesDoUsuario?.length) {
          const usuarioNovo = {
            nome: usuario.nome,
            carregando: false,
            planoAtivo: usuario.planoAtivo.nome,
          };
          const organizacoes = usuario.organizacoesDoUsuario.map((ou: any) => ({
            carregando: false,
            nome: ou.organizacao.nome,
            subDominio: ou.organizacao.subDominio,
            ...ou,
          }));
          const opcoes: any = [...organizacoes, usuarioNovo];
          setOrganizacoes(opcoes);

          slidesRef.slideNext();

          if (window.firebase) {
            window.firebase.analytics().logEvent('login_submit_success_select_organization');
          }
        } else {
          obterDadosConsumoUsuarioLogado({ dispatch }).then((resp: any) => {
            reset();
            if (resp.data.processos.quantidadeDeProcessos === 0) {
              history.push('/monitorar-processos');
            } else {
              history.push('/calendar');
            }
          });
        }

        if (salvarUsuario) {
          Auth.loginSalvarUsuario(data.email);
        } else {
          Auth.removerUsuarioSalvo();
        }
      },
      (erro: any) => {
        if (window.firebase) {
          window.firebase.analytics().logEvent('login_submit_erro_request');
        }
        setLoading(INITIAL_STATE.loading);
        setMensagemErro(`${INITIAL_STATE.mensagemErro} ${erro?.response?.data?.erro}`);
        setMostrarErro(true);
      },
    );
  };

  /**
   *
   * @param _fieldName
   */
  // const mostrarErro = (_fieldName: string) => {
  //   let error = (errors as any)[_fieldName];
  //   return error ? (
  //     <div style={{ color: "red", fontWeight: "bold" }}>
  //       {error.message || "Campo obrigatório"}
  //     </div>
  //   ) : null;
  // };

  const onSelecionarOrganizacao = (organizacao: any) => {
    if (organizacao.subDominio) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('login_submit_success_select_organization_true');
      }

      Auth.setOrganizacao(organizacao);
      autenticar({ ...data, organizacao: organizacao.subDominio }, dispatch, false).then(() => {
        obterDadosConsumoUsuarioLogado({ dispatch }).then(() => {
          reset();
          history.push('/calendar');
        });
      });
    } else {
      if (window.firebase) {
        window.firebase.analytics().logEvent('login_submit_success_select_organization_false');
      }
      Auth.setOrganizacao(null);
      obterDadosConsumoUsuarioLogado({ dispatch }).then((resp: any) => {
        reset();
        if (resp.data.processos.quantidadeDeProcessos === 0) {
          history.push('/monitorar-processos');
        } else {
          history.push('/calendar');
        }
      });
    }
  };

  useIonViewWillEnter(async () => {
    const isLogged = await Auth.isLogged();
    const email = await Auth.obterUsuarioSalvo();

    if (email) {
      setSalvarUsuario(true);
      setInputEmailBloqueado(true);
      setData({
        ...data,
        email,
      });

      inputEmail.children[0].value = email;
      setValue('email', email);
    }

    if (!isLogged) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('login_view');
      }
      props.history.push('/login');
    } else {
      props.history.push('/calendar');
    }
  });

  useIonViewDidLeave(() => {
    setRevealPass(INITIAL_STATE.revealPass);
    setLoading(INITIAL_STATE.loading);
    setOrganizacoes(INITIAL_STATE.organizacoes);
    slidesRef.slidePrev();
  });

  return (
    <IonPage id="login">
      <IonContent
        className={cn({
          'android-app': isPlatform('android'),
        })}
        class="content"
        fullscreen
      >
        <IonSlides
          id="slides-login"
          ref={(node: any) => {
            slidesRef = node;
          }}
          options={{
            loop: false,
            initialSlide: 0,
            noSwiping: true,
            onlyExternal: false,
            allowTouchMove: false,
          }}
        >
          <IonSlide>
            <IonGrid>
              <IonRow>
                <div className="bg-verde"></div>
                <IonText className="titulo-header">
                  Seja <br /> Bem-vindo
                </IonText>
              </IonRow>

              <IonRow>
                <div className="jazida-logo">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512.png`} alt="Jazida.com" />
                  <IonText className="jazida-logo_tipo">calendário</IonText>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="background-transparent">
                  <IonList className="form" lines="none">
                    <IonItem
                      className={cn({
                        item: true,
                        'background-transparent': true,
                      })}
                      lines="none"
                    >
                      <IonGrid className="grid-usuario">
                        <IonRow>
                          <IonCol size="4">
                            <IonLabel className="jazida-label" color="primary" position="stacked">
                              Usuário <IonText color="danger">*</IonText>
                            </IonLabel>
                          </IonCol>

                          <IonCol>
                            <div className="bloco-usuario">
                              <IonToggle
                                color="primary"
                                checked={salvarUsuario}
                                className="ion-toggle-small"
                                onIonChange={(event) => {
                                  setInputEmailBloqueado(false);
                                  setSalvarUsuario(event.detail.checked);
                                  if (!event.detail.checked) {
                                    setData({
                                      ...data,
                                      email: INITIAL_STATE.data.email,
                                    });
                                  }
                                }}
                              />

                              <IonText className="bloco-usuario_texto">Lembrar meu usuário</IonText>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <Controller
                        name="email"
                        control={control}
                        onChangeName="onIonChange"
                        defaultValue={data.email}
                        as={
                          <IonInput
                            autocomplete="on"
                            inputmode="email"
                            disabled={inputEmailBloqueado}
                            ref={(node: any) => {
                              inputEmail = node;
                            }}
                          />
                        }
                        className={cn({
                          'input-jazida': true,
                          'input-erro': formState.isSubmitted && errors?.email,
                          'background-transparent': true,
                        })}
                        onChange={([selected]) => {
                          return selected.detail.value.trim();
                        }}
                        rules={{
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email inválido',
                          },
                        }}
                      />

                      {/* {mostrarErro("email")} */}
                    </IonItem>

                    <IonItem className="background-transparent" lines="none">
                      <IonLabel className="jazida-label" color="primary" position="stacked">
                        Senha <IonText color="danger">*</IonText>
                      </IonLabel>
                      <Controller
                        name="senha"
                        as={IonInput}
                        control={control}
                        onChangeName="onIonChange"
                        type={revealPass ? 'text' : 'password'}
                        className="background-transparent input-jazida input-jazida-senha"
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                        }}
                      />

                      <IonButton
                        slot="end"
                        className="btn-reveal"
                        onClick={() => {
                          setRevealPass(!revealPass);
                        }}
                      >
                        <IonIcon slot="icon-only" icon={revealPass ? eyeSharp : eyeOffSharp} />
                      </IonButton>

                      <a
                        className="btn-esqueceu"
                        href="#this"
                        onClick={() => {
                          if (window.firebase) {
                            window.firebase.analytics().logEvent('login_btn_esqueceu_senha');
                          }
                          window.open('https://www.jazida.com/recuperar-senha', '_system', 'location=yes');
                        }}
                      >
                        Esqueceu a senha?
                      </a>
                    </IonItem>
                  </IonList>

                  <IonButton expand="full" className="btn-join" type="submit">
                    {loading ? <IonSpinner name="crescent" /> : 'Entrar'}
                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                  </IonButton>

                  <IonGrid class="footer">
                    <IonRow>
                      <IonCol size="4">
                        <button
                          type="button"
                          className="btn-cadastrar"
                          onClick={() => {
                            if (window.firebase) {
                              window.firebase.analytics().logEvent('login_btn_cadastrar');
                            }

                            props.history.replace('/cadastrar');
                          }}
                        >
                          <IonText className="texto-novo-usuario">Novo usuário?</IonText>
                        </button>
                      </IonCol>

                      <IonCol size="8">
                        <button
                          type="button"
                          className="btn-cadastrar"
                          onClick={() => {
                            if (window.firebase) {
                              window.firebase.analytics().logEvent('login_btn_cadastrar');
                            }

                            props.history.replace('/cadastrar');
                          }}
                        >
                          <IonText className="texto-novo-usuario">Cadastre-se e monitore processos</IonText>
                        </button>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </form>
              </IonRow>
            </IonGrid>
          </IonSlide>

          <IonSlide>
            <IonGrid className="organizacao-grid">
              <IonRow>
                <div className="bg-verde bg-second"></div>
                <h3 className="titulo-header titulo-login">Escolha uma conta para fazer o login</h3>
              </IonRow>

              <IonRow>
                <IonList lines="none" className="lista-organizacoes background-transparent">
                  {organizacoes.map((organizacao: any) => (
                    <OrganizacaoItem
                      key={organizacao.nome}
                      organizacao={organizacao}
                      onSelecionarOrganizacao={(org: any) => {
                        onSelecionarOrganizacao(org);
                        reset();
                      }}
                    />
                  ))}
                </IonList>
              </IonRow>
            </IonGrid>

            <div className="jazida-logo-small">
              <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512.png`} alt="Jazida.com" />
            </div>
          </IonSlide>
        </IonSlides>

        <IonToast
          animated
          color="danger"
          duration={4000}
          position="bottom"
          isOpen={mostrarErro}
          message={mensagemErro}
          cssClass="toast-mensagem"
          onDidDismiss={() => {
            setMostrarErro(INITIAL_STATE.mostrarErro);
            setMensagemErro(INITIAL_STATE.mensagemErro);
          }}
          buttons={[
            {
              text: 'Fechar',
              role: 'cancel',
              handler: () => {
                setMostrarErro(INITIAL_STATE.mostrarErro);
                setMensagemErro(INITIAL_STATE.mensagemErro);
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  autenticar: autenticarAction,
  obterDadosConsumoUsuarioLogado: obterDadosConsumoUsuarioLogadoAction,
});

export default connect(mapStateToProps, mapActionToProps)(Login);
