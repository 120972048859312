import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  IonRow,
  IonCol,
  IonPage,
  IonGrid,
  IonText,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonInput,
  IonToast,
  IonFooter,
  IonButton,
  isPlatform,
  IonContent,
  IonPopover,
  IonSpinner,
  IonListHeader,
  IonItemOption,
  IonItemSliding,
  IonItemOptions,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';

import cn from 'classnames';
import { map, size } from 'lodash';
import MaskedInput from 'react-input-mask';
import { obterProcessosDeTexto } from '../../utils';
import {
  obterDadosDoUsuarioLogado as obterDadosDoUsuarioLogadoReducer,
  obterProcessoParaAdicionar as obterProcessoParaAdicionarReducer,
} from '../../reducers';
import {
  criarGrupoMinerario as criarGrupoMinerarioAction,
  salvarDadosDoUsuario as salvarDadosDoUsuarioAction,
  adicionarProcessosNoGrupo as adicionarProcessosNoGrupoAction,
  removerProcessoParaAdicionar as removerProcessoParaAdicionarAction,
  listarProcessosParaAdicionar as listarProcessosParaAdicionarAction,
  removerProcessosParaAdicionar as removerProcessosParaAdicionarAction,
  obterDadosConsumoUsuarioLogado as obterDadosConsumoUsuarioLogadoAction,
} from '../../actions';

import Texto from '../../components/Texto';
import AuthService from '../../services/AuthService';

import './index.css';

const Auth = new AuthService();
const ID_RELACAO_TITULAR = 1;

interface SaibaMaisInterface {
  mostrar: boolean;
  evento: Event | undefined;
}

interface AdicionarProcessoInterface {
  mensagem: string;
  carregando: boolean;
}

interface MensagensInterface {
  adicionarProcesso: {
    tipo: string;
    mensagem: string;
    mostrar: boolean;
  };
}

interface MonitorarInterface {
  carregando: boolean;
}

const INITIAL_VALUES = {
  processos: '',
};
const INITIAL_STATE = {
  saibaMais: {
    mostrar: false,
    evento: undefined,
  },
  adicionarProcesso: {
    mensagem: '',
    carregando: false,
  },
  monitorar: {
    carregando: false,
  },
  mensagens: {
    adicionarProcesso: {
      mensagem: '',
      mostrar: false,
      tipo: 'danger',
    },
  },
};

let slidingsRefs: any = {};

const MonitorarProcessos: React.FC = (props: any) => {
  const {
    history,
    usuario,
    criarGrupoMinerario,
    salvarDadosDoUsuario,
    processosParaAdicionar,
    adicionarProcessosNoGrupo,
    removerProcessoParaAdicionar,
    listarProcessosParaAdicionar,
    removerProcessosParaAdicionar,
    obterDadosConsumoUsuarioLogado,
  } = props;

  const dispatch = useDispatch();
  const { control, formState, errors, reset, getValues, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: { ...INITIAL_VALUES },
  });
  const [mensagens, setMensagens] = useState<MensagensInterface>(INITIAL_STATE.mensagens);
  const [monitorar, setMonitorar] = useState<MonitorarInterface>(INITIAL_STATE.monitorar);
  const [saibaMais, setSaibaMais] = useState<SaibaMaisInterface>(INITIAL_STATE.saibaMais);
  const [adiciconarProcessos, setAdicionarprocessos] = useState<AdicionarProcessoInterface>(
    INITIAL_STATE.adicionarProcesso,
  );

  slidingsRefs = {};

  const adicionarNovoProcesso = () => {
    const dados = getValues();
    const resp = obterProcessosDeTexto(dados.processos);
    let { processos } = resp;

    if (size(processosParaAdicionar) === 3) {
      setMensagens({
        ...mensagens,
        adicionarProcesso: {
          mostrar: true,
          tipo: 'warning',
          mensagem: 'Você já possui 3 processos selecionados!',
        },
      });

      return;
    }

    if (!processos) return;

    setAdicionarprocessos({
      ...adiciconarProcessos,
      carregando: true,
      mensagem: resp.mensagemLimiteProcessos || '',
    });

    processos = [...processos.validos, ...processos.invalidos];

    if (processos.length === 0) return;

    listarProcessosParaAdicionar({ processos, dispatch }).then(
      () => {
        setAdicionarprocessos({
          ...adiciconarProcessos,
          carregando: false,
        });

        reset();
      },
      (erros: any) => {
        setAdicionarprocessos({
          ...adiciconarProcessos,
          carregando: false,
        });

        setMensagens({
          ...mensagens,
          adicionarProcesso: {
            mostrar: true,
            tipo: 'danger',
            mensagem: erros.erro,
          },
        });
      },
    );
  };

  const mostrarBotaoDeRemover = () => {
    setTimeout(() => {
      if (size(slidingsRefs) > 0) {
        // eslint-disable-next-line no-unused-expressions
        slidingsRefs['sliding0']?.open();

        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          slidingsRefs['sliding0']?.close();
        }, 900);
      }
    });
  };

  const monitorarProcessosSelecionados = () => {
    const mensagemErroMonitorar = () => {
      setMensagens({
        ...mensagens,
        adicionarProcesso: {
          mostrar: true,
          tipo: 'danger',
          mensagem: 'Ocorreu um erro ao tentar monitorar os Processos :(! Entre em contato com o suporte.',
        },
      });
    };

    if (size(processosParaAdicionar) === 0) {
      setMensagens({
        ...mensagens,
        adicionarProcesso: {
          mostrar: true,
          tipo: 'warning',
          mensagem: 'Adicione ao menos 1 processo para monitorar!',
        },
      });

      return;
    }

    setMonitorar({
      ...monitorar,
      carregando: true,
    });

    criarGrupoMinerario({
      dispatch,
      dados: {
        descricap: '',
        cor: '#007f74',
        nome: 'Grupo de Processo App',
      },
    }).then((resp: any) => {
      if (resp.success) {
        adicionarProcessosNoGrupo({
          dispatch,
          grupoId: resp.data.id,
          processosId: map(processosParaAdicionar, (processo: any) => processo.id),
        }).then(
          (resposta: any) => {
            setMonitorar({
              ...monitorar,
              carregando: false,
            });

            if (resposta.success) {
              removerProcessosParaAdicionar({ dispatch });
              history.replace('/calendar');

              return;
            }

            mensagemErroMonitorar();
          },
          (erros: any) => {
            setMensagens({
              ...mensagens,
              adicionarProcesso: {
                mostrar: true,
                tipo: 'danger',
                mensagem: erros.erro,
              },
            });
          },
        );

        return;
      }

      setMonitorar({
        ...monitorar,
        carregando: false,
      });

      mensagemErroMonitorar();
    });
  };

  const ProcessoInput = (props: any) => {
    const { value, className, onIonChange, name } = props;

    return (
      <MaskedInput
        name={name}
        value={value}
        mask="999.999/9999"
        onChange={(e) => {
          e.persist();
          onIonChange(e.target.value);
        }}
      >
        {() => (
          <div
            className={cn({
              hydrated: true,
              'input-jazida': true,
              ios: isPlatform('ios'),
              md: isPlatform('android'),
              'background-transparent': true,
              'sc-ion-input-md-h': isPlatform('android'),
              'sc-ion-input-md-s': isPlatform('android'),
              'sc-ion-input-ios-h': isPlatform('ios'),
              'sc-ion-input-ios-s': isPlatform('ios'),
              [className]: true,
            })}
          >
            <input
              name={name}
              type="text"
              onChange={onIonChange}
              placeholder="Ex: 800.001/2016"
              className={cn({
                'native-input': true,
                'sc-ion-input-ios': isPlatform('ios'),
                'sc-ion-input-md': isPlatform('android'),
              })}
            />
          </div>
        )}
      </MaskedInput>
    );
  };

  useIonViewWillEnter(async () => {
    const isLogged = await Auth.isLogged();
    obterDadosConsumoUsuarioLogado({ dispatch });

    if (!isLogged) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('monitorar_processos_gratis_logout');
      }
      Auth.logout();
      history.replace('/login');
    } else {
      salvarDadosDoUsuario({}, dispatch);

      if (window.firebase) {
        window.firebase.analytics().logEvent('monitorar_processos_gratis');
      }
    }
  });

  useIonViewDidEnter(() => {
    mostrarBotaoDeRemover();
  });

  useEffect(() => {
    if (size(processosParaAdicionar) === 1) {
      setTimeout(() => {
        mostrarBotaoDeRemover();
      }, 500);
    }
  }, [processosParaAdicionar]);

  useEffect(() => {
    if (
      (!usuario?.planoAtivo?.eGratuito && usuario?.planoAtivo?.ePagante) ||
      usuario?.consumo?.processos?.quantidadeDeProcessos > 0
    ) {
      history.replace('/calendar');
    }
  }, [usuario.consumo]);

  return (
    <IonPage className="monitorar-processos">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <div className="logo-jazida__titulo">
              <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512-branca.png`} alt="Jazida.com" />
            </div>
            <div className="bg-verde"></div>
            <IonText className="titulo-header">
              Monitore <br />
              até 3 processos grátis
            </IonText>
          </IonRow>

          <IonRow>
            <form onSubmit={handleSubmit(adicionarNovoProcesso)} className="background-transparent">
              <IonList>
                <IonListHeader>
                  <h4 className="subtitulo">
                    Receba diariamente no seu e-mail a atualização de eventos, ações e documentos do SEI diretamente no
                    seu no seu celular e e-mail.
                  </h4>
                </IonListHeader>
                <IonItem lines="none" text-wrap>
                  <IonLabel className="label-default ion-text-wrap">
                    Buscar seu processo.
                    <>
                      <IonPopover
                        cssClass="popover"
                        event={saibaMais.evento}
                        isOpen={saibaMais.mostrar}
                        onDidDismiss={() => {
                          setSaibaMais(INITIAL_STATE.saibaMais);
                        }}
                      >
                        <p>Insira o número do processo (ex: 800001/2016) e clique no botão &quot;Adicionar&quot;.</p>
                      </IonPopover>

                      <IonButton
                        type="button"
                        fill="outline"
                        className="btn-link no-border-radius"
                        onClick={(e) =>
                          setSaibaMais({
                            ...saibaMais,
                            mostrar: true,
                            evento: e.nativeEvent,
                          })
                        }
                      >
                        Saiba mais
                      </IonButton>
                    </>
                  </IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonGrid className="no-padding" fixed>
                    <IonRow className="no-padding">
                      <IonCol className="no-padding" size="9">
                        <Controller
                          name="processos"
                          control={control}
                          as={<ProcessoInput />}
                          onChangeName="onIonChange"
                          placeholder="Ex: 800.001/2016"
                          className={cn({
                            'input-jazida': true,
                            'background-transparent': true,
                            'input-erro': formState.isSubmitted && errors?.processos,
                          })}
                          onChange={([selected]) => {
                            return selected;
                          }}
                          rules={{
                            required: true,
                          }}
                        />
                      </IonCol>

                      <IonCol className="vertical-full no-padding" size="3">
                        <IonButton
                          fill="solid"
                          expand="block"
                          onClick={adicionarNovoProcesso}
                          class="btn-vertical-full no-border-radius"
                        >
                          {adiciconarProcessos.carregando ? <IonSpinner name="crescent" /> : 'ADICIONAR'}
                        </IonButton>
                      </IonCol>
                    </IonRow>

                    <IonRow className="no-padding esconder-elemento">
                      <IonCol className="no-padding" size="9">
                        <Controller
                          as={IonInput}
                          name="processos2"
                          control={control}
                          onChangeName="onIonChange"
                          placeholder="Ex: 800.001/2016"
                          className={cn({
                            'input-jazida': true,
                            'background-transparent': true,
                            'input-erro': formState.isSubmitted && errors?.processos,
                          })}
                          onChange={([selected]) => {
                            return selected.detail.value;
                          }}
                          rules={{
                            required: true,
                          }}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>

                {size(processosParaAdicionar) > 0 && (
                  <IonListHeader>
                    <h3 className="titulo-processos-selecionados">Processos selecionados:</h3>
                  </IonListHeader>
                )}

                {processosParaAdicionar?.map((processo: any, index: string) => {
                  const titular =
                    processo.pessoas.find((pessoa: any) => pessoa.relacaoId === ID_RELACAO_TITULAR) ||
                    processo.pessoas[0];

                  return (
                    <IonItemSliding
                      key={processo.id}
                      ref={(node: any) => {
                        slidingsRefs[`sliding${index}`] = node;
                      }}
                    >
                      <IonItem lines="none" className="processo__selecionado">
                        <IonIcon
                          icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_poligono_minerario.svg`}
                          className="ga-estilo-icone-poligono-acoes processo__selecionado-icone"
                          style={{ fill: '#2bb673' }}
                        />
                        {processo.idFormatado}
                        {titular && (
                          <>
                            <Texto
                              style={{ fontSize: '20px' }}
                              texto="• "
                              className="processo__selecionado-separador"
                            />
                            <IonLabel className="processo__selecionado-titulo">{titular.nome}</IonLabel>
                          </>
                        )}
                      </IonItem>

                      <IonItemOptions side="end">
                        <IonItemOption
                          color="danger"
                          onClick={() => {
                            removerProcessoParaAdicionar({ dispatch, processoId: processo.id });
                            reset();
                          }}
                        >
                          Remover
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  );
                })}
              </IonList>
            </form>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol size="6" className="ion-text-end">
              <IonButton
                fill="outline"
                color="primary"
                className="no-border-radius"
                onClick={() => {
                  history.replace('calendar');
                }}
              >
                Cancelar
              </IonButton>
            </IonCol>
            <IonCol size="6" className="ion-text-start">
              <IonButton
                color="primary"
                className="no-border-radius"
                style={{
                  minWidth: '138px',
                }}
                onClick={() => {
                  monitorarProcessosSelecionados();
                }}
              >
                {monitorar.carregando ? <IonSpinner name="crescent" /> : 'Monitorar'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>

      <IonToast
        animated
        duration={4000}
        position="bottom"
        cssClass="toast-mensagem"
        color={mensagens.adicionarProcesso.tipo}
        isOpen={mensagens.adicionarProcesso.mostrar}
        message={mensagens.adicionarProcesso.mensagem}
        onDidDismiss={() => {
          setMensagens(INITIAL_STATE.mensagens);
        }}
        buttons={[
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              setMensagens(INITIAL_STATE.mensagens);
            },
          },
        ]}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: State) => ({
  usuario: obterDadosDoUsuarioLogadoReducer(state),
  processosParaAdicionar: obterProcessoParaAdicionarReducer(state),
});

const mapActionToProps = () => ({
  criarGrupoMinerario: criarGrupoMinerarioAction,
  salvarDadosDoUsuario: salvarDadosDoUsuarioAction,
  adicionarProcessosNoGrupo: adicionarProcessosNoGrupoAction,
  removerProcessoParaAdicionar: removerProcessoParaAdicionarAction,
  listarProcessosParaAdicionar: listarProcessosParaAdicionarAction,
  removerProcessosParaAdicionar: removerProcessosParaAdicionarAction,
  obterDadosConsumoUsuarioLogado: obterDadosConsumoUsuarioLogadoAction,
});

export default connect(mapStateToProps, mapActionToProps)(MonitorarProcessos);
