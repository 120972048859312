export const OBTER_DETALHES_DO_PROCESSO = 'OBTER_DETALHES_DO_PROCESSO';
export const OBTER_DETALHES_DO_PROCESSO_SUCCESS = 'OBTER_DETALHES_DO_PROCESSO_S';
export const OBTER_DETALHES_DO_PROCESSO_FAILURE = 'OBTER_DETALHES_DO_PROCESSO_F';

export const OBTER_PROCESSOS_PARA_ADICIONAR = 'OBTER_PROCESSOS_PARA_ADICIONAR';
export const OBTER_PROCESSOS_PARA_ADICIONAR_SUCCESS = 'OBTER_PROCESSOS_PARA_ADICIONAR_S';
export const OBTER_PROCESSOS_PARA_ADICIONAR_FAILURE = 'OBTER_PROCESSOS_PARA_ADICIONAR_F';

export const REMOVER_PROCESSO_PARA_ADICIONAR = 'REMOVER_PROCESSO_PARA_ADICIONAR';
export const REMOVER_PROCESSOS_PARA_ADICIONAR = 'REMOVER_PROCESSOS_PARA_ADICIONAR';

export const OBTER_PROCESSOS_DO_GRUPO_MINERARIO = 'OBTER_PROCESSOS_DO_GRUPO_MINERARIO';
export const OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS = 'OBTER_PROCESSOS_DO_GRUPO_MINERARIO_S';
export const OBTER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE = 'OBTER_PROCESSOS_DO_GRUPO_MINERARIO_F';

export const REMOVER_PROCESSOS_DO_GRUPO_MINERARIO = 'REMOVER_PROCESSOS_DO_GRUPO_MINERARIO';
export const REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS = 'REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_S';
export const REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE = 'REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_F';