import { combineReducers } from 'redux';

import interfaceReducer, * as fromInterface from './interface';
import entidadesReducer, * as fromEntidades from './entidades';
// import viewModelsReducer, * as fromViewModels from './view-models';
import usuarioReducer, * as fromUsuario from './usuario';

export const INITIAL_STATE = {
  usuario: {},
  interface: {},
  entidades: {},
  viewModels: {},
};

export default combineReducers({
  usuario: usuarioReducer,
  interface: interfaceReducer,
  entidades: entidadesReducer,
  // viewModels: viewModelsReducer,
});

// Interface
export const obterGruposMinerario = (state: State) => fromInterface.obterGruposMinerario(state.interface);
export const obterProcessoParaAdicionar = (state: State) => fromInterface.obterProcessoParaAdicionar(state.interface);
export const estaCarregandoGrupoMinerario = (state: State) =>
  fromInterface.estaCarregandoGrupoMinerario(state.interface);
export const obterDadosDaModalDetalhesDoProcesso = (state: State) =>
  fromInterface.obterDadosDaModalDetalhesDoProcesso(state.interface);
export const estaExibindoModalDetalhesDoProcesso = (state: State) =>
  fromInterface.estaExibindoModalDetalhesDoProcesso(state.interface);
export const estaCarregandoProcessosDoGrupoMinerario = (state: State) =>
  fromInterface.estaCarregandoProcessosDoGrupoMinerario(state.interface);
export const obterProcessosDoGruposMinerario = (state: State, grupoId: number) =>
  fromInterface.obterProcessosDoGruposMinerario(state.interface, grupoId);
export const estaRemovendoProcessoDoGrupoMinerario = ({ state, processoId }: any) =>
  fromInterface.estaRemovendoProcessoDoGrupoMinerario({ state: state.interface, processoId });

// Entidades
export const obterGrupo = (state: State, id: string) => fromEntidades.obterGrupo(state.entidades)(id);
export const obterProcesso = (state: State, id: string) => fromEntidades.obterProcesso(state.entidades)(id);

// Usuario
export const estaAssinando = (state: State) => fromUsuario.estaAssinando(state.usuario);
export const obterDadosDoUsuarioLogado = (state: State) => fromUsuario.obterDadosDoUsuarioLogado(state.usuario);
