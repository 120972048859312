import { uniq, groupBy } from 'lodash';

const PROCESSO_REG_EXP = /^([0-9]{2,6})\/([0-9]{4})$/;

const leftTrim = (texto: string, caracteres: string) => {
  if (!texto) {
    return texto;
  }

  return texto.replace(new RegExp(`^${caracteres}+`), '');
};

const normalizarProcesso = (processo: any) => {
  if (!processo) {
    return processo;
  }

  let retorno = processo.toString().trim().replace(/\./g, '');
  retorno = leftTrim(processo, '0');

  return retorno;
};

export const validarProcesso = (processo: any) => {
  if (!processo) {
    return false;
  }

  let retorno = processo.toString();
  retorno = normalizarProcesso(retorno);
  let match = retorno.match(PROCESSO_REG_EXP);

  if (!match) {
    return false;
  }

  match = {
    numero: match[1],
    ano: match[2],
  };

  const anoAtual = new Date().getFullYear();

  if (match.ano < 1900 || match.ano > anoAtual) {
    return false;
  }

  return true;
};

export const obterProcessosDeTexto = (input: any) => {
  if (!input) {
    return {
      validos: [],
      invalidos: [],
    };
  }

  let processos = input.toString().trim();
  let mensagemLimiteProcessos = '';

  processos = processos.replace(/\s/g, '\n');
  processos = processos.replace(/\t/g, '\n');
  processos = processos.replace(/;/g, '\n');
  processos = processos.replace(/,/g, '\n');
  processos = processos.replace(/\|/g, '\n');
  processos = processos.replace(/\./g, '');

  processos = processos
    .split('\n')
    .filter((processo: any) => processo)
    .filter((processo: any) => !/^[a-zA-Z]{1,}$/.test(processo.replace(/\./g, '')));

  mensagemLimiteProcessos = processos.length > 500 ? 'Número máximo (500) de processos ultrapassado na adição, ' : '';

  processos = processos.slice(0, 500);

  processos = processos.map(normalizarProcesso);
  processos = uniq(processos);
  processos = groupBy(processos, validarProcesso);
  processos.validos = processos.true || [];
  delete processos.true;

  processos.invalidos = processos.false || [];
  delete processos.false;

  return { processos, mensagemLimiteProcessos };
};

export const tratarErroNoRequeste = (erro: any) => {
  // @TODO: Tratar erro retornando um Array de erros;
  return erro?.response?.data?.erro;
};

export const parametroObrigatorio = (param: string) => {
  const parametroObrigatorioErro = new Error(`${param} é um paramêtro obrigatório.`);

  if (typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(parametroObrigatorioErro, parametroObrigatorio);
  }

  throw parametroObrigatorioErro;
};

// Remove uma propriedade do objeto usando desestruturação.
// @PARAMS: props: 'Nome da Propriedade', obj: {}

interface RemovePropsObjInterface {
  obj: object;
  props: string;
}

export const removePropObj = ({
  obj = parametroObrigatorio('obj'),
  props = parametroObrigatorio('props'),
}: RemovePropsObjInterface) => {
  const { [props]: o, ...rest }: any = obj;

  return rest;
};

export const url = (resource: any) => ['url(', resource, ')'].join('');

export const recuperarIniciaisNome = ({ usuario }: any) => {
  const iniciais = [];
  const maximoIniciais = 3;
  const nome = usuario.nome || '';
  const nomes = nome.split(' ');

  if (!nomes) {
    return nome.substring(0, 1);
  }

  for (let i = 0; i < nomes.length && i < maximoIniciais; i += 1) {
    iniciais.push(nomes[i].substring(0, 1));
  }

  return iniciais.join('');
};
