import {
  ASSINATURA_PLANO_PREMIUM,
  ASSINATURA_PLANO_PREMIUM_SUCCESS,
  ASSINATURA_PLANO_PREMIUM_FAILURE,
  ATUALIZAR_PERFIL_DO_USUARIO,
  ATUALIZAR_PERFIL_DO_USUARIO_SUCCESS,
  ATUALIZAR_PERFIL_DO_USUARIO_FAILURE,
  INICIAR_ASSINATURA,
  INICIAR_ASSINATURA_SUCCESS,
  INICIAR_ASSINATURA_FAILURE,
} from '../../types';

import UsuarioService from '../../services/UsuarioService';

const usuarioService = new UsuarioService();

interface AssinaturaPremiumInterface {
  erros: any;
  url: string;
  dispatch: any;
  data: {
    origem: string;
  };
}

export const assinaturaPremium = ({ data, dispatch }: AssinaturaPremiumInterface) => {
  dispatch({ type: ASSINATURA_PLANO_PREMIUM });

  return usuarioService.assinaturaPremium({ data }).then(
    (resp: any) => {
      dispatch({
        payload: resp.data,
        type: ASSINATURA_PLANO_PREMIUM_SUCCESS,
      });

      return Promise.resolve(resp);
    },
    (erros: unknown) => {
      dispatch({
        erro: erros,
        type: ASSINATURA_PLANO_PREMIUM_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};

export const adicionarPlanoAoUsuario = ({ data, dispatch }: any) => {
  dispatch({ type: ATUALIZAR_PERFIL_DO_USUARIO });

  return usuarioService.adicionarPlanoAoUsuario({ data }).then(
    (resp: any) => {
      dispatch({
        payload: resp.data,
        type: ATUALIZAR_PERFIL_DO_USUARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    },
    (erros: unknown) => {
      dispatch({
        erro: erros,
        type: ATUALIZAR_PERFIL_DO_USUARIO_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};

export const iniciarAssinatura = ({ dispatch }: any) => {
  dispatch({ type: INICIAR_ASSINATURA });
};

export const finalizarAssinaturaSucesso = ({ dispatch }: any) => {
  dispatch({ type: INICIAR_ASSINATURA_SUCCESS });
};

export const finalizarAssinaturaErro = ({ dispatch }: any) => {
  dispatch({ type: INICIAR_ASSINATURA_FAILURE });
};
