import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import moment from 'moment';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

import 'moment/locale/pt-br';

import { connect, useDispatch } from 'react-redux';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet, IonSplitPane, IonToast, isPlatform, IonText } from '@ionic/react';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';

import {
  obterDadosDoUsuarioLogado as obterDadosDoUsuarioLogadoReducer,
  estaExibindoModalDetalhesDoProcesso as estaExibindoModalDetalhesDoProcessoReducer,
  obterDadosDaModalDetalhesDoProcesso as obterDadosDaModalDetalhesDoProcessoReducer,
} from './reducers';

import {
  exibirModalDeDetalhesDoProcesso as exibirModalDeDetalhesDoProcessoAction,
  ocultarModalDeDetalhesDoProcesso as ocultarModalDeDetalhesDoProcessoAction,
} from './actions';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import AuthService from './services/AuthService';
import PushNotificationService from './services/PushNotificationService';

import Login from './pages/Login';
import Calendar from './pages/Calendar';
import Cadastrar from './pages/Cadastrar';
import Onboarding from './pages/Onboarding';
import MonitorarProcessos from './pages/MonitorarProcessos';
import GerenciarProcessos from './pages/GerenciarProcessos';

import Menu from './components/Menu';
import DetalhesDoProcesso from './components/DetalhesDoProcesso';

/* Theme variables */
import './theme/variables.css';

import './App.css';

const { PushNotifications } = Plugins;

moment.locale('pt-br', {
  calendar: {
    sameDay: '[Hoje] ',
    nextDay: '[Amanhã] ',
    nextWeek: ' ',
    lastDay: '[Ontem] ',
    lastWeek: ' ',
    sameElse: ' ',
  },
});

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDHAr4SjvKKHwQI-UUwtfjnrfe7cvWST5Y',
  authDomain: 'jazida-calendar-app-cc611.firebaseapp.com',
  databaseURL: 'https://jazida-calendar-app-cc611.firebaseio.com',
  projectId: 'jazida-calendar-app-cc611',
  storageBucket: 'jazida-calendar-app-cc611.appspot.com',
  messagingSenderId: '395359370677',
  measurementId: 'G-966F90JTCM',
  appID: '1:395359370677:web:cb37023f6a29be75d1edfc',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line @typescript-eslint/no-empty-function
let isUpdateAvailable = new Promise(() => {});
let verificarAtualizacao = true;

setTimeout(() => {
  isUpdateAvailable = window.isUpdateAvailable;
}, 1000);

const definirViewPort = () => {
  const viewPortMeta: any = document.getElementById('viewport-meta');

  const viewports = {
    default: viewPortMeta.getAttribute('content'),
    mobile: 'width=600',
  };

  // Change the viewport value based on screen.width
  const viewPortSet = () => {
    if (window.screen.width > 768) viewPortMeta.setAttribute('content', viewports.mobile);
    else viewPortMeta.setAttribute('content', viewports.default);
  };

  // Set the correct viewport value on page load
  viewPortSet();
};

window.onresize = function () {
  definirViewPort();
};

// definirViewPort();

const Auth = new AuthService();
const PushNotificationJazida = new PushNotificationService();
let eventosPushNotificationRegistrado = false;

const push = async (setNotificacao: any) => {
  // Register with Apple / Google to receive push via APNS/FCM
  const token = await PushNotificationJazida.getPush();
  let registrando = false;

  if (!token) {
    if (window.Capacitor.Plugins.PushNotifications.requestPermission) {
      window.Capacitor.Plugins.PushNotifications.requestPermission().then((result: any) => {
        if (result.granted) {
          PushNotifications.register();
        } else {
          if (window.firebase) {
            window.firebase.analytics().logEvent('push_notification_not_accepted');
          }
        }
      });
    } else {
      PushNotifications.register();
    }
  }

  if (!eventosPushNotificationRegistrado) {
    eventosPushNotificationRegistrado = true;
    // On succcess, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      if (!registrando) {
        registrando = true;
        PushNotificationJazida.setPush(token.value);
        PushNotificationJazida.postCadastrarDevice({ token: token.value }).then(() => {
          registrando = false;
        });
        if (window.firebase) {
          window.firebase.analytics().logEvent('push_notification_register');
        }
      }
    });

    // Some issue with your setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Erro ao tentar registrar: ', error);
      // @TODO: Fazer requeste quando apresenta erro.
      if (window.firebase) {
        window.firebase.analytics().logEvent('push_notification_error');
      }
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
      // let notif = this.state.notifications;
      // notif.push({ id: notification.id, title: notification.title, body: notification.body })
      // this.setState({
      //   notifications: notif
      // })

      setNotificacao({
        mostrar: true,
        titulo: notification.title,
        mensagem: notification.body,
      });

      if (window.firebase) {
        window.firebase.analytics().logEvent('push_notification_app_open');
      }
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('notification ActionPerformed :>> ', notification);

        if (window.firebase) {
          window.firebase.analytics().logEvent('push_notification_app_close');
        }
      },
    );
  }
};

window.pushGlobal = push;

const INITIAL_STATE = {
  mostrarUpdate: false,
  podeGerarToken: false,
  notificacao: {
    titulo: '',
    mensagem: '',
    mostrar: false,
  },
};

const App: React.FC = (props: any) => {
  const {
    usuario,
    dadosDaModalDetalhesDoProcesso,
    ocultarModalDeDetalhesDoProcesso,
    estaExibindoModalDetalhesDoProcesso,
  } = props;
  const dispatch = useDispatch();

  const [mostrarUpdate, setMostrarUpdate] = useState(INITIAL_STATE.mostrarUpdate);
  const [notificacao, setNotificacao] = useState(INITIAL_STATE.notificacao);
  const [podeGerarToken, setPodeGerarToken] = useState(INITIAL_STATE.podeGerarToken);

  setTimeout(() => {
    if (verificarAtualizacao) {
      verificarAtualizacao = false;
      isUpdateAvailable.then((isAvailable) => {
        if (isAvailable) {
          setMostrarUpdate(true);
        }
      });
    }
  }, 2000);

  const estaLogado = async () => {
    const logado = await Auth.isLogged();
    const pushToken = await PushNotificationJazida.getPush();

    if (logado && !pushToken) {
      setPodeGerarToken(true);
    }
  };

  estaLogado();

  useEffect(() => {
    // Configuração das Notificações.
    if (!isPlatform('mobileweb') && podeGerarToken) {
      // Usando IIFE
      (async () => {
        try {
          const logado = await Auth.isLogged();
          if (usuario.id && logado) {
            push(setNotificacao);
          }
        } catch (e) {
          if (window.firebase) {
            window.firebase.analytics().logEvent('push_notification_start_erro');
          }
        }
      })();
    }
  }, [podeGerarToken, usuario.id]);

  return (
    <IonApp className={isPlatform('desktop') ? 'jazida-desktop' : ''}>
      {isPlatform('desktop') ? (
        <div className="desktop">
          <div className="conteudo">
            <div className="jazida-logo">
              <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512.png`} alt="Jazida.com" />
              <IonText className="jazida-logo_tipo">calendário</IonText>
            </div>

            <h2>A plataforma Jazida Calendário só está disponível para dispositivos móveis.</h2>
            <p>A versão para Computador está em desenvolvimento.</p>
            <i>Atenciosamente Equipe Jazida.</i>
          </div>
        </div>
      ) : (
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />

            <IonRouterOutlet id="main">
              <Route path="/login" component={Login} exact={true} />
              <Route path="/calendar" component={Calendar} exact={true} />
              <Route path="/cadastrar" component={Cadastrar} exact={true} />
              <Route path="/onboarding" component={Onboarding} exact={true} />
              <Route path="/monitorar-processos" component={MonitorarProcessos} exact={true} />
              <Route path="/gerenciar-processos" component={GerenciarProcessos} exact={true} />
              <Route path="/" exact={true} render={() => <Redirect to="/onboarding" />} />
              <Redirect to="/" />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      )}

      <IonToast
        position="bottom"
        isOpen={mostrarUpdate}
        cssClass="toast-mensagem"
        onDidDismiss={() => setMostrarUpdate(INITIAL_STATE.mostrarUpdate)}
        message="Uma nova versão está disponível! Atualize seu Aplicativo."
        buttons={[
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              setMostrarUpdate(INITIAL_STATE.mostrarUpdate);
            },
          },
        ]}
      />

      <IonToast
        position="top"
        color="primary"
        duration={10000}
        cssClass="toast-mensagem"
        header={notificacao.titulo}
        isOpen={notificacao.mostrar}
        message={notificacao.mensagem}
        onDidDismiss={() => setNotificacao(INITIAL_STATE.notificacao)}
        buttons={[
          {
            text: 'X',
            role: 'cancel',
            handler: () => {
              setNotificacao(INITIAL_STATE.notificacao);
            },
          },
        ]}
      />

      {dadosDaModalDetalhesDoProcesso && (
        <DetalhesDoProcesso
          mostrarDetalhes={estaExibindoModalDetalhesDoProcesso}
          onFecharModal={() => {
            dispatch(ocultarModalDeDetalhesDoProcesso());
          }}
        />
      )}
    </IonApp>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: obterDadosDoUsuarioLogadoReducer(state),
  dadosDaModalDetalhesDoProcesso: obterDadosDaModalDetalhesDoProcessoReducer(state),
  estaExibindoModalDetalhesDoProcesso: estaExibindoModalDetalhesDoProcessoReducer(state),
});

const mapActionToProps = () => ({
  exibirModalDeDetalhesDoProcesso: exibirModalDeDetalhesDoProcessoAction,
  ocultarModalDeDetalhesDoProcesso: ocultarModalDeDetalhesDoProcessoAction,
});

export default connect(mapStateToProps, mapActionToProps)(App);
