import React, { FC } from 'react';

import { IonRow, IonIcon } from '@ionic/react';

import { map, size } from 'lodash';

import { CardEmBranco } from '../../../components/Card';
import Texto from '../../../components/Texto';

import Acao from './Acao';

import './index.css';

const GrupoAmbiental: FC<GrupoAmbientalType> = ({
  indice,
  tamanho,
  grupoAmbiental,
  tamanhoGruposMinerarios,
}: GrupoAmbientalType) => {
  const { cor, nome, acoes } = grupoAmbiental;

  const getClassNameDoCard = (index: any) => {
    // Caso seja o primeiro ou último grupo, terá estilo diferente
    // Caso seja card único
    if (tamanho === 1) {
      if (tamanhoGruposMinerarios === 0)
        return 'ga-card-principal-unico-sem-gm';
      else return 'ga-card-principal-unico';
    }
    // Caso seja o primeiro
    else if (index === 0) return 'ga-card-principal-primeiro';
    // Caso seja o último
    else if (index === tamanho - 1) return 'ga-card-principal-ultimo';
    // Intermediário
    else return 'ga-card-principal';
  };

  return (
    <CardEmBranco className={getClassNameDoCard(indice)}>
      <div className='ga-linha-titulo'>
        <div
          style={{ backgroundColor: cor }}
          className='ga-coluna-titulo-espaco-minimo'
        >
          <IonIcon
            icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_pasta.svg`}
            className='ga-icone-pasta-titulo'
            style={{ fill: cor }}
          />
        </div>

        <div className='ga-coluna-titulo-espaco-maximo'>
          <Texto
            texto={nome}
            tipo='titulo'
            style={{ color: cor }}
            className="titulo-grupo"
          />
        </div>

        <div className='ga-coluna-titulo-espaco-minimo'>
          <IonIcon
            icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_folha.svg`}
            className='ga-icone-titulo'
            style={{ fill: cor }}
          />
        </div>
      </div>

      <IonRow className='ga-linha-titulo-acoes'>
        {size(acoes) > 0 && (
          <IonRow className='ga-container-acoes-eventos-calendario'>
            <IonRow className='ga-subtitulo-processos-ambientais'>
              <Texto texto='Processos ambientais' tipo='subtitulo' />
            </IonRow>
            {map(acoes, (acao: any, index: Number) => (
              <Acao acao={acao} cor={cor} key={acao.id || index} />
            ))}
          </IonRow>
        )}
      </IonRow>
    </CardEmBranco>
  );
};

type GrupoAmbientalType = {
  indice?: any;
  tamanho?: any;
  grupoAmbiental: any;
  tamanhoGruposMinerarios?: any;
};

export default GrupoAmbiental;
