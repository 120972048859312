import BaseService from './BaseService';

interface CriarGrupoInterface {
  cor: string;
  nome: string;
  descricao?: string;
}

interface MonitorarNoGrupoInterface {
  grupoId: number;
  processosId: Array<string>;
}

export default class GruposService extends BaseService {
  async criar({ cor, nome, descricao }: CriarGrupoInterface) {
    return this.post({ path: '/grupos-de-processos', data: { cor, nome, descricao }});
  }

  async monitorar({ processosId, grupoId }: MonitorarNoGrupoInterface) {
    return this.post({ path: `/grupos-de-processos/${grupoId}/processos`, data: { processosId } });
  }

  async listar() {
    return this.get({ path: '/grupos-de-processos', params: { origens: ['minerarios'] } });
  }
}
