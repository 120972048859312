import React, { useState, useEffect } from 'react';
import mobiscroll from '@mobiscroll/react';
import { connect, useDispatch } from 'react-redux';
import {
  IonRow,
  IonPage,
  IonList,
  IonTitle,
  IonToast,
  IonButton,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonItemGroup,
  IonMenuButton,
  IonItemDivider,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from '@ionic/react';

import moment from 'moment';
import { map, find, forEach, groupBy } from 'lodash';

import { salvarDadosDoUsuario as salvarDadosDoUsuarioAction } from '../../actions';

import AuthService from '../../services/AuthService';
import ProcessosService from '../../services/ProcessosService';

import Texto from '../../components/Texto';
import SejaPremium from '../../components/SejaPremium';

import SubHeader from './SubHeader';
import Carregando from './Carregando/index';
import GrupoMinerario from './GrupoMinerario';
import GrupoAmbiental from './GrupoAmbiental';

import './index.css';

enum OpcoesSemana {
  anterior,
  proxima,
}

// interface Usuario {
//   name: string;
//   email: string;
//   position: string;
//   photo: string;
//   token: string;
// }

const obterDiasDaSemana = (diaAtual: Date) => {
  const diasDaSemana = [];
  const diaInicial = moment(diaAtual).startOf('week');
  const diaFinal = moment(diaInicial).endOf('week');
  let diaDaSemana = diaInicial;

  while (diaDaSemana < diaFinal) {
    diasDaSemana.push(diaDaSemana.toDate());
    diaDaSemana = diaDaSemana.clone().add(1, 'd');
  }

  return diasDaSemana;
};

const obterDataTimezoneBr = (data: Date) => {
  const dataComTimeZone = moment(data).add(3, 'hours');
  return new Date(dataComTimeZone.toDate());
};

const Calendar: React.FC = (props: any) => {
  const { history, salvarDadosDoUsuario } = props;

  const processos = new ProcessosService();
  const dispatch = useDispatch();

  const [mostrarErro, setMostrarErro] = useState(false);
  const [dataOffset, setDataOffset] = useState([] as any);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [gruposPorData, setGruposPorData] = useState([] as any);
  const [tamanhoDisponivel, setTamanhoDisponivel] = useState(0);
  const [tamanhoCabecalho, setTamanhoCabecalho] = useState(140);
  const [tamanhoFooterBotoes, setTamanhoFooterBotoes] = useState(0);
  const [carregarSemana, setCarregarSemana] = useState(false);
  const [dataVisivel, setDataVisivel] = useState(moment().startOf('week').toDate());
  const [semanaDeDatas, setSemanaDeDatas] = useState(obterDiasDaSemana(moment().startOf('week').toDate()));

  const Auth = new AuthService();

  // Calcula tamanho da tela sem o calendário e o header para ser utilizado na tela vazia
  const calcularTamanhoDaTelaSemCalendario = () => {
    const tamanhoMaximo = window.innerHeight;
    const tamanhoHeader = document.getElementById('header-calendario')?.offsetHeight || 0;
    const tamanhoCalendarioMS = document.getElementById('calendario-mobiscroll')?.offsetHeight || 0;
    const tamanhoFooter = document.getElementById('footer-proxima-semana')?.offsetHeight || 0;

    setTamanhoDisponivel(tamanhoMaximo - (tamanhoHeader + tamanhoCalendarioMS + tamanhoFooter + 5));
    setTamanhoCabecalho(tamanhoCalendarioMS);
  };

  const obterTamanhoDoRodapeAposCarregamento = () => {
    setTimeout(() => {
      const tamanhoFooter = document.getElementById('footer-proxima-semana')?.offsetHeight || 0;
      setTamanhoFooterBotoes(tamanhoFooter);
    }, 500);
  };

  const obterOffsetDeCadaDiaEDefinirAlturaNaView = () => {
    setTimeout(() => {
      const datas = map(gruposPorData, (gpd: any) => gpd.data);

      const offsetDeCadaData = map(datas, (d: any) => {
        const elementoRenderizado: any = document.getElementById(d);
        const tabOffsetTop = elementoRenderizado?.offsetTop;
        const tabOffsetBottom = elementoRenderizado?.offsetHeight;

        return {
          data: d,
          offsetMin: tabOffsetTop,
          offSetMax: tabOffsetTop + tabOffsetBottom,
        };
      });
      setDataOffset(offsetDeCadaData);
    }, 1000);
  };

  const carregarDadosDaSemana = () => {
    if (semanaDeDatas.length > 1 && carregarSemana) {
      const dataPrimeiroDiaDaSemana = semanaDeDatas[0];
      const dataUltimoDiaDaSemana = semanaDeDatas[semanaDeDatas.length - 1];

      const dataInicio = moment(dataPrimeiroDiaDaSemana, 'YYYY-MM-DD').startOf('day').toISOString();
      const dataFim = moment(dataUltimoDiaDaSemana, 'YYYY-MM-DD').startOf('day').toISOString();

      // @DEBUG: Utilizar para Debugar Semanas
      // const dataInicio = moment('2020-02-11', 'YYYY-MM-DD')
      //   .startOf('day')
      //   .toISOString();
      // const dataFim = moment('2020-02-12', 'YYYY-MM-DD')
      //   .startOf('day')
      //   .toISOString();

      setCarregandoDados(true);
      setCarregarSemana(false)
      processos.getAcoesEEventos({ dataFim, dataInicio }).then((response) => {
        if (response.success) {
          const novosDados = map(response.data, (dadosPorDia) => {
            const { data: dataDosDados, dou } = dadosPorDia;
            // Acessa o atributo "dados" de cada array dos valores por data
            const {
              gruposMinerarios: gruposASeremNormalizados,
              gruposAmbientais: gruposAmbientaisASeremNormalizados,
            } = dadosPorDia;

            // Normaliza os gruposMinerarios
            const gruposMinerariosNormalizados = map(gruposASeremNormalizados, (grupo: any) => {
              const gmEventosDou: any[] = [];
              const gmEventosCm: any[] = [];

              // forEach para fazer apenas um loop e decidir se cada evento é dou ou cm
              forEach(grupo.eventos, (eventoDoGrupo) => {
                if (eventoDoGrupo.publicadoNoDou) gmEventosDou.push(eventoDoGrupo);
                else if (eventoDoGrupo.publicadoNoCm) gmEventosCm.push(eventoDoGrupo);
              });

              return {
                id: grupo.id,
                cor: grupo.cor,
                nome: grupo.nome,
                descricao: grupo.descricao,
                quantidadeAcoes: grupo.quantidadeAcoes,
                acoes: groupBy(grupo.acoes, 'acao.id'),
                documentosSei: grupo.documentosSeiPorTipos,
                quantidadeDeProcessos: grupo.quantidadeDeProcessos,
                eventos: {
                  eventosDou: groupBy(gmEventosDou, 'evento.id'),
                  eventosCm: groupBy(gmEventosCm, 'evento.id'),
                },
              };
            });

            const gruposAmbientaisNormalizados = map(gruposAmbientaisASeremNormalizados, (grupo: any) => {
              return {
                id: grupo.id,
                cor: grupo.cor,
                nome: grupo.nome,
                descricao: grupo.descricao,
                acoes: groupBy(grupo.acoes, 'alarmeId'),
                quantidadeDeLicencas: grupo.quantidadeDeLicencas,
                quantidadeDeProcessos: grupo.quantidadeDeProcessos,
                quantidadeDeLicencasAtivas: grupo.quantidadeDeLicencasAtivas,
                // quantidadeAcoes: grupo.quantidadeAcoes,
                // quantidadeDeProcessos: grupo.quantidadeDeProcessos,
              };
            });

            return {
              dou,
              data: dataDosDados,
              gruposMinerarios: gruposMinerariosNormalizados,
              gruposAmbientais: gruposAmbientaisNormalizados,
            };
          });
          setCarregandoDados(false);
          setGruposPorData(novosDados);
        } else if (response?.error?.message === 499) {
          return false;
        } else {
          // Tratar erro
          setMostrarErro(true);
          // history.replace('/login');
        }
      });
    }
  };

  useEffect(() => {
    calcularTamanhoDaTelaSemCalendario();
  }, [gruposPorData]);

  useEffect(() => {
    obterTamanhoDoRodapeAposCarregamento();
  }, [carregandoDados]);

  useEffect(() => {
    obterOffsetDeCadaDiaEDefinirAlturaNaView();
  }, [gruposPorData]);

  // Carrega novos dados a serem apresentados (grupos minerários e ambientais)
  useEffect(() => {
    // !carregandoDados pois o evento do calendário dispara dois eventos ao mudar de aba
    carregarDadosDaSemana();
  }, [carregarSemana]);

  useIonViewWillEnter(async () => {
    const isLogged = await Auth.isLogged();

    setTimeout(() => {
      setSemanaDeDatas(obterDiasDaSemana(moment().startOf('week').toDate()));
      setCarregarSemana(true);
    }, 500);

    if (!isLogged) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('calendar_logout');
      }
      Auth.logout();
      history.replace('/login');
    } else {
      salvarDadosDoUsuario({}, dispatch);

      if (window.firebase) {
        window.firebase.analytics().logEvent('calendar');
      }
    }
  });

  useIonViewDidLeave(() => {
    setMostrarErro(false);
    setDataOffset([]);
    setCarregandoDados(false);
    setGruposPorData([]);
    setCarregarSemana(false);
    setDataVisivel(moment().startOf('week').toDate());
    setSemanaDeDatas(obterDiasDaSemana(moment().startOf('week').toDate()));
  });

  // Estabelece a nova semana de acordo com o clique para semana anterior ou próxima
  const getNovaSemanaASerVisualizada = (opcao: OpcoesSemana) => {
    if (opcao === OpcoesSemana.anterior) {
      const primeiroDiaDaSemanaAnterior = moment(semanaDeDatas[0]).subtract(1, 'week').toDate();

      setSemanaDeDatas(obterDiasDaSemana(primeiroDiaDaSemanaAnterior));
    } else {
      const primeiroDiaDaProximaSemana = moment(semanaDeDatas[0]).add(1, 'week').toDate();

      setSemanaDeDatas(obterDiasDaSemana(primeiroDiaDaProximaSemana));
    }
  };

  const getDatasDaPropsColorDoCalendario = () => {
    const novoArray: {
      d: Date;
      background: string;
      color?: string;
      cssClass?: string;
    }[] = [];

    // Apenas a data de cada grupo
    const dataDosGruposComDado = map(gruposPorData, (gpd: any) => gpd.data);

    forEach(semanaDeDatas, (d) => {
      /*
        gruposPorData possui apenas os dias com dados
        semanaDeDatas possui todos os dias da semana
        Os dias de semanaDeDatas que não estiverem no gruposPorData, terão coloração diferente
      */
      const diaMapeado = moment(d).format('YYYY-MM-DD');
      if (!dataDosGruposComDado.includes(diaMapeado)) {
        novoArray.push({
          d,
          background: '#ffffff',
          cssClass: 'estilo-circulo-de-datas-mobiscroll-calendar',
        });
      }
    });

    return novoArray;
  };

  // Obtém qual a data que deve ser marcada
  const getDataMarcada = () => {
    const novoArray: {
      d: Date;
    }[] = [];

    const dataDosGruposComDado = map(gruposPorData, (gpd: any) => gpd.data);
    const dataMarcada = moment(dataVisivel).format('YYYY-MM-DD');

    // Se a data marcada não estiver presente, retorna a primeira presente
    if (!dataDosGruposComDado.includes(dataMarcada))
      return [
        {
          d: moment(dataDosGruposComDado[0]).toDate(),
        },
      ];

    novoArray.push({ d: dataVisivel });

    return novoArray;
  };

  return (
    <IonPage className="container-page">
      <IonHeader id="header-calendario">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>Calendário</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        scrollEvents
        onIonScroll={(event) => {
          const posicaoDoEvento = event.detail.currentY;

          const elemento = find(
            dataOffset,
            (d: any) => d.offSetMax > posicaoDoEvento && d.offsetMin <= posicaoDoEvento,
          );

          const listaDeDados = document.getElementById('lista-de-dados-do-calendario');

          /*
            Se a posicao do scroll for menor que o tamanho da lista em px menos o tamanho do
            container (parte visível fora o calendário e o footer), significa que não está no fim da
            rolagem, então é atribuído ao dataVisível o elemento encontrado para o id da data
          */
          if (elemento && listaDeDados && posicaoDoEvento < listaDeDados?.offsetHeight - tamanhoDisponivel) {
            setDataVisivel(elemento.data);
          }

          /*
            Se a posicao do scroll for maior que o tamanho da lista em px menos o tamanho do
            container (parte visível fora o calendário e o footer), significa que está no fim da
            rolagem, então é atribuído ao dataVisível a última data com dados
          */
          if (listaDeDados && posicaoDoEvento > listaDeDados?.offsetHeight - tamanhoDisponivel) {
            const ultimaDataComDados = gruposPorData[gruposPorData.length - 1].data;
            if (ultimaDataComDados !== dataVisivel) {
              setDataVisivel(ultimaDataComDados);
            }
          }
        }}
      >
        <IonHeader className="calendario-mobiscroll" id="calendario-mobiscroll">
          <mobiscroll.Calendar
            weeks={1}
            readonly
            lang="pt-BR"
            type="hidden"
            // Tema customizado
            theme="jazida"
            display="inline"
            selectType="week"
            value={semanaDeDatas}
            controls={['calendar']}
            marked={getDataMarcada()}
            cssClass="estilo-mobiscroll-calendar"
            colors={getDatasDaPropsColorDoCalendario()}
            onPageChange={(evento: { firstDay: Date }) => {
              if (carregandoDados) {
                processos.cancelarUltimoGet();
              }

              const novaSemana = obterDiasDaSemana(evento.firstDay);
              setSemanaDeDatas(novaSemana);
              setCarregarSemana(!carregarSemana);

              if (window.firebase) {
                window.firebase.analytics().logEvent('calendar_change_week');
              }
            }}
          />
        </IonHeader>

        {carregandoDados ? (
          <Carregando />
        ) : gruposPorData.length ? (
          <IonList
            className="container-list"
            id="lista-de-dados-do-calendario"
            style={{ backgroundColor: '#f4f4f4', minHeight: tamanhoDisponivel }}
          >
            {map(gruposPorData, (dadosDoDia, index) => (
              <IonItemGroup key={index} className="container-group" id={dadosDoDia.data}>
                <IonItemDivider sticky className="container-data-do-dia" style={{ top: tamanhoCabecalho }}>
                  <SubHeader data={obterDataTimezoneBr(dadosDoDia.data)} linkDou={dadosDoDia.dou} />
                </IonItemDivider>

                {map(dadosDoDia.gruposMinerarios, (grupo: any, index: number) => (
                  <GrupoMinerario
                    grupoMinerario={grupo}
                    key={grupo?.id || index}
                    indice={index}
                    tamanho={dadosDoDia.gruposMinerarios.length}
                  />
                ))}

                {map(dadosDoDia.gruposAmbientais, (grupo: any, index: number) => (
                  <GrupoAmbiental
                    grupoAmbiental={grupo}
                    key={grupo.id || index}
                    indice={index}
                    tamanho={dadosDoDia.gruposAmbientais.length}
                    tamanhoGruposMinerarios={dadosDoDia.gruposMinerarios.length}
                  />
                ))}
              </IonItemGroup>
            ))}
          </IonList>
        ) : (
          <IonRow
            className="pagina-carregando"
            style={{
              height: tamanhoDisponivel - tamanhoFooterBotoes,
            }}
          >
            <Texto texto="Não existem pendências e eventos" />
            <Texto
              tipo="data-em-branco"
              className="texto-dias-data-em-branco"
              texto={`${moment(semanaDeDatas[0]).format('DD')} à ${moment(semanaDeDatas[6]).format('DD')}`}
            />
            <Texto tipo="data-em-branco" texto={moment(semanaDeDatas[0]).format('MMMM')} />
          </IonRow>
        )}

        {!carregandoDados && (
          <div id="footer-proxima-semana" className="linha-botoes-anterior-proxima-semana">
            <div className="coluna-botoes-anterior-proxima-semana">
              <IonButton
                className="botao-anterior-proxima-semana"
                onClick={() => getNovaSemanaASerVisualizada(OpcoesSemana.anterior)}
              >
                <Texto texto="Semana Anterior" className="botao-semana-texto" tipo="titulo-botao" />
              </IonButton>
            </div>

            <div className="coluna-botoes-anterior-proxima-semana">
              <IonButton
                className="botao-anterior-proxima-semana"
                onClick={() => getNovaSemanaASerVisualizada(OpcoesSemana.proxima)}
              >
                <Texto texto="Próxima Semana" className="botao-semana-texto" tipo="titulo-botao" />
              </IonButton>
            </div>
          </div>
        )}

        <IonToast
          animated
          color="danger"
          duration={4000}
          position="bottom"
          isOpen={mostrarErro}
          cssClass="toast-mensagem"
          onDidDismiss={() => setMostrarErro(false)}
          message="Ocorreu um erro ao tentar recuperar os dados do Calendário. Tente mais tarde ou entre em contato com o nosso suporte!"
          buttons={[
            {
              text: 'Fechar',
              role: 'cancel',
              handler: () => {
                setMostrarErro(false);
              },
            },
          ]}
        />
      </IonContent>

      <SejaPremium />
    </IonPage>
  );
};

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  salvarDadosDoUsuario: salvarDadosDoUsuarioAction,
});

export default connect(mapStateToProps, mapActionToProps)(Calendar);
