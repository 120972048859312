/* eslint-disable react/display-name */
import React, { FC } from 'react';

import { IonText } from '@ionic/react';

import { isArray } from 'lodash';

import './index.css';

const Textos: FC<TextosTypes> = ({ cor, tipo, texto, style, className }: TextosTypes) => {
  const mostrarTexto = () =>
    isArray(texto)
      ? texto.map((txt, index) => (
          <span key={`${txt}-${index}`} className="sub-texto">
            {txt}
          </span>
        ))
      : texto;

  const tiposDisponiveis: any = {
    comum: () => (
      <IonText className={`comum ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    titulo: () => (
      <IonText className={`titulo ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    subtitulo: () => (
      <IonText className={`subtitulo ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    h3: () => (
      <IonText className={`h3 ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    h4: () => (
      <IonText className={`h4 ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    tag: () => (
      <IonText className={`tag ${className}`} style={{ color: cor, ...style }}>
        {mostrarTexto()}
      </IonText>
    ),
    'titulo-botao': () => (
      <IonText className={`titulo-botao ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    'cinza-escuro': () => (
      <IonText className={`cinza-escuro ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    'titulo-data': () => (
      <IonText className={`titulo-data ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    'texto-ver-dou': () => (
      <IonText className={`texto-ver-dou ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    'data-em-branco': () => (
      <IonText className={`data-em-branco ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
    'sugestao-fechamento': () => (
      <IonText className={`sugestao-fechamento ${className}`} style={style}>
        {mostrarTexto()}
      </IonText>
    ),
  };

  return tipo ? tiposDisponiveis[tipo]() : tiposDisponiveis.comum();
};

Textos.defaultProps = {
  style: {},
};

type TextosTypes = {
  cor?: string;
  tipo?: string;
  style?: object;
  className?: string;
  texto: string | Array<string>;
};

export default Textos;
