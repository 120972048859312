import { AutenticarInterface } from '../../interfaces';
import {
  OBTER_LOGIN,
  OBTER_LOGIN_SUCCESS,
  OBTER_LOGIN_FAILURE,
  SALVAR_DADOS_DO_USUARIO,
  SALVAR_DADOS_DO_USUARIO_SUCCESS,
  SALVAR_DADOS_DO_USUARIO_FAILURE,
  OBTER_DADOS_CONSUMO_DO_USUARIO_SUCCESS,
  OBTER_DADOS_CONSUMO_DO_USUARIO_FAILURE,
  LOGOUT_USUARIO,
  LOGOUT_USUARIO_SUCCESS,
  LOGOUT_USUARIO_FAILURE,
} from '../../types';

import { tratarErroNoRequeste } from '../../utils';
import AuthService from '../../services/AuthService';
import PushNotificationService from '../../services/PushNotificationService';

const Auth = new AuthService();
const PushNotificationJazida = new PushNotificationService();

export const autenticar = (dados: AutenticarInterface, dispatch: any, formulario: boolean) => {
  dispatch({ type: OBTER_LOGIN });

  return Auth.login(dados.email, dados.senha, dados.token, dados.organizacao).then(
    (resp) => {
      const usuario = resp.data;

      if (!formulario && !usuario?.organizacoesDoUsuario?.length) {
        dispatch({
          payload: resp.data,
          type: OBTER_LOGIN_SUCCESS,
        });
      }

      return Promise.resolve(resp);
    },
    (erros) => {
      dispatch({
        erro: erros,
        type: OBTER_LOGIN_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};

export const salvarDadosDoUsuario = async (dados: any, dispatch: any) => {
  dispatch({ dados, type: SALVAR_DADOS_DO_USUARIO });

  return Auth.getUsuario().then(
    (resp) => {
      dispatch({
        payload: resp,
        type: SALVAR_DADOS_DO_USUARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    },
    (erros) => {
      dispatch({
        erro: erros,
        type: SALVAR_DADOS_DO_USUARIO_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};

export const obterDadosConsumoUsuarioLogado = async ({ dispatch }: any) => {
  return Auth.obterConsumoUsuarioLogado().then((resp: any) => {
    if (resp.success) {
      dispatch({
        payload: resp,
        type: OBTER_DADOS_CONSUMO_DO_USUARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(
      dispatch({
        type: OBTER_DADOS_CONSUMO_DO_USUARIO_FAILURE,
        erro: tratarErroNoRequeste(resp.error),
      }),
    );
  });
};

export const logoutUsuario = async ({ dispatch }: any) => {
  dispatch({ type: LOGOUT_USUARIO });

  return PushNotificationJazida.deleteRegistroNotificacao().then(
    (resp: any) => {
      if (resp.success) {
        dispatch({
          payload: resp,
          type: LOGOUT_USUARIO_SUCCESS,
        });

        return Promise.resolve(resp);
      }

      return Promise.reject(
        dispatch({
          erro: tratarErroNoRequeste(resp.error),
          type: LOGOUT_USUARIO_FAILURE,
        }),
      );
    },
    (erros) => {
      dispatch({
        erro: erros,
        type: LOGOUT_USUARIO_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};
