import { combineReducers } from 'redux';

import gruposReducer from './grupos';
import processosReducer from './processos';

export default combineReducers({
  grupos: gruposReducer,
  processos: processosReducer,
});

const getEntity = (entity: any) => {
  if (!entity) {
    return undefined;
  }

  return { ...entity };
};

interface ObterProcessoInterface {
  processos: any;
}

interface ObterGrupoInterface {
  grupos: any;
}

export const obterGrupo = (state: ObterGrupoInterface) => (id: string) => getEntity(state.grupos[id]);
export const obterProcesso = (state: ObterProcessoInterface) => (id: string) => getEntity(state.processos[id]);
