import BaseService from './BaseService';

export default class CadastrarService extends BaseService {
  async postCadastrar(dados: PostCadastrarTypes) {
    return this.post({ path: '/usuarios', data: dados, params: {} });
  }
}

interface PostCadastrarTypes {
  nome: string;
  email: string;
  senha: string;
  termo: boolean;
  empresa: string;
  telefone: string;
}
