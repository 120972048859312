/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  IonAlert,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSkeletonText,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonText,
  IonFooter,
  IonSpinner,
  IonRippleEffect,
  isPlatform,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonToast,
  IonLoading,
} from '@ionic/react';

import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2';
import { closeOutline, calendarOutline, helpOutline, notificationsOutline } from 'ionicons/icons';

import {
  assinaturaPremium as assinaturaPremiumAction,
  iniciarAssinatura as iniciarAssinaturaAction,
  salvarDadosDoUsuario as salvarDadosDoUsuarioAction,
  finalizarAssinaturaErro as finalizarAssinaturaErroAction,
  finalizarAssinaturaSucesso as finalizarAssinaturaSucessoAction,
} from '../../actions';
import {
  estaAssinando as estaAssinandoReducer,
  obterDadosDoUsuarioLogado as obterDadosDoUsuarioLogadoReducer,
} from '../../reducers';

import AuthService from '../../services/AuthService';
import UsuarioService from '../../services/UsuarioService';

import './index.css';

const INITIAL_STATE = {
  carregando: false,
  assinando: false,
  mostrarModalPremium: false,
  assinatura: {
    ofertas: {},
    produto: {},
    eAssinante: false,
    carregando: false,
  },
  alerta: {
    titulo: '',
    mensagem: '',
    buttons: null,
    subtitulo: '',
    mostrar: false,
    callback: () => {},
    params: {
      processoId: null,
    },
  },
  toast: {
    duration: 300,
    mostrar: false,
    posicao: 'bottom',
    mensagem: 'Aguarde estamos processando sua assinatura!',
  },
};

interface StateLocalAlertaInterface {
  titulo: string;
  mostrar: boolean;
  mensagem: string;
  subtitulo: string;
  callback: (params: any) => void;
  buttons: Array<{
    text: string;
    role?: string;
    cssClass: string;
    handler: () => void;
  }> | null;
  params: {
    processoId?: string | null;
  };
}

interface StateLocalAssinaturaInterface {
  ofertas: any;
  produto: any;
  eAssinante: boolean;
  carregando: boolean;
}

let idTimeOutAlertaSejaPremium: any = null;

const Auth = new AuthService();
const Usuario = new UsuarioService();
let ID_SET_INTERVAL_DADOS: any = null;
let TOTAL_TENTATIVAS: any = 20;

// const SejaPremium: FC<SejaPremiumProps> = ({ usuario }: SejaPremiumProps) => {
const SejaPremium: FC = (props: any) => {
  const {
    usuario,
    location,
    estaAssinando,
    assinaturaPremium,
    iniciarAssinatura,
    salvarDadosDoUsuario,
    finalizarAssinaturaErro,
    finalizarAssinaturaSucesso,
  } = props;
  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(INITIAL_STATE.carregando);
  const [assinatura, setAssinatura] = useState<StateLocalAssinaturaInterface>(INITIAL_STATE.assinatura);
  const [alerta, setAlerta] = useState<StateLocalAlertaInterface>(INITIAL_STATE.alerta);
  const [mostrarModalPremium, setMostrarModalPremium] = useState(INITIAL_STATE.mostrarModalPremium);
  // const [assinando, setAssinando] = useState(INITIAL_STATE.assinando);
  const [toast, setToast] = useState(INITIAL_STATE.toast);

  const onFecharModal = () => {
    if (!estaAssinando()) {
      setToast(INITIAL_STATE.toast);
      setAlerta(INITIAL_STATE.alerta);
      setCarregando(INITIAL_STATE.carregando);
      setAssinatura(INITIAL_STATE.assinatura);
      setMostrarModalPremium(INITIAL_STATE.mostrarModalPremium);

      return false;
    }

    setToast({
      ...toast,
      mostrar: true,
    });
  };

  const alertaErro = () => {
    setAlerta({
      ...alerta,
      mostrar: true,
      subtitulo: 'Opss!',
      titulo: 'Assinatura 10',
      mensagem: 'Ocorreu um erro ao tentar finalizar sua assinatura. Entraremos em contato em breve!',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'btn-nao',
          handler: () => {
            setAlerta({
              ...alerta,
              mostrar: false,
            });

            onFecharModal();
          },
        },
      ],
    });
  };

  const atualizarStateLocal = (eAssinate = false) => {
    setAssinatura({
      ...assinatura,
      carregando: false,
      eAssinante: eAssinate,
    });

    Usuario.setAssinante(true);
  };

  const verificarSeOPerfilFoiAtualizado = () => {
    Usuario.obterDadosDoUsuarioLogado().then(
      (resp) => {
        TOTAL_TENTATIVAS = TOTAL_TENTATIVAS - 1;

        if (resp.success && resp.data.planoAtivo.ePagante) {
          clearInterval(ID_SET_INTERVAL_DADOS);
          TOTAL_TENTATIVAS = 20;

          atualizarStateLocal(true);
          finalizarAssinaturaSucesso({ dispatch });

          // Atualizando Perfil do usuário
          Usuario.setToken({
            ...resp.data,
            token: Usuario.getToken(),
          });
          salvarDadosDoUsuario({}, dispatch);

          return false;
        }

        if (TOTAL_TENTATIVAS === 0) {
          clearInterval(ID_SET_INTERVAL_DADOS);

          alertaErro();

          atualizarStateLocal();
          finalizarAssinaturaErro({ dispatch });

          assinaturaPremium({
            dispatch,
            data: {
              nome: usuario.nome,
              email: usuario.email,
              telefone: usuario.telefone,
              origem: `Assinatura Premium com Atualizar Plano - Jazida Calendário APP - Backend não atualizou o perfil do usuário entrar em contato`,
            },
          });
        }
      },
      () => {
        clearInterval(ID_SET_INTERVAL_DADOS);

        alertaErro();

        atualizarStateLocal();

        assinaturaPremium({
          dispatch,
          data: {
            nome: usuario.nome,
            email: usuario.email,
            telefone: usuario.telefone,
            origem: `Assinatura Premium com Erro ou Problemas - Jazida Calendário APP - Erro ao tentar recuperar os dados do usuário logado.`,
          },
        });
      },
    );
  };

  const iniciarConfiguracaoPurchase = () => {
    // if (usuario.id && !assinatura.produto.id) {
    // Usando IIFE
    (async () => {
      try {
        const logado = await Auth.isLogged();

        if (logado) {
          InAppPurchase2.verbosity = InAppPurchase2.DEBUG;
          // Validar de Produção
          InAppPurchase2.validator =
            'https://validator.fovea.cc/v1/validate?appName=com.jazida.calendario2&apiKey=6cd84563-da05-4088-8136-858ab6499d9d';
          // Validar de Desenvolvimento
          // InAppPurchase2.validator =
          //   'https://validator.fovea.cc/v1/validate?appName=com.jazida.app&apiKey=2ac33221-96e4-4a72-bf7a-fe7f8c6f244b';

          const PRODUTO_ID = isPlatform('android') ? 'jazidaassinatura10' : 'jazidaassinatura';

          InAppPurchase2.register({
            id: PRODUTO_ID,
            type: InAppPurchase2.PAID_SUBSCRIPTION,
          });

          const produto = InAppPurchase2.get(PRODUTO_ID);

          if (!produto) {
            setCarregando(false);
            assinaturaPremium({
              dispatch,
              data: {
                nome: usuario.nome,
                email: usuario.email,
                telefone: usuario.telefone,
                origem: `Assinatura Premium com Erro ou Problemas - Jazida Calendário APP - Produto não encontrado`,
              },
            });

            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_error_sem_produto');
            }

            onFecharModal();
          } else if (produto.state === InAppPurchase2.INVALID) {
            setCarregando(false);
            assinaturaPremium({
              dispatch,
              data: {
                nome: usuario.nome,
                email: usuario.email,
                telefone: usuario.telefone,
                origem: `Assinatura Premium com Erro ou Problemas - Jazida Calendário APP - Produto Inválido.`,
              },
            });

            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_error_produto_invalido');
            }

            onFecharModal();
          } else if (produto.state === InAppPurchase2.REGISTERED) {
            setCarregando(true);
          } else if (!produto.canPurchase) {
            setCarregando(false);
            assinaturaPremium({
              dispatch,
              data: {
                nome: usuario.nome,
                email: usuario.email,
                telefone: usuario.telefone,
                origem: `Assinatura Premium com Erro ou Problemas - Jazida Calendário APP - Não pode comprar o Produto`,
              },
            });

            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_error_nao_pode_comprar');
            }

            onFecharModal();
          }

          InAppPurchase2.when(produto.id).approved((p: IAPProduct) => {
            if (p.transaction) {
              ID_SET_INTERVAL_DADOS = setInterval(() => {
                verificarSeOPerfilFoiAtualizado();
              }, 15000);

              assinaturaPremium({
                dispatch,
                data: {
                  nome: usuario.nome,
                  email: usuario.email,
                  telefone: usuario.telefone,
                  origem: 'Assinatura Premium com Sucesso - Jazida Calendário APP',
                },
              });

              if (window.firebase) {
                window.firebase.analytics().logEvent('iap_sucesso_venda_verificada');
              }

              p.verify();
            } else {
              // Se o usuário não clicar no botão a ultima transação não finalizada será encerrada.
              p.finish();
            }
          });

          InAppPurchase2.when(produto.id).verified((p: IAPProduct) => {
            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_sucesso');
            }

            p.finish();
          });

          InAppPurchase2.when(produto.id).unverified(() => {
            alertaErro();

            atualizarStateLocal();

            assinaturaPremium({
              dispatch,
              data: {
                nome: usuario.nome,
                email: usuario.email,
                telefone: usuario.telefone,
                origem: `Assinatura Premium com Problemas na Verificação da Ordem - Jazida Calendário APP - Produto`,
              },
            });

            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_erro_venda_nao_verificada');
            }
          });

          // InAppPurchase2.when(produto.id).updated((p: IAPProduct) => {
          //   if (p.owned) {
          //     console.log('Dono do Produto', p);
          //   } else {
          //     console.log('Não é Dono', p);
          //   }
          // });

          InAppPurchase2.error(() => {
            alertaErro();

            atualizarStateLocal();

            assinaturaPremium({
              dispatch,
              data: {
                nome: usuario.nome,
                email: usuario.email,
                telefone: usuario.telefone,
                origem: `Assinatura Premium com Erro ou Problemas - Jazida Calendário APP - Error request`,
              },
            });

            if (window.firebase) {
              window.firebase.analytics().logEvent('iap_erro_efetuar_compra');
            }
          });

          InAppPurchase2.ready(() => {
            setAssinatura({
              ...assinatura,
              produto: produto,
            });
            setCarregando(false);
          });

          InAppPurchase2.applicationUsername = () => usuario.id;
          if (!produto?.transaction?.id) {
            InAppPurchase2.autoFinishTransactions = true;
            InAppPurchase2.refresh();
          }
        }
      } catch (e) {
        onFecharModal();
      }
    })();
    // }
  };

  useEffect(() => {
    if (usuario?.planoAtivo?.eGratuito && !mostrarModalPremium && !isPlatform('desktop') && !isPlatform('mobileweb')) {
      clearTimeout(idTimeOutAlertaSejaPremium);

      idTimeOutAlertaSejaPremium = setTimeout(async () => {
        const temAssinatura = await Usuario.getAssinante();
        if (location.pathname === '/calendar' && !temAssinatura) {
          setAlerta({
            ...alerta,
            mostrar: true,
            titulo: 'Seja Premium',
            subtitulo: 'Monitore mais processos!',
            mensagem: 'Deseja se tornar assinante do Jazida?',
            callback: () => {
              iniciarConfiguracaoPurchase();
              setMostrarModalPremium(true);
            },
          });
        }
      }, 5000);
    }
  }, [usuario.planoAtivo]);

  return (
    <>
      <IonModal
        cssClass="modal-premium"
        isOpen={mostrarModalPremium}
        swipeToClose={estaAssinando()}
        onWillPresent={() => {
          // onFetchDados();
        }}
        onWillDismiss={() => {
          onFecharModal();
        }}
      >
        <IonHeader style={{ backgroundColor: '#2bb673' }} translucent>
          <IonToolbar style={{ '--background': '#2bb673' }}>
            <span className="swipe-close"></span>
            {carregando ? (
              <IonSkeletonText animated className="skeleton-titulo-modal" />
            ) : (
              <IonTitle style={{ color: '#fff' }}>Usuário Premium</IonTitle>
            )}
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  onFecharModal();
                }}
              >
                <IonIcon className="icon-color-white" slot="start" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent className="premium-conteudo" text-center>
          <div className="conteudo texto-center">
            <div className="jazida-logo">
              <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512.png`} alt="Jazida.com" />
              <IonText className="jazida-logo_tipo">calendário</IonText>
            </div>

            <div className="premium-detalhes">
              {assinatura.eAssinante ? (
                <>
                  <p>
                    Assinatura realizada com sucesso! <br /> Monitore até 10 processos <br /> e receba as notificações
                    <br /> das ações, eventos e documentos SEI.
                    <br /> Qualquer dúvida entre em <br /> contato com nosso suporte
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Assinatura Jazida permite o monitoramento de até 10 processos minerário no aplicativo. <br />
                    por {assinatura.produto?.price} por mês.
                  </p>
                  <IonList style={{ marginBottom: '20px' }}>
                    <IonListHeader style={{ color: '#2bb673' }}>Vantagens Assinatura Jazida</IonListHeader>

                    <IonItem>
                      <IonIcon slot="start" color="primary" icon={calendarOutline} />

                      <IonLabel>
                        <h3>Monitoramento</h3>
                        <p>Monitore até 10 processos minerários</p>
                      </IonLabel>
                    </IonItem>

                    <IonItem>
                      <IonIcon slot="start" color="primary" icon={notificationsOutline} />

                      <IonLabel>
                        <h3>Notificações</h3>
                        <p>Notificações diárias por e-mail e celular</p>
                      </IonLabel>
                    </IonItem>

                    <IonItem>
                      <IonIcon slot="start" color="primary" icon={helpOutline} />

                      <IonLabel>
                        <h3>Suporte</h3>
                        <p>Suporte online e telefone</p>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </>
              )}
            </div>
          </div>
        </IonContent>

        <IonFooter>
          {assinatura.eAssinante ? (
            <IonButton
              expand="full"
              type="button"
              className="btn-assinar"
              onClick={() => {
                onFecharModal();
              }}
            >
              {assinatura.carregando ? <IonSpinner name="crescent" /> : 'Calendário Jazida'}
              <IonRippleEffect type="unbounded"></IonRippleEffect>
            </IonButton>
          ) : (
            <IonButton
              expand="full"
              type="button"
              className="btn-assinar"
              onClick={() => {
                if (!assinatura.produto.canPurchase) {
                  alertaErro();

                  atualizarStateLocal();

                  assinaturaPremium({
                    dispatch,
                    data: {
                      nome: usuario.nome,
                      email: usuario.email,
                      telefone: usuario.telefone,
                      origem: `Assinatura Premium com Problemas para Iniciar a Assinatura - Jazida Calendário APP - Produto`,
                    },
                  });

                  return false;
                }

                setAssinatura({
                  ...assinatura,
                  carregando: true,
                });

                if (estaAssinando() || !assinatura.produto.canPurchase) {
                  setToast({
                    ...toast,
                    mostrar: true,
                  });

                  return false;
                }

                iniciarAssinatura({ dispatch });

                setTimeout(() => {
                  InAppPurchase2.order(assinatura.produto.id, {
                    applicationUsername: usuario.id,
                  });
                }, 1500);
              }}
            >
              {assinatura.carregando ? <IonSpinner name="crescent" /> : `Assinar ${assinatura.produto?.price}`}
              <IonRippleEffect type="unbounded"></IonRippleEffect>
            </IonButton>
          )}
        </IonFooter>
      </IonModal>

      <IonAlert
        header={alerta.titulo}
        isOpen={alerta.mostrar}
        cssClass="jazida-alerta"
        message={alerta.mensagem}
        subHeader={alerta.subtitulo}
        onDidDismiss={() => {
          setAlerta({
            ...alerta,
            mostrar: false,
          });
        }}
        buttons={
          alerta.buttons || [
            {
              text: 'Não',
              role: 'cancel',
              cssClass: 'btn-nao',
              handler: () => {
                setAlerta({
                  ...alerta,
                  mostrar: false,
                });
              },
            },
            {
              text: 'Sim',
              cssClass: 'btn-sim',
              handler: () => {
                alerta.callback(alerta.params);

                setAlerta({
                  ...alerta,
                  mostrar: false,
                });
              },
            },
          ]
        }
      />

      <IonToast
        position="bottom"
        isOpen={toast.mostrar}
        message={toast.mensagem}
        duration={toast.duration}
        cssClass="toast-mensagem"
        onDidDismiss={() => setToast(INITIAL_STATE.toast)}
      />

      <IonLoading message={'Carregando...'} cssClass="carregando-dados" isOpen={carregando} />
    </>
  );
};

const mapStateToProps = (state: State) => ({
  estaAssinando: () => estaAssinandoReducer(state),
  usuario: obterDadosDoUsuarioLogadoReducer(state) || {},
});

const mapActionToProps = () => ({
  assinaturaPremium: assinaturaPremiumAction,
  iniciarAssinatura: iniciarAssinaturaAction,
  salvarDadosDoUsuario: salvarDadosDoUsuarioAction,
  finalizarAssinaturaErro: finalizarAssinaturaErroAction,
  finalizarAssinaturaSucesso: finalizarAssinaturaSucessoAction,
});

export default withRouter(connect(mapStateToProps, mapActionToProps)(SejaPremium));
