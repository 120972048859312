export const OBTER_LOGIN = 'OBTER_LOGIN';
export const OBTER_LOGIN_SUCCESS = 'OBTER_LOGIN_S';
export const OBTER_LOGIN_FAILURE = 'OBTER_LOGIN_F';

export const SALVAR_DADOS_DO_USUARIO = 'SALVAR_DADOS_DO_USUARIO';
export const SALVAR_DADOS_DO_USUARIO_SUCCESS = 'SALVAR_DADOS_DO_USUARIO_S';
export const SALVAR_DADOS_DO_USUARIO_FAILURE = 'SALVAR_DADOS_DO_USUARIO_F';

export const OBTER_DADOS_CONSUMO_DO_USUARIO_SUCCESS = 'OBTER_DADOS_CONSUMO_DO_USUARIO_S';
export const OBTER_DADOS_CONSUMO_DO_USUARIO_FAILURE = 'OBTER_DADOS_CONSUMO_DO_USUARIO_F';
export const LOGOUT_USUARIO = 'LOGOUT_USUARIO';
export const LOGOUT_USUARIO_SUCCESS = 'LOGOUT_USUARIO_S';
export const LOGOUT_USUARIO_FAILURE = 'LOGOUT_USUARIO_F';
