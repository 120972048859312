export const ASSINATURA_PLANO_PREMIUM = 'ASSINATURA_PLANO_PREMIUM';
export const ASSINATURA_PLANO_PREMIUM_SUCCESS = 'ASSINATURA_PLANO_PREMIUM_S';
export const ASSINATURA_PLANO_PREMIUM_FAILURE = 'ASSINATURA_PLANO_PREMIUM_F';

export const ATUALIZAR_PERFIL_DO_USUARIO = 'ATUALIZAR_PERFIL_DO_USUARIO';
export const ATUALIZAR_PERFIL_DO_USUARIO_SUCCESS = 'ATUALIZAR_PERFIL_DO_USUARIO_S';
export const ATUALIZAR_PERFIL_DO_USUARIO_FAILURE = 'ATUALIZAR_PERFIL_DO_USUARIO_F';

export const INICIAR_ASSINATURA = ' INICIAR_ASSINATURA';
export const INICIAR_ASSINATURA_SUCCESS = ' INICIAR_ASSINATURA_S';
export const INICIAR_ASSINATURA_FAILURE = ' INICIAR_ASSINATURA_F';
