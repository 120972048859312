import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import Reducers from './reducers';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

declare global {
  interface Window {
    Capacitor: any;
    pushGlobal: any;
    isUpdateAvailable: any;
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const store = createStore(Reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
