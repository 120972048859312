import React, { FC, useEffect, useState } from 'react';

import {
  IonItem,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonListHeader,
  IonItemOption,
  IonItemSliding,
  IonItemOptions,
  IonRippleEffect,
  useIonViewDidEnter,
  IonAlert,
} from '@ionic/react';

import './index.css';
import { size } from 'lodash';

import Texto from '../../../components/Texto';
import LinkProcesso from '../../../components/LinkProcesso';

const ID_RELACAO_TITULAR = 1;
const slidingsRefs: any = {};

interface ListaProcessosPropsInterface {
  dispatch: any;
  // reset: () => unknown;
  processosIds: Array<any>;
  setMensagens: (dados: { tipo: string; mensagem: string; mostrar: boolean }) => unknown;
  estaRemovendoProcessoDoGrupoMinerario: (dados: { grupoId?: number; processoId: string }) => boolean;
  mensagens: {
    mostrar: boolean;
  };
  obterProcesso: (
    processoId: unknown,
  ) => {
    id: string;
    processoId?: string;
    pessoas: Array<{
      nome: string;
    }>;
  };
  removerProcessosDoGrupoMinerario: (dados: {
    dispatch: any;
    grupoId?: number;
    processosId: Array<unknown>;
  }) => {
    id: number;
    catch: (erros: any) => unknown;
  };
  grupoSelecionado: {
    cor: string;
    value?: number;
  };
}

interface StateLocalAlertaInterface {
  titulo: string;
  mostrar: boolean;
  mensagem: string;
  subtitulo: string;
  callback: (params: any) => void;
  params: {
    processoId?: string | null;
  };
}

const INITIAL_STATE = {
  alerta: {
    titulo: '',
    mensagem: '',
    subtitulo: '',
    mostrar: false,
    callback: () => {},
    params: {
      processoId: null,
    },
  },
};

const mostrarBotaoDeRemover = () => {
  setTimeout(() => {
    if (size(slidingsRefs) > 0) {
      // eslint-disable-next-line no-unused-expressions
      slidingsRefs['sliding0']?.open();

      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        slidingsRefs['sliding0']?.close();
      }, 900);
    }
  });
};

const ListaProcessos: FC<ListaProcessosPropsInterface> = ({
  dispatch,
  mensagens,
  processosIds,
  setMensagens,
  obterProcesso,
  grupoSelecionado,
  removerProcessosDoGrupoMinerario,
  estaRemovendoProcessoDoGrupoMinerario,
}: ListaProcessosPropsInterface) => {
  const [alerta, setAlerta] = useState<StateLocalAlertaInterface>(INITIAL_STATE.alerta);

  useIonViewDidEnter(() => {
    mostrarBotaoDeRemover();
  });

  useEffect(() => {
    if (size(processosIds) > 0) {
      setTimeout(() => {
        mostrarBotaoDeRemover();
      }, 1000);
    }
  }, [processosIds]);

  return (
    <>
      {size(processosIds) > 0 && (
        <IonListHeader>
          <h3 className="titulo-processos-selecionados">Processos:</h3>
        </IonListHeader>
      )}

      {processosIds?.map((processoId: any, index: number) => {
        const processo = obterProcesso(processoId);
        const titular: {
          nome?: string;
        } =
          processo.pessoas &&
          (processo.pessoas.find((pessoa: any) => pessoa.relacaoId === ID_RELACAO_TITULAR) || processo.pessoas[0]);

        return (
          <IonItemSliding
            key={processo.id}
            ref={(node: any) => {
              slidingsRefs[`sliding${index}`] = node;
            }}
          >
            <IonItem lines="none" className="processo__selecionado">
              <IonIcon
                style={{ fill: grupoSelecionado.cor }}
                className="ga-estilo-icone-poligono-acoes processo__selecionado-icone"
                icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_poligono_minerario.svg`}
              />
              <LinkProcesso
                style={{
                  top: '-2px',
                  color: '#000',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  position: 'relative',
                }}
                processoMinerario={{
                  processoId: '',
                  eMinerario: true,
                  processoIdFormatado: '',
                  ...processo,
                }}
              />
              {titular && (
                <>
                  <Texto style={{ fontSize: '20px' }} texto="• " className="processo__selecionado-separador" />
                  <IonLabel className="processo__selecionado-titulo">{titular.nome}</IonLabel>
                </>
              )}
            </IonItem>

            <IonItemOptions side="end">
              <IonItemOption
                color="danger"
                style={{
                  minWidth: '85px',
                }}
                onClick={() => {
                  setAlerta({
                    ...alerta,
                    mostrar: true,
                    titulo: 'Remover processo',
                    subtitulo: `Processo ${processo.processoId}`,
                    mensagem: `Tem certeza que deseja excluir o processo <b>${processo.processoId}</b>`,
                    callback: () => {
                      removerProcessosDoGrupoMinerario({
                        dispatch,
                        processosId: [processoId],
                        grupoId: grupoSelecionado.value,
                      }).catch((erros: any) => {
                        setMensagens({
                          ...mensagens,
                          mostrar: true,
                          tipo: 'danger',
                          mensagem: `${erros.erro}! Entre em contato com o suporte.`,
                        });
                      });
                    },
                  });
                }}
              >
                {estaRemovendoProcessoDoGrupoMinerario({
                  processoId: processoId,
                  grupoId: grupoSelecionado.value,
                }) ? (
                  <IonSpinner name="crescent" />
                ) : (
                  'Remover'
                )}
                <IonRippleEffect type="unbounded" />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        );
      })}

      <IonAlert
        header={alerta.titulo}
        isOpen={alerta.mostrar}
        cssClass="jazida-alerta"
        message={alerta.mensagem}
        subHeader={alerta.subtitulo}
        onDidDismiss={() => {
          setAlerta({
            ...alerta,
            mostrar: false,
          });
        }}
        buttons={[
          {
            role: 'cancel',
            text: 'Cancelar',
            cssClass: 'btn-cancelar',
            handler: () => {
              setAlerta({
                ...alerta,
                mostrar: false,
              });
            },
          },
          {
            text: 'Remover',
            cssClass: 'btn-remover',
            handler: () => {
              alerta.callback(alerta.params);

              setAlerta({
                ...alerta,
                mostrar: false,
              });
            },
          },
        ]}
      />
    </>
  );
};

export default ListaProcessos;
