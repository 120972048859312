import { ActionInterface } from '../../../interfaces';

import { remove, uniqBy } from 'lodash';

import {
  REMOVER_PROCESSO_PARA_ADICIONAR,
  REMOVER_PROCESSOS_PARA_ADICIONAR,
  OBTER_PROCESSOS_PARA_ADICIONAR_SUCCESS,
} from '../../../types';

const INITIAL_STATE: Array<Object> = [];

export default (state = INITIAL_STATE, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_PROCESSOS_PARA_ADICIONAR_SUCCESS: {
      const novoState = [ ...state ];

      return uniqBy([
        ...novoState,
        ...action.payload,
      ], (processo: any) => processo.id);
    }

    case REMOVER_PROCESSO_PARA_ADICIONAR: {
      const novoState = [ ...state ];

      remove(novoState, (processo: any) => processo.id === action.processoId);

      return novoState;
    }

    case REMOVER_PROCESSOS_PARA_ADICIONAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const obterProcessoParaAdicionar = (state: State) => state;