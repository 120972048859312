import React, { FC, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { eyeSharp, keyOutline, documentOutline } from 'ionicons/icons';
import { IonGrid, IonRow, IonIcon, IonButton, IonSpinner, IonRippleEffect, IonText } from '@ionic/react';

import { baixarDocumento as baixarDocumentoAction } from '../../../../actions';

import Texto from '../../../../components/Texto';
import LinkProcesso from '../../../../components/LinkProcesso';

import './index.css';

const DocumentoSei: FC<DocumentoSeiInterface> = ({ cor, tipo, baixarDocumento }: DocumentoSeiInterface) => {
  const { processos } = tipo;
  const [baixando, setBaixando] = useState<any>({});
  const dispatch = useDispatch();

  return (
    <IonGrid className="grid-acoes-calendario">
      <IonRow className="container-titulo-acoes">
        <Texto texto={tipo.nome} tipo="h3" />
      </IonRow>

      {processos.map((processo: ProcessoType, index: any) => {
        const { processoId, documentosSei } = processo;

        return (
          <div key={processoId || index}>
            {documentosSei.map((documento: DocumentoType, index: any) => {
              const { id, nome, estaEmSigilo, download_url, numeroDocumentoSei } = documento;

              return (
                <IonRow key={id || index} className="container-linha-acoes">
                  <div className="item-documento-sei coluna-texto-acoes coluna-acoes-num-processos coluna-acoes-sem-padding">
                    <div className="coluna-acoes-sem-padding coluna-acoes-icon-poligono">
                      <IonIcon
                        icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_poligono_minerario.svg`}
                        className="ga-estilo-icone-poligono-acoes"
                        style={{ fill: cor }}
                      />
                    </div>

                    <LinkProcesso
                      processoMinerario={{
                        // processoId: '',
                        // eMinerario: true,
                        processoIdFormatado: '',
                        ...processo,
                      }}
                    />
                    <span className="coluna-documento-separador">•</span>
                    <IonText className="coluna-documento-nome">
                      {estaEmSigilo ? (
                        <>
                          <IonIcon className="icone-documento" icon={keyOutline} />
                          Documento nº {numeroDocumentoSei}
                        </>
                      ) : (
                        <>
                          <IonIcon className="icone-documento" icon={documentOutline} />
                          {nome}
                        </>
                      )}
                    </IonText>
                    <div className="btn-container">
                      <IonButton
                        slot="end"
                        size="small"
                        download={download_url}
                        className="btn-visualizar-documento"
                        onClick={() => {
                          setBaixando({
                            ...baixando,
                            [index]: true,
                          });
                          baixarDocumento({
                            dispatch,
                            url: download_url,
                          }).then((resp: any) => {
                            setBaixando({
                              ...baixando,
                              [index]: false,
                            });

                            if (resp.success) {
                              window.open(resp.data.url, '_system', 'location=yes');
                            } else {
                            }
                          });
                        }}
                        style={{
                          '--background': cor,
                          '--background-hover': cor,
                          '--background-focused': cor,
                          '--background-activated': cor,
                        }}
                      >
                        <IonIcon icon={eyeSharp} />

                        {baixando[index] ? <IonSpinner name="crescent" /> : 'VER'}

                        <IonRippleEffect type="unbounded"></IonRippleEffect>
                      </IonButton>
                    </div>
                  </div>
                </IonRow>
              );
            })}
          </div>
        );
      })}
    </IonGrid>
  );
};

type DocumentoType = {
  nome: string;
  id: number | string;
  download_url: string;
  estaEmSigilo: boolean;
  numeroDocumentoSei: string;
};

type ProcessoType = {
  processoId: string;
  eMinerario: boolean;
  documentosSei: Array<DocumentoType>;
};

type baixarDocumentoType = (dados: any) => any;

interface DocumentoSeiInterface {
  cor?: string;
  baixarDocumento: baixarDocumentoType;
  tipo: {
    nome: string;
    processos: Array<ProcessoType>;
  };
}

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  baixarDocumento: baixarDocumentoAction,
});

export default connect(mapStateToProps, mapActionToProps)(DocumentoSei);
