import { ActionInterface } from '../../../interfaces';

import { keyBy } from 'lodash';

import { OBTER_GRUPOS_MINERARIO_SUCCESS } from '../../../types';

export default (state = {} as any, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_GRUPOS_MINERARIO_SUCCESS:
      return {
        ...state,
        ...keyBy(action.payload, 'id'),
      };

    default:
      return state;
  }
};
