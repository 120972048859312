import BaseService from './BaseService';

interface ObterAcoesEEventosTypes {
  dataFim: string;
  dataInicio: string;
}

interface ObterDetalhesDoProcessoInterface {
  path: string;
}

interface ListarProcessosInterface {
  processos: Array<unknown>;
}

interface ListarProcessosDoGrupoMinerarioInterface {
  grupoId: number;
}

interface RemoverProcessosDoGrupoMinerarioInterface {
  grupoId: number;
  processosId: Array<string>;
}
export default class ProcessosService extends BaseService {
  async getAcoesEEventos({ dataFim, dataInicio }: ObterAcoesEEventosTypes) {
    return this.get({ path: '/app-calendar', params: { dataFim, dataInicio } });
  }

  async obterDetalhesDoProcesso({ path }: ObterDetalhesDoProcessoInterface) {
    return this.get({ path, params: {} });
  }

  async listarProcessos({ processos }: ListarProcessosInterface) {
    return this.post({
      path: '/processos',
      params: {
        per_page: 1000,
        incluirGruposDeProcessos: true,
      },
      data: {
        in: processos,
      },
    });
  }

  async listarProcessosDoGrupoMinerario({ grupoId }: ListarProcessosDoGrupoMinerarioInterface) {
    return this.get({
      path: `/grupos-de-processos/${grupoId}/processos`,
      params: {
        per_page: 100,
      },
    });
  }

  async removerProcessosDoGrupoMinerario({ grupoId, processosId }: RemoverProcessosDoGrupoMinerarioInterface) {
    return this.delete({
      path: `/grupos-de-processos/${grupoId}/processos`,
      data: {
        processosId,
      },
    });
  }
}
