import { Plugins } from '@capacitor/core';
import axios from 'axios';

const { Storage } = Plugins;

let cancelar: any;

export default class BaseService {
  public baseURL: string;
  public authorization: string;

  // Initializing important variables
  constructor() {
    // this.baseURL = 'http://localhost:8000';
    // this.baseURL = 'https://api.greenstone.com.br';
    this.baseURL = 'https://api.jazida.com';
    // this.baseURL = 'https://a66277cc35b7.ngrok.io';
    this.authorization = '';
  }

  getAuthorization(novoToken: string) {
    if (this.authorization?.length) {
      return this.authorization;
    }

    const token = novoToken || this.getToken();
    if (token) {
      this.authorization = `Bearer ${token}`;
      return this.authorization;
    }

    return null;
  }

  async getToken() {
    const { value } = await Storage.get({ key: 'token' });

    return value;
  }

  async setToken(usuario: any) {
    await Storage.set({
      key: 'token',
      value: usuario.token,
    });

    await Storage.set({
      key: 'usuario',
      value: JSON.stringify(usuario),
    });
  }

  async getUsuario() {
    const { value } = await Storage.get({ key: 'usuario' });
    try {
      if (value) {
        return JSON.parse(value);
      }
    } catch {
      return value;
    }
  }

  async setOrganizacao(organizacao: any) {
    await Storage.set({
      key: 'organizacao',
      value: JSON.stringify(organizacao),
    });
  }

  async getOrganizacao() {
    const { value } = await Storage.get({ key: 'organizacao' });

    try {
      if (value) {
        return JSON.parse(value);
      }
    } catch {
      return value;
    }
  }

  async setPush(push: any) {
    await Storage.set({
      key: 'push',
      value: push,
    });
  }

  async getPush() {
    const { value } = await Storage.get({
      key: 'push',
    });

    return value;
  }

  async logout() {
    await Storage.remove({ key: 'push' });
    await Storage.remove({ key: 'token' });
    await Storage.remove({ key: 'usuario' });
    await Storage.remove({ key: 'organizacao' });
    await Storage.remove({ key: 'eAssinante' });
    window.localStorage.removeItem('usuario');
  }

  async get({ path, params = {} }: GetTypes) {
    try {
      const axiosUrl = this.baseURL + path;
      const headers = await this.obterHeaders();

      // const response = await axios.get(axiosUrl, { params, headers });
      const response = await axios({
        params,
        headers,
        baseURL: axiosUrl,
        cancelToken: new axios.CancelToken((cancel) => {
          cancelar = cancel;
        }),
      });

      return { success: true, data: response.data };
    } catch (error) {
      const { response } = error;

      if (response) {
        if (response.status === 401 || response.status === 400) {
          await this.logout();
        }
      }

      return { success: false, error };
    }
  }

  async post({ path, fullpath, params = {}, data = {} }: PostTypes) {
    try {
      const axiosUrl = fullpath || this.baseURL + path;
      const headers = await this.obterHeaders();

      const response = await axios({
        data,
        params,
        headers,
        method: 'post',
        baseURL: axiosUrl,
        cancelToken: new axios.CancelToken((cancel) => {
          cancelar = cancel;
        }),
      });

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async delete({ path, params = {}, data = {} }: DeleteTypes) {
    try {
      const axiosUrl = this.baseURL + path;
      const headers = await this.obterHeaders();

      const response = await axios({
        data,
        params,
        headers,
        method: 'delete',
        baseURL: axiosUrl,
        cancelToken: new axios.CancelToken((cancel) => {
          cancelar = cancel;
        }),
      });

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }

  async cancelarUltimoGet() {
    return cancelar && cancelar(499);
  }

  async obterHeaders() {
    const organizacao = await this.getOrganizacao();
    const token = await this.getToken();

    const headers: Header = {
      App: 'calendario',
      'x-organizacao': null,
      ...(token && { Authorization: `Bearer ${await this.getToken()}` }),
    };

    if (organizacao) {
      headers['x-organizacao'] = organizacao.subDominio;
    } else {
      delete headers['x-organizacao'];
    }

    return headers;
  }
}

interface GetTypes {
  data?: any;
  params?: any;
  path: string;
}

interface PostTypes {
  data: any;
  params?: any;
  path?: string;
  fullpath?: string;
}

interface DeleteTypes {
  data?: any;
  params?: any;
  path: string;
}
