import decode from 'jwt-decode';
import axios from 'axios';
import BaseService from './BaseService';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export default class AuthService extends BaseService {
  async login(email: string, senha: string, token: string | null | undefined, organizacao: string) {
    const headers: Header = {
      App: 'calendario',
      'x-organizacao': null,
      Authorization: token ? await this.getAuthorization(token) : `Basic ${btoa([email, senha].join(':'))}`,
    };

    if (organizacao) {
      headers['x-organizacao'] = organizacao;
    } else {
      delete headers['x-organizacao'];
    }

    return axios({
      headers,
      method: 'POST',
      baseURL: this.baseURL,
      url: '/usuarios/login',
    }).then(
      (resp) => {
        this.setToken(resp.data);

        return Promise.resolve(resp);
      },
      (erro) => {
        this.logout();

        return Promise.reject(erro);
      },
    );
  }

  async obterConsumoUsuarioLogado() {
    return this.get({ path: '/usuarios/logado/consumo' });
  }

  async isLogged() {
    const token = await this.getToken();

    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token: any) {
    try {
      interface DecodeInterface {
        exp: number;
      }
      const decoded: DecodeInterface = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      this.logout();

      return true;
    }
  }

  async loginSalvarUsuario(email: string) {
    await Storage.set({
      value: email,
      key: 'loginSalvarUsuario',
    });
  }

  async obterUsuarioSalvo() {
    const email = await Storage.get({ key: 'loginSalvarUsuario' });

    return email.value;
  }

  async removerUsuarioSalvo() {
    await Storage.remove({ key: 'loginSalvarUsuario' });
  }
}
