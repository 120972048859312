export default (exibir: string, ocultar: string, keys?: any) => (state = null, action: any) => {
  const keysArray = Array.isArray(keys) ? keys : [keys];

  switch (action.type) {
    case exibir: {
      const retorno = keysArray.reduce((c, key) => {
        const next = c;
        next[key] = action[key];
        return next;
      }, {});

      return retorno;
    }

    case ocultar:
      return null;

    default:
      return state;
  }
};
