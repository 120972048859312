import React, { FC } from 'react';

import { size } from 'lodash';
import { IonGrid, IonRow, IonImg } from '@ionic/react';

import Tags from '../../../../components/Tags';
import Texto from '../../../../components/Texto';
import LinkProcesso from '../../../../components/LinkProcesso';

import './index.css';

const Evento: FC<AcaoType> = ({ evento, cor }: AcaoType) => {
  const primeiroEvento = evento[0];

  return (
    <IonGrid className='grid-eventos-calendario'>
      <IonRow className='container-titulo-eventos'>
        <Texto texto={primeiroEvento.evento.descricaoFormatada} tipo='h3' />
      </IonRow>

      {evento.map((e: any, index: Number) => {
        const {
          processoDoGrupo: { tags },
        } = e;

        return (
          <IonRow key={e.id || index} className='container-linha-eventos'>
            <div className='coluna-eventos-sem-padding coluna-eventos-img-poligono'>
              <IonImg
                src={`${process.env.PUBLIC_URL}/assets/images/poligono-minerario.png`}
                className='estilo-icone-poligono-eventos'
                style={{ backgroundColor: cor }}
              />
            </div>
            <div className='coluna-eventos-sem-padding coluna-texto-eventos coluna-eventos-num-processos '>
              <LinkProcesso processoMinerario={{
                processoId: '',
                eMinerario: true,
                processoIdFormatado: '',
                ...e.processoDoGrupo,
              }} />
            </div>
            <div className='coluna-eventos-sem-padding coluna-texto-eventos coluna-tag-eventos'>
              {
                size(tags) > 0 && (
                  <Tags tags={tags} cor={cor} />
                )
              }
            </div>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

type AcaoType = {
  cor?: string;
  evento: any;
};

export default Evento;
