import React, { FC } from 'react';

import { size } from 'lodash';
import { IonGrid, IonRow, IonIcon } from '@ionic/react';

import Tags from '../../../../components/Tags';
import Texto from '../../../../components/Texto';
import LinkProcesso from '../../../../components/LinkProcesso';

import './index.css';

const Acao: FC<AcaoType> = ({ acao, cor }: AcaoType) => {
  const primeiraAcao = acao[0];

  const obterInformacaoASerApresentada = (acaoMapeada: any) => {
    if (acaoMapeada.licencaAmbiental) {
      return `Licença ${acaoMapeada.licencaAmbiental.numero}`;
    } else if (acaoMapeada.processoAmbiental) {
      return `Processo ${acaoMapeada.processoAmbiental.idFormatado}`;
    } else {
      return 'Sem processo ou licença vinculados';
    }
  };

  return (
    <IonGrid className='ga-grid-acoes'>
      <IonRow className='ga-container-titulo-acoes'>
        <Texto texto={primeiraAcao?.alerta?.acao?.nome} tipo='h3' />
      </IonRow>

      {acao.map((acaoAmbiental: any, index: Number) => {
        const tags = acaoAmbiental?.processoAmbiental?.tags || [];

        return (
          <IonRow key={acaoAmbiental.id || index} className='ga-container-linha-acoes'>
            <div className='ga-coluna-acoes-sem-padding ga-coluna-acoes-img-poligono'>
              <IonIcon
                style={{ fill: cor }}
                className='ga-icone-processos-ambientais'
                icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_folha.svg`}
              />
            </div>
            <div className='ga-coluna-acoes-sem-padding ga-coluna-texto-acoes ga-coluna-acoes-num-processos'>
              {
                !acaoAmbiental.licencaAmbiental && acaoAmbiental.processoAmbiental
                  ? <LinkProcesso processoAmbiental={{
                    cor,
                    processoId: '',
                    eAmbiental: true,
                    processoIdFormatado: '',
                    texto: obterInformacaoASerApresentada(acaoAmbiental),
                    ...acaoAmbiental.processoAmbiental,
                  }} />
                  : <Texto texto={obterInformacaoASerApresentada(acaoAmbiental)} tipo='comum' />
              }
            </div>
            <div className='ga-coluna-acoes-sem-padding ga-coluna-texto-acoes ga-coluna-tag-acoes '>
              {
                size(tags) > 0 && (
                  <Tags tags={tags} cor={cor} />
                )
              }
            </div>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

type AcaoType = {
  acao: any;
  cor?: string;
};

export default Acao;
