import { combineReducers } from 'redux';

import resultadoReducer, * as fromResultado from './resultado';
import processosReducer, * as fromProcessos from './processos';
import carregandoReducer, * as fromCarregando from './carregando';

interface GruposInterface {
  processos: object;
  carregando: boolean;
  resultado: Array<string>;
}

export default combineReducers({
  resultado: resultadoReducer,
  processos: processosReducer,
  carregando: carregandoReducer,
});

// Carregando
export const estaCarregandoGrupoMinerario = (state: GruposInterface) =>
  fromCarregando.estaCarregandoGrupoMinerario(state.carregando);

// Resultado
export const obterGruposMinerario = (state: GruposInterface) => fromResultado.obterGruposMinerario(state.resultado);

// Processos
export const estaRemovendoProcessoDoGrupoMinerario = ({ state, processoId }: any) =>
  fromProcessos.estaRemovendoProcessoDoGrupoMinerario({ state: state.processos, processoId });
export const estaCarregandoProcessosDoGrupoMinerario = (state: GruposInterface) =>
  fromProcessos.estaCarregandoProcessosDoGrupoMinerario(state.processos);
export const obterProcessosDoGruposMinerario = (state: GruposInterface, grupoId: number) =>
  fromProcessos.obterProcessosDoGruposMinerario(state.processos, grupoId);
