import React, { FC } from 'react';

import { IonList, IonItemGroup, IonSkeletonText, IonGrid, IonRow, IonCol } from '@ionic/react';

import { CardEmBranco } from '../../../components/Card';

import './index.css';

const Carregando: FC = () => (
  <IonList
    style={{ backgroundColor: '#f4f4f4' }}
  >
    <IonItemGroup>
      <IonSkeletonText className="data" animated />

      <CardEmBranco>
        <IonGrid>
          <IonRow>
            <IonCol size="1">
              <IonSkeletonText className="icone-grupo" animated />
            </IonCol>
            <IonCol size="11">
              <IonSkeletonText className="nome-grupo" animated />
            </IonCol>
          </IonRow>

          <IonRow className="acoes">
            <IonSkeletonText className="nome-acao" animated />
          </IonRow>

          <IonRow>
            <IonGrid className="lista-acoes">
              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </CardEmBranco>

      <IonSkeletonText className="data" animated />

      <CardEmBranco>
        <IonGrid>
          <IonRow>
            <IonCol size="1">
              <IonSkeletonText className="icone-grupo" animated />
            </IonCol>
            <IonCol size="11">
              <IonSkeletonText className="nome-grupo" animated />
            </IonCol>
          </IonRow>

          <IonRow className="acoes">
            <IonSkeletonText className="nome-acao" animated />
          </IonRow>

          <IonRow>
            <IonGrid className="lista-acoes">
              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText animated className="processo" />
              </IonRow>
              <IonRow>
                <IonSkeletonText animated className="processo" />
              </IonRow>
              <IonRow>
                <IonSkeletonText animated className="processo" />
              </IonRow>
              <IonRow>
                <IonSkeletonText animated className="processo" />
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </CardEmBranco>

      <IonSkeletonText className="data" animated />

      <CardEmBranco>
        <IonGrid>
          <IonRow>
            <IonCol size="1">
              <IonSkeletonText className="icone-grupo" animated />
            </IonCol>
            <IonCol size="11">
              <IonSkeletonText className="nome-grupo" animated />
            </IonCol>
          </IonRow>

          <IonRow className="acoes">
            <IonSkeletonText className="nome-acao" animated />
          </IonRow>

          <IonRow>
            <IonGrid className="lista-acoes">
              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="nome-acao-detalhes" animated />
              </IonRow>

              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
              <IonRow>
                <IonSkeletonText className="processo" animated />
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </CardEmBranco>

      <IonGrid>
        <IonRow className="lista-botoes">
          <IonCol>
            <IonSkeletonText className="botao" animated />
          </IonCol>

          <IonCol>
            <IonSkeletonText className="botao" animated />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItemGroup>
  </IonList>
);

export default Carregando