import { EXIBIR_DETALHES_DO_PROCESSO, OCULTAR_DETALHES_DO_PROCESSO } from '../../types';

export const exibirModalDeDetalhesDoProcesso = (processo?: Object) => ({
  processo,
  type: EXIBIR_DETALHES_DO_PROCESSO,
});

export const ocultarModalDeDetalhesDoProcesso = () => ({
  type: OCULTAR_DETALHES_DO_PROCESSO,
});
