import * as fromLogin from './login';
import * as fromGrupos from './grupos';
import * as fromModais from './modais';
import * as fromUsuario from './usuario';
import * as fromProcessos from './processos';
import * as fromDocumentos from './documentos';

export const { autenticar, logoutUsuario, salvarDadosDoUsuario, obterDadosConsumoUsuarioLogado } = fromLogin;

export const { exibirModalDeDetalhesDoProcesso, ocultarModalDeDetalhesDoProcesso } = fromModais;

export const {
  obterDetalhesDoProcesso,
  listarProcessosParaAdicionar,
  removerProcessoParaAdicionar,
  removerProcessosParaAdicionar,
  listarProcessosDoGrupoMinerario,
  removerProcessosDoGrupoMinerario,
} = fromProcessos;

export const { baixarDocumento } = fromDocumentos;

export const { criarGrupoMinerario, listarGruposMinerario, adicionarProcessosNoGrupo } = fromGrupos;

export const {
  assinaturaPremium,
  iniciarAssinatura,
  finalizarAssinaturaErro,
  finalizarAssinaturaSucesso,
} = fromUsuario;
