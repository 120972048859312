import React, { useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import {
  IonRow,
  IonPage,
  IonGrid,
  IonText,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonRippleEffect,
  IonSpinner,
  isPlatform,
  IonToast,
  IonSlides,
  IonSlide,
  IonFooter,
  IonContent,
  IonCheckbox,
} from '@ionic/react';

import cn from 'classnames';
import MaskedInput from 'react-input-mask';

import Texto from '../../components/Texto';
import CadastrarService from '../../services/CadastrarService';

import './index.css';

let slidesRef = {
  on: () => {},
  update: () => {},
  slideNext: () => {},
  slidePrev: () => {},
  allowSlideNext: false,
  allowSlidePrev: false,
  options: {
    allowSlideNext: false,
  },
};

const dataAtual = new Date();

const INITIAL_VALUES = {
  loading: false,
  revealPass: false,
  mostrarErro: false,
  cadastroComSucesso: false,
  erroMensagem: 'Ocorreu um erro ao tentar cadastrar. Tente mais tarde ou entre em contato com o nosso suporte!',
  dados: {
    nome: '',
    email: '',
    senha: '',
    empresa: '',
    telefone: '',
    termo: false,
  },
};

const TelefoneInput = (props: any) => {
  const { value, className, onIonChange, name } = props;

  return (
    <MaskedInput
      name={name}
      value={value}
      mask={value.replace(/(\.|\/|\(|-| |_|\)|)/gi, '').length > 10 ? '(99) 99999-9999' : '(99) 9999-99999'}
      onChange={(e) => {
        e.persist();
        onIonChange(e.target.value);
      }}
    >
      {() => (
        <div
          className={cn({
            hydrated: true,
            'input-jazida': true,
            ios: isPlatform('ios'),
            md: isPlatform('android'),
            'background-transparent': true,
            'sc-ion-input-md-h': isPlatform('android'),
            'sc-ion-input-md-s': isPlatform('android'),
            'sc-ion-input-ios-h': isPlatform('ios'),
            'sc-ion-input-ios-s': isPlatform('ios'),
            [className]: true,
          })}
        >
          <input
            placeholder="Telefone"
            className={cn({
              'native-input': true,
              'sc-ion-input-ios': isPlatform('ios'),
              'sc-ion-input-md': isPlatform('android'),
            })}
            type="text"
            onChange={onIonChange}
          />
        </div>
      )}
    </MaskedInput>
  );
};

const Cadastrar: React.FC = (props: any) => {
  const { control, handleSubmit, reset, formState, errors } = useForm({
    mode: 'onChange',
    defaultValues: { ...INITIAL_VALUES.dados },
  });

  const [dados, setDados] = useState(INITIAL_VALUES.dados);
  const [loading, setLoading] = useState(INITIAL_VALUES.loading);
  const [revealPass, setRevealPass] = useState(INITIAL_VALUES.revealPass);
  const [mostrarErro, setMostrarErro] = useState(INITIAL_VALUES.mostrarErro);
  const [erroMensagem, setErroMensagem] = useState(INITIAL_VALUES.erroMensagem);
  const [cadastroComSucesso, setCadastroComSucesso] = useState(INITIAL_VALUES.cadastroComSucesso);

  /**
   *
   * @param dados
   */
  const onSubmit = (dados: any) => {
    if (formState.isValid === false) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('cadastro_submit_form_invalid');
      }

      return;
    }

    if (!dados.termo) {
      setErroMensagem('Aceite os Termos e a Política de Privacidade antes de se cadastrar.');
      setMostrarErro(true);

      setTimeout(() => {
        setErroMensagem(INITIAL_VALUES.erroMensagem);
      }, 5000);

      return;
    }

    const cadastrar = new CadastrarService();

    setDados(dados);
    setLoading(true);

    const novosDados = {
      ...dados,
      telefone: dados.telefone.replace(/^\(|\)| |-|_/g, ''),
    };

    cadastrar.postCadastrar(novosDados).then((resposta) => {
      if (resposta.success) {
        slidesRef.slideNext();
        setCadastroComSucesso(true);
      } else {
        const {
          error: { response },
        } = resposta;

        setErroMensagem(response?.data?.erro);
        setMostrarErro(true);
        setLoading(INITIAL_VALUES.loading);

        return false;
      }

      reset();
    });
  };

  return (
    <IonPage className="cadastrar" id="cadastrar">
      <IonContent
        fullscreen
        class="content"
        className={cn({
          'android-app': isPlatform('android'),
          'sucesso-cadastro': cadastroComSucesso,
        })}
      >
        <IonSlides
          id="slides-login"
          ref={(node: any) => {
            slidesRef = node;
          }}
          options={{
            loop: false,
            initialSlide: 0,
            noSwiping: true,
            onlyExternal: false,
            allowTouchMove: false,
          }}
        >
          <IonSlide style={{ flexWrap: 'wrap' }}>
            <IonGrid style={{ width: '100%' }}>
              <IonRow>
                <div className="bg-verde"></div>
                <IonText className="titulo-header">
                  Cadastre-se gratuitamente <br /> <span>e monitore até 3 processos minerários gratuitamente</span>
                </IonText>
              </IonRow>

              <IonRow>
                <div className="jazida-logo">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/jazida-logo-512.png`} alt="Jazida.com" />
                  <IonText className="jazida-logo_tipo">calendário</IonText>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="background-transparent">
                  <IonList className="form" lines="none">
                    <IonItem
                      className={cn({
                        item: true,
                        'background-transparent': true,
                      })}
                      lines="none"
                    >
                      <Controller
                        name="nome"
                        as={IonInput}
                        control={control}
                        placeholder="Nome"
                        onChangeName="onIonChange"
                        className={cn({
                          'input-jazida': true,
                          'background-transparent': true,
                          'input-erro': formState.isSubmitted && errors?.nome,
                        })}
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </IonItem>

                    <IonItem
                      className={cn({
                        item: true,
                        'background-transparent': true,
                      })}
                      lines="none"
                    >
                      <Controller
                        name="empresa"
                        as={IonInput}
                        control={control}
                        placeholder="Empresa"
                        onChangeName="onIonChange"
                        className={cn({
                          'input-jazida': true,
                          'background-transparent': true,
                          'input-erro': formState.isSubmitted && errors?.empresa,
                        })}
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </IonItem>

                    <IonItem
                      lines="none"
                      className={cn({
                        item: true,
                        'background-transparent': true,
                      })}
                    >
                      <Controller
                        required
                        name="telefone"
                        control={control}
                        as={<TelefoneInput />}
                        placeholder="Telefone"
                        onChangeName="onIonChange"
                        className={cn({
                          'input-jazida': true,
                          'background-transparent': true,
                          'input-erro': formState.isSubmitted && errors?.telefone,
                        })}
                        onChange={([selected]) => {
                          return selected;
                        }}
                        rules={{
                          required: true,
                        }}
                      />
                    </IonItem>

                    <IonItem
                      lines="none"
                      className={cn({
                        item: true,
                        'background-transparent': true,
                      })}
                    >
                      <Controller
                        required
                        name="email"
                        as={IonInput}
                        control={control}
                        placeholder="E-mail"
                        onChangeName="onIonChange"
                        className={cn({
                          'input-jazida': true,
                          'background-transparent': true,
                          'input-erro': formState.isSubmitted && errors?.email,
                        })}
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email inválido',
                          },
                        }}
                      />
                    </IonItem>

                    <IonItem className="background-transparent" lines="none">
                      <Controller
                        name="senha"
                        as={IonInput}
                        control={control}
                        onChangeName="onIonChange"
                        type={revealPass ? 'text' : 'password'}
                        className={cn({
                          'input-jazida': true,
                          'input-jazida-senha': true,
                          'background-transparent': true,
                          'input-erro': formState.isSubmitted && errors?.senha,
                        })}
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                        }}
                      />

                      <IonButton
                        slot="end"
                        className="btn-reveal"
                        onClick={() => {
                          setRevealPass(!revealPass);
                        }}
                      >
                        <IonIcon slot="icon-only" icon={revealPass ? eyeSharp : eyeOffSharp} />
                      </IonButton>
                    </IonItem>

                    <IonItem className="background-transparent" lines="none">
                      <Controller
                        slot="start"
                        name="termo"
                        color="primary"
                        as={IonCheckbox}
                        control={control}
                        className={cn({
                          checkbox: true,
                          'checkbox-erro': formState.isSubmitted && errors?.termo,
                        })}
                        checked={dados.termo}
                        onChangeName="onIonChange"
                        onChange={([selected]) => {
                          return selected.detail.value;
                        }}
                        rules={{
                          required: true,
                        }}
                      />

                      <div className="label-container">
                        <Texto tipo="comum" texto="Você concorda com nossos" style={{ fontSize: '14px' }} />{' '}
                        <button
                          type="button"
                          className="btn-link"
                          onClick={() => {
                            if (window.firebase) {
                              window.firebase.analytics().logEvent('register_btn_termos');
                            }
                            window.open('https://www.jazida.com/termos-de-uso', '_system', 'location=yes');

                            return false;
                          }}
                        >
                          Termos
                        </button>{' '}
                        <Texto tipo="comum" texto="e que leu nossa" style={{ fontSize: '14px' }} />{' '}
                        <button
                          type="button"
                          className="btn-link"
                          onClick={() => {
                            if (window.firebase) {
                              window.firebase.analytics().logEvent('register_btn_privacidade');
                            }
                            window.open('https://www.jazida.com/privacidade', '_system', 'location=yes');

                            return false;
                          }}
                        >
                          Políticas de Privacidade
                        </button>
                      </div>
                    </IonItem>
                  </IonList>

                  <IonButton expand="full" className="btn-join" type="submit">
                    {loading ? <IonSpinner name="crescent" /> : 'Cadastrar'}
                    <IonRippleEffect type="unbounded"></IonRippleEffect>
                  </IonButton>
                </form>
              </IonRow>
            </IonGrid>

            <IonFooter className="ion-no-border footer">
              <IonText className="texto-login">
                Já tem cadastro?{' '}
                <IonButton
                  fill="outline"
                  className="btn-login"
                  onClick={() => {
                    if (window.firebase) {
                      window.firebase.analytics().logEvent('login_btn_cadastrar');
                    }

                    props.history.replace('/login');
                  }}
                >
                  Entrar
                </IonButton>
              </IonText>
            </IonFooter>
          </IonSlide>

          <IonSlide style={{ height: '100vh', flexWrap: 'wrap' }}>
            <IonGrid style={{ height: '80%', width: '100%' }}>
              <IonRow>
                <div className="bg-verde bg-second"></div>
                <IonText className="titulo-header">Cadastro realizado com sucesso</IonText>
              </IonRow>

              <IonRow style={{ height: '50vh' }}>
                <div className="verificar-email">
                  <h2>Verfique o seu e-mail!</h2>

                  <div className="texto">
                    <IonText>Enviamos um link de confirmação para seu e-mail:</IonText>
                    <IonText>{dados.email}</IonText>
                    <IonText>Acesse o seu e-mail para finalizar o cadastro.</IonText>
                  </div>
                </div>

                <IonButton
                  expand="full"
                  className="btn-login"
                  onClick={() => {
                    props.history.replace('/login');
                  }}
                >
                  Login
                  <IonRippleEffect type="unbounded"></IonRippleEffect>
                </IonButton>
              </IonRow>
            </IonGrid>

            <IonFooter className="footer">
              <IonText>© Todos os direitos reservados - Jazida {dataAtual.getFullYear()}.</IonText>
            </IonFooter>
          </IonSlide>
        </IonSlides>
      </IonContent>

      <IonToast
        animated
        color="danger"
        duration={4000}
        position="bottom"
        isOpen={mostrarErro}
        message={erroMensagem}
        cssClass="toast-mensagem"
        onDidDismiss={() => setMostrarErro(false)}
        buttons={[
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              setMostrarErro(false);
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default Cadastrar;
