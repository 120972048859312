import { ActionInterface } from '../../../../../interfaces';

import { map, uniq } from 'lodash';

import { LOGOUT_USUARIO_SUCCESS, LOGOUT_USUARIO_FAILURE, OBTER_GRUPOS_MINERARIO_SUCCESS } from '../../../../../types';

const INITIAL_STATE: Array<string> = [];

export default (state = INITIAL_STATE as any, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_GRUPOS_MINERARIO_SUCCESS:
      return uniq([...state, ...map(action.payload, 'id')]);

    case LOGOUT_USUARIO_SUCCESS:
    case LOGOUT_USUARIO_FAILURE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const obterGruposMinerario = (state: Array<string>) => state;
