import { ActionInterface } from '../../../interfaces';
import { INICIAR_ASSINATURA, INICIAR_ASSINATURA_SUCCESS, INICIAR_ASSINATURA_FAILURE } from '../../../types';

interface Assinatura {
  assinando: boolean;
}

const INITIAL_STATE = {
  assinando: false,
};

export default (state = INITIAL_STATE, action: ActionInterface) => {
  switch (action.type) {
    case INICIAR_ASSINATURA:
      return {
        ...state,
        assinando: true,
      };

    case INICIAR_ASSINATURA_SUCCESS:
    case INICIAR_ASSINATURA_FAILURE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export const estaAssinando = (state: Assinatura) => state.assinando;
