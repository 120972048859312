import {
  CRIAR_GRUPO_MINERARIO,
  CRIAR_GRUPO_MINERARIO_SUCCESS,
  CRIAR_GRUPO_MINERARIO_FAILURE,
  ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO,
  ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_SUCCESS,
  ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_FAILURE,
  OBTER_GRUPOS_MINERARIO,
  OBTER_GRUPOS_MINERARIO_SUCCESS,
  OBTER_GRUPOS_MINERARIO_FAILURE,
} from '../../types';
import { tratarErroNoRequeste } from '../../utils';

import GruposService from '../../services/GruposService';

const grupos = new GruposService();

interface CriarGrupoInterface {
  dispatch: any;
  dados: {
    cor: string;
    nome: string;
    descricao?: string;
  };
}

interface RespostaCriarGrupoInterface {
  data?: any;
  error?: any;
  success: boolean;
}

export const criarGrupoMinerario = ({
  dados,
  dispatch,
}: CriarGrupoInterface) => {
  dispatch({
    dados,
    type: CRIAR_GRUPO_MINERARIO,
  });

  return grupos.criar(dados).then((resp: RespostaCriarGrupoInterface) => {
    if (resp.success) {
      dispatch({
        payload: resp.data,
        type: CRIAR_GRUPO_MINERARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(dispatch({
      type: CRIAR_GRUPO_MINERARIO_FAILURE,
      erro: tratarErroNoRequeste(resp.error),
    }));
  });
};

interface AdicionarProcessosNoGrupo {
  dispatch: any;
  grupoId: number;
  processosId: Array<string>;
}

export const adicionarProcessosNoGrupo = ({
  grupoId,
  dispatch,
  processosId,
}: AdicionarProcessosNoGrupo) => {
  dispatch({
    grupoId,
    processosId,
    type: ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO,
  });

  return grupos.monitorar({ grupoId, processosId }).then((resp: RespostaCriarGrupoInterface) => {
    if (resp.success) {
      dispatch({
        grupoId,
        payload: resp.data,
        type: ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(dispatch({
      grupoId,
      erro: tratarErroNoRequeste(resp.error),
      type: ADICIONAR_PROCESSOS_NO_GRUPO_MINERARIO_FAILURE,
    }));
  });
}

interface ListarGruposMinerarioInterface {
  dispatch: any;
}

export const listarGruposMinerario = ({
  dispatch,
}: ListarGruposMinerarioInterface) => {
  dispatch({
    type: OBTER_GRUPOS_MINERARIO,
  });

  return grupos.listar().then((resp: RespostaCriarGrupoInterface) => {
    if (resp.success) {
      dispatch({
        payload: resp.data,
        type: OBTER_GRUPOS_MINERARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(dispatch({
      type: OBTER_GRUPOS_MINERARIO_FAILURE,
      erro: tratarErroNoRequeste(resp.error),
    }));
  });
}