import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonRow,
  IonPage,
  IonGrid,
  IonText,
  IonList,
  IonItem,
  IonCard,
  IonLabel,
  IonToast,
  IonHeader,
  IonSelect,
  IonContent,
  IonLoading,
  IonCardHeader,
  IonCardContent,
  IonSelectOption,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonButtons,
  IonBackButton,
  IonToolbar,
} from '@ionic/react';

import { url } from '../../utils';
import { map, size, isObject } from 'lodash';
import { chevronBackOutline } from 'ionicons/icons';
import {
  obterGrupo as obterGrupoReducer,
  obterProcesso as obterProcessoReducer,
  obterGruposMinerario as obterGruposMinerarioReducer,
  obterDadosDoUsuarioLogado as obterDadosDoUsuarioLogadoReducer,
  obterProcessoParaAdicionar as obterProcessoParaAdicionarReducer,
  estaCarregandoGrupoMinerario as estaCarregandoGrupoMinerarioReducer,
  obterProcessosDoGruposMinerario as obterProcessosDoGruposMinerarioReducer,
  estaRemovendoProcessoDoGrupoMinerario as estaRemovendoProcessoDoGrupoMinerarioReducer,
  estaCarregandoProcessosDoGrupoMinerario as estaCarregandoProcessosDoGrupoMinerarioReducer,
} from '../../reducers';
import {
  salvarDadosDoUsuario as salvarDadosDoUsuarioAction,
  listarGruposMinerario as listarGruposMinerarioAction,
  adicionarProcessosNoGrupo as adicionarProcessosNoGrupoAction,
  removerProcessoParaAdicionar as removerProcessoParaAdicionarAction,
  listarProcessosParaAdicionar as listarProcessosParaAdicionarAction,
  removerProcessosParaAdicionar as removerProcessosParaAdicionarAction,
  obterDadosConsumoUsuarioLogado as obterDadosConsumoUsuarioLogadoAction,
  listarProcessosDoGrupoMinerario as listarProcessosDoGrupoMinerarioAction,
  removerProcessosDoGrupoMinerario as removerProcessosDoGrupoMinerarioAction,
} from '../../actions';

import Carregando from './Carregando';
import Texto from '../../components/Texto';
import ListaProcessos from './ListaProcessos';
import BuscarProcessos from './BuscarProcessos';
import AuthService from '../../services/AuthService';

import './index.css';

const Auth = new AuthService();
// const ID_RELACAO_TITULAR = 1;

interface MensagensInterface {
  tipo: string;
  mensagem: string;
  mostrar: boolean;
  adicionarProcesso: {
    tipo: string;
    mensagem: string;
    mostrar: boolean;
  };
}

interface GrupoInterface {
  cor: string;
  label: string;
  value?: number;
  quantidadeDeProcessos?: number | null;
}

const INITIAL_STATE = {
  grupo: {
    nome: '',
    label: '',
    cor: '#2bb673',
    value: undefined,
    quantidadeDeProcessos: null,
  },
  grupos: [
    {
      value: '',
      label: '',
    },
  ],
  monitorar: {
    carregando: false,
  },
  mensagens: {
    adicionarProcesso: {
      mensagem: '',
      mostrar: false,
      tipo: 'danger',
    },
    removendoProcesso: {
      mensagem: '',
      mostrar: false,
      tipo: 'danger',
    },
    mensagem: '',
    tipo: 'danger',
    mostrar: false,
  },
  processosDoGrupo: [],
};

const GerenciarProcessos: React.FC = (props: any) => {
  const {
    history,
    obterGrupo,
    obterProcesso,
    gruposMinerario,
    salvarDadosDoUsuario,
    listarGruposMinerario,
    processosParaAdicionar,
    adicionarProcessosNoGrupo,
    estaCarregandoGrupoMinerario,
    listarProcessosParaAdicionar,
    removerProcessosParaAdicionar,
    obterDadosConsumoUsuarioLogado,
    listarProcessosDoGrupoMinerario,
    obterProcessosDoGruposMinerario,
    removerProcessosDoGrupoMinerario,
    estaRemovendoProcessoDoGrupoMinerario,
    estaCarregandoProcessosDoGrupoMinerario,
  } = props;

  const dispatch = useDispatch();
  const [grupos, setGrupos] = useState<Array<unknown>>(INITIAL_STATE.grupos);
  const [mensagens, setMensagens] = useState<MensagensInterface>(INITIAL_STATE.mensagens);
  const [grupoSelecionado, setGrupoSelecionado] = useState<GrupoInterface>(INITIAL_STATE.grupo);

  const processosIds = obterProcessosDoGruposMinerario(grupoSelecionado.value);

  useIonViewWillEnter(async () => {
    const isLogged = await Auth.isLogged();
    obterDadosConsumoUsuarioLogado({ dispatch });
    listarGruposMinerario({ dispatch });

    if (!isLogged) {
      if (window.firebase) {
        window.firebase.analytics().logEvent('gerenciar_processos_logout');
      }
      Auth.logout();
      history.replace('/login');
    } else {
      salvarDadosDoUsuario({}, dispatch);

      if (window.firebase) {
        window.firebase.analytics().logEvent('gerenciar_processos');
      }
    }
  });

  useIonViewDidLeave(() => {
    setGrupos(INITIAL_STATE.grupos);
    setGrupoSelecionado(INITIAL_STATE.grupo);
    // setAdicionarprocessos(INITIAL_STATE.adicionarProcesso);
  });

  useEffect(() => {
    if (size(gruposMinerario) > 0) {
      const opcoes = map(gruposMinerario, (grupoId: string) => {
        const grupo = obterGrupo(grupoId);
        return {
          cor: grupo.cor,
          value: grupoId,
          label: grupo.nome,
          quantidadeDeProcessos: grupo.quantidadeDeProcessos,
        };
      });

      setGrupos(opcoes);
    }
  }, [gruposMinerario]);

  useEffect(() => {
    if (grupoSelecionado.value) {
      // Usando IIFE
      (async () => {
        await listarProcessosDoGrupoMinerario({ dispatch, grupoId: grupoSelecionado.value });
      })();
    }
  }, [grupoSelecionado]);

  return (
    <IonPage className="gerenciar-processos">
      <IonContent>
        <IonHeader className="header-default">
          <div className="bg-verde"></div>
          <IonToolbar className="toolbar">
            <IonButtons slot="start">
              <IonBackButton className="btn-back" defaultHref="/" text="Voltar" icon={chevronBackOutline} />
            </IonButtons>
          </IonToolbar>

          <IonText className="titulo-header">Monitorar processos</IonText>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonList style={{ width: '96%' }}>
              <IonItem>
                <IonLabel className="label-default ion-text-wrap">Selecione o grupo:</IonLabel>
                <IonSelect
                  okText="OK"
                  cancelText="Cancelar"
                  value={grupoSelecionado}
                  onIonChange={(e: any) => {
                    if (isObject(e.detail.value)) {
                      setGrupoSelecionado(e.detail.value);
                    }
                  }}
                >
                  {map(grupos, (grupo: GrupoInterface) => (
                    <IonSelectOption key={grupo.value} value={grupo}>
                      {grupo.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              {grupoSelecionado.value && (
                <BuscarProcessos
                  dispatch={dispatch}
                  mensagens={mensagens}
                  setMensagens={setMensagens}
                  grupoSelecionado={grupoSelecionado}
                  processosParaAdicionar={processosParaAdicionar}
                  adicionarProcessosNoGrupo={adicionarProcessosNoGrupo}
                  listarProcessosParaAdicionar={listarProcessosParaAdicionar}
                  removerProcessosParaAdicionar={removerProcessosParaAdicionar}
                  listarProcessosDoGrupoMinerario={listarProcessosDoGrupoMinerario}
                />
              )}

              {estaCarregandoProcessosDoGrupoMinerario ? (
                <Carregando />
              ) : (
                <ListaProcessos
                  dispatch={dispatch}
                  mensagens={mensagens}
                  processosIds={processosIds}
                  obterProcesso={obterProcesso}
                  grupoSelecionado={grupoSelecionado}
                  setMensagens={(dados: any) => setMensagens(dados)}
                  removerProcessosDoGrupoMinerario={removerProcessosDoGrupoMinerario}
                  estaRemovendoProcessoDoGrupoMinerario={estaRemovendoProcessoDoGrupoMinerario}
                />
              )}

              {grupoSelecionado.quantidadeDeProcessos === 0 && size(processosParaAdicionar) === 0 && (
                <IonCard style={{ boxShadow: 'none' }}>
                  <IonCardHeader>
                    <div
                      className="icon-monitorar-processos"
                      style={{
                        backgroundColor: grupoSelecionado.cor,
                        backgroundImage: url(`${process.env.PUBLIC_URL}/assets/images/monitorar-processo.png`),
                      }}
                    />
                  </IonCardHeader>

                  <IonCardContent style={{ fontSize: '18px', textAlign: 'center' }}>
                    <Texto
                      style={{ fontSize: '18px', textAlign: 'center' }}
                      texto="Você ainda não está monitorando nenhum processo. Adicione um processo e comece a monitorar."
                    />
                  </IonCardContent>
                </IonCard>
              )}
            </IonList>
          </IonRow>
        </IonGrid>

        <IonLoading message={'Carregando...'} cssClass="carregando-dados" isOpen={estaCarregandoGrupoMinerario} />
      </IonContent>

      <IonToast
        animated
        duration={4000}
        position="bottom"
        color={mensagens.tipo}
        cssClass="toast-mensagem"
        isOpen={mensagens.mostrar}
        message={mensagens.mensagem}
        onDidDismiss={() => {
          setMensagens(INITIAL_STATE.mensagens);
        }}
        buttons={[
          {
            text: 'Fechar',
            role: 'cancel',
            handler: () => {
              setMensagens(INITIAL_STATE.mensagens);
            },
          },
        ]}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: State) => ({
  usuario: obterDadosDoUsuarioLogadoReducer(state),
  gruposMinerario: obterGruposMinerarioReducer(state),
  obterGrupo: (id: string) => obterGrupoReducer(state, id),
  obterProcesso: (id: string) => obterProcessoReducer(state, id),
  processosParaAdicionar: obterProcessoParaAdicionarReducer(state),
  estaCarregandoGrupoMinerario: estaCarregandoGrupoMinerarioReducer(state),
  estaCarregandoProcessosDoGrupoMinerario: estaCarregandoProcessosDoGrupoMinerarioReducer(state),
  obterProcessosDoGruposMinerario: (grupoId: number) => obterProcessosDoGruposMinerarioReducer(state, grupoId),
  estaRemovendoProcessoDoGrupoMinerario: ({ grupoId, processoId }: any) =>
    estaRemovendoProcessoDoGrupoMinerarioReducer({ state, grupoId, processoId }),
});

const mapActionToProps = () => ({
  salvarDadosDoUsuario: salvarDadosDoUsuarioAction,
  listarGruposMinerario: listarGruposMinerarioAction,
  adicionarProcessosNoGrupo: adicionarProcessosNoGrupoAction,
  removerProcessoParaAdicionar: removerProcessoParaAdicionarAction,
  listarProcessosParaAdicionar: listarProcessosParaAdicionarAction,
  removerProcessosParaAdicionar: removerProcessosParaAdicionarAction,
  obterDadosConsumoUsuarioLogado: obterDadosConsumoUsuarioLogadoAction,
  listarProcessosDoGrupoMinerario: listarProcessosDoGrupoMinerarioAction,
  removerProcessosDoGrupoMinerario: removerProcessosDoGrupoMinerarioAction,
});

export default connect(mapStateToProps, mapActionToProps)(GerenciarProcessos);
