import {
  OBTER_DETALHES_DO_PROCESSO,
  OBTER_DETALHES_DO_PROCESSO_SUCCESS,
  OBTER_DETALHES_DO_PROCESSO_FAILURE,
  OBTER_PROCESSOS_PARA_ADICIONAR,
  OBTER_PROCESSOS_PARA_ADICIONAR_SUCCESS,
  OBTER_PROCESSOS_PARA_ADICIONAR_FAILURE,
  REMOVER_PROCESSO_PARA_ADICIONAR,
  REMOVER_PROCESSOS_PARA_ADICIONAR,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
} from '../../types';
import { tratarErroNoRequeste } from '../../utils';

import ProcessosService from '../../services/ProcessosService';

const processos = new ProcessosService();

type processoType = {
  self_url: string;
};

interface ObterDetalhesDoProcessoInterface {
  dispatch: any;
  processo: processoType;
}

export const obterDetalhesDoProcesso = ({ processo, dispatch }: ObterDetalhesDoProcessoInterface) => {
  dispatch({ type: OBTER_DETALHES_DO_PROCESSO });

  return processos.obterDetalhesDoProcesso({ path: processo.self_url }).then(
    (resp) => {
      dispatch({
        payload: resp.data,
        type: OBTER_DETALHES_DO_PROCESSO_SUCCESS,
        processoId: resp.data.processoId || `${resp.data.idFormatado}-${resp.data.orgaoLicenciadorId}`,
      });

      return Promise.resolve(resp);
    },
    (erros) => {
      dispatch({
        erro: erros,
        type: OBTER_DETALHES_DO_PROCESSO_FAILURE,
      });

      return Promise.reject(erros);
    },
  );
};

interface ListarProcessosParaAdicionarInterface {
  dispatch: any;
  processos: Array<unknown>;
}

export const listarProcessosParaAdicionar = ({
  dispatch,
  processos: processosArray,
}: ListarProcessosParaAdicionarInterface) => {
  dispatch({ type: OBTER_PROCESSOS_PARA_ADICIONAR });

  return processos.listarProcessos({ processos: processosArray }).then((resp) => {
    if (resp.success) {
      dispatch({
        payload: resp.data,
        type: OBTER_PROCESSOS_PARA_ADICIONAR_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(
      dispatch({
        erro: tratarErroNoRequeste(resp.error),
        type: OBTER_PROCESSOS_PARA_ADICIONAR_FAILURE,
      }),
    );
  });
};

interface RemoverProcessoParaAdicionarInterface {
  dispatch: any;
  processoId: string;
}

export const removerProcessoParaAdicionar = ({ dispatch, processoId }: RemoverProcessoParaAdicionarInterface) => {
  dispatch({
    processoId,
    type: REMOVER_PROCESSO_PARA_ADICIONAR,
  });
};

export const removerProcessosParaAdicionar = ({ dispatch }: RemoverProcessoParaAdicionarInterface) => {
  dispatch({
    type: REMOVER_PROCESSOS_PARA_ADICIONAR,
  });
};

interface ListarProcessosDoGrupoMinerarioInterface {
  dispatch: any;
  grupoId: number;
}

export const listarProcessosDoGrupoMinerario = ({ grupoId, dispatch }: ListarProcessosDoGrupoMinerarioInterface) => {
  dispatch({
    grupoId,
    type: OBTER_PROCESSOS_DO_GRUPO_MINERARIO,
  });

  return processos.listarProcessosDoGrupoMinerario({ grupoId }).then((resp: any) => {
    if (resp.success) {
      dispatch({
        grupoId,
        payload: resp.data,
        type: OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    return Promise.reject(
      dispatch({
        grupoId,
        erro: tratarErroNoRequeste(resp.error),
        type: OBTER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
      }),
    );
  });
};

interface RemoverProcessosDoGrupoMinerario {
  dispatch: any;
  grupoId: number;
  processosId: Array<string>;
}

export const removerProcessosDoGrupoMinerario = ({
  grupoId,
  dispatch,
  processosId,
}: RemoverProcessosDoGrupoMinerario) => {
  dispatch({
    grupoId,
    processosId,
    type: REMOVER_PROCESSOS_DO_GRUPO_MINERARIO,
  });

  return processos.removerProcessosDoGrupoMinerario({ grupoId, processosId }).then((resp: any) => {
    if (resp.success) {
      dispatch({
        grupoId,
        processosId,
        payload: resp.data,
        type: REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
      });

      return Promise.resolve(resp);
    }

    dispatch({
      grupoId,
      processosId,
      erro: tratarErroNoRequeste(resp.error),
      type: REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
    });

    return Promise.reject({
      erro: tratarErroNoRequeste(resp.error),
    });
  });
};
