import React, { FC, useState, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import {
  IonRow,
  IonCol,
  IonItem,
  IonGrid,
  IonLabel,
  IonInput,
  IonButton,
  IonPopover,
  IonSpinner,
  isPlatform,
} from '@ionic/react';

import cn from 'classnames';
import { size, map } from 'lodash';
import MaskedInput from 'react-input-mask';
import { obterProcessosDeTexto } from '../../../utils';

interface SaibaMaisInterface {
  mostrar: boolean;
  evento: Event | undefined;
}

interface AdicionarProcessoInterface {
  mensagem: string;
  carregando: boolean;
}

interface BuscarProcessosPropsInterface {
  dispatch: any;
  setMensagens: (dados: any) => unknown;
  processosParaAdicionar: Array<unknown>;
  adicionarProcessosNoGrupo: (params: any) => any;
  listarProcessosParaAdicionar: (params: any) => any;
  removerProcessosParaAdicionar: (params: any) => any;
  listarProcessosDoGrupoMinerario: (params: any) => any;
  mensagens: {
    mostrar: boolean;
  };
  grupoSelecionado: {
    cor: string;
    value?: number;
  };
}

const INITIAL_VALUES = {
  grupo: '',
  processos: '',
};

const INITIAL_STATE = {
  saibaMais: {
    mostrar: false,
    evento: undefined,
  },
  adicionarProcesso: {
    mensagem: '',
    carregando: false,
  },
};

const BuscarProcessos: FC<BuscarProcessosPropsInterface> = ({
  dispatch,
  mensagens,
  setMensagens,
  grupoSelecionado,
  processosParaAdicionar,
  adicionarProcessosNoGrupo,
  listarProcessosParaAdicionar,
  removerProcessosParaAdicionar,
  listarProcessosDoGrupoMinerario,
}: BuscarProcessosPropsInterface) => {
  const { control, formState, errors, reset, getValues, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: { ...INITIAL_VALUES },
  });
  const [saibaMais, setSaibaMais] = useState<SaibaMaisInterface>(INITIAL_STATE.saibaMais);
  const [adicionarProcessos, setAdicionarprocessos] = useState<AdicionarProcessoInterface>(
    INITIAL_STATE.adicionarProcesso,
  );

  const monitorarProcessosSelecionados = () => {
    const mensagemErroMonitorar = () => {
      setMensagens({
        ...mensagens,
        mostrar: true,
        tipo: 'danger',
        mensagem: 'Ocorreu um erro ao tentar monitorar os Processo :(! Entre em contato com o suporte.',
      });
    };

    if (size(processosParaAdicionar) === 0) {
      setMensagens({
        ...mensagens,
        mostrar: true,
        tipo: 'warning',
        mensagem: 'Adicione ao menos 1 processo para monitorar!',
      });

      return;
    }

    adicionarProcessosNoGrupo({
      dispatch,
      grupoId: grupoSelecionado.value,
      processosId: map(processosParaAdicionar, (processo: any) => processo.id),
    }).then(
      (resposta: any) => {
        setAdicionarprocessos({
          ...adicionarProcessos,
          carregando: false,
        });

        if (resposta.success) {
          removerProcessosParaAdicionar({ dispatch });
          listarProcessosDoGrupoMinerario({ dispatch, grupoId: grupoSelecionado.value });
          reset();

          return;
        }

        mensagemErroMonitorar();
      },
      (erros: any) => {
        setMensagens({
          ...mensagens,
          mostrar: true,
          tipo: 'danger',
          mensagem: erros.erro,
        });
      },
    );
  };

  const adicionarNovoProcesso = () => {
    const dados = getValues();
    const resp = obterProcessosDeTexto(dados.processos);
    let { processos } = resp;

    if (!processos) return;

    setAdicionarprocessos({
      ...adicionarProcessos,
      carregando: true,
      mensagem: resp.mensagemLimiteProcessos || '',
    });

    processos = [...processos.validos, ...processos.invalidos];

    if (processos.length === 0) return;

    listarProcessosParaAdicionar({ processos, dispatch }).catch((erros: any) => {
      setAdicionarprocessos({
        ...adicionarProcessos,
        carregando: false,
      });

      setMensagens({
        ...mensagens,
        mostrar: true,
        tipo: 'danger',
        mensagem: erros.erro,
      });
    });
  };

  const ProcessoInput = (props: any) => {
    const { value, className, onIonChange, name } = props;

    return (
      <MaskedInput
        name={name}
        value={value}
        mask="999.999/9999"
        onChange={(e) => {
          e.persist();
          onIonChange(e.target.value);
        }}
      >
        {() => (
          <div
            className={cn({
              hydrated: true,
              'input-jazida': true,
              ios: isPlatform('ios'),
              md: isPlatform('android'),
              'background-transparent': true,
              'sc-ion-input-md-h': isPlatform('android'),
              'sc-ion-input-md-s': isPlatform('android'),
              'sc-ion-input-ios-h': isPlatform('ios'),
              'sc-ion-input-ios-s': isPlatform('ios'),
              [className]: true,
            })}
          >
            <input
              name={name}
              type="text"
              onChange={onIonChange}
              placeholder="Ex: 800.001/2016"
              className={cn({
                'native-input': true,
                'sc-ion-input-ios': isPlatform('ios'),
                'sc-ion-input-md': isPlatform('android'),
              })}
            />
          </div>
        )}
      </MaskedInput>
    );
  };

  useEffect(() => {
    if (size(processosParaAdicionar) > 0) {
      monitorarProcessosSelecionados();
    }
  }, [processosParaAdicionar]);

  return (
    <form onSubmit={handleSubmit(adicionarNovoProcesso)} className="background-transparent">
      <IonItem lines="none" text-wrap>
        <IonLabel className="label-default ion-text-wrap">
          Informe o número do processo
          <>
            <IonPopover
              cssClass="popover"
              event={saibaMais.evento}
              isOpen={saibaMais.mostrar}
              onDidDismiss={() => {
                setSaibaMais(INITIAL_STATE.saibaMais);
              }}
            >
              <p>Insira o número do processo (ex: 800001/2016) e clique no botão &quot;Monitorar&quot;.</p>
            </IonPopover>

            <IonButton
              type="button"
              fill="outline"
              className="btn-link no-border-radius"
              onClick={(e: any) => {
                setSaibaMais({
                  ...saibaMais,
                  mostrar: true,
                  evento: e.nativeEvent,
                });
              }}
            >
              Saiba mais
            </IonButton>
          </>
        </IonLabel>
      </IonItem>

      <IonItem lines="none">
        <IonGrid className="no-padding">
          <IonRow className="no-padding esconder-elemento">
            <IonCol className="no-padding" size="9">
              <Controller
                as={IonInput}
                name="processos2"
                control={control}
                onChangeName="onIonChange"
                placeholder="Ex: 800.001/2016"
                className={cn({
                  'input-jazida': true,
                  'background-transparent': true,
                  'input-erro': formState.isSubmitted && errors?.processos,
                })}
                onChange={([selected]) => {
                  return selected.detail.value;
                }}
                rules={{
                  required: true,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow className="no-padding">
            <IonCol className="no-padding" size="9">
              <Controller
                name="processos"
                control={control}
                as={<ProcessoInput />}
                onChangeName="onIonChange"
                placeholder="Ex: 800.001/2016"
                className={cn({
                  'input-jazida': true,
                  'background-transparent': true,
                  'input-erro': formState.isSubmitted && errors?.processos,
                })}
                onChange={([selected]) => {
                  return selected;
                }}
                rules={{
                  required: true,
                }}
              />
            </IonCol>

            <IonCol className="vertical-full no-padding" size="3">
              <IonButton
                fill="solid"
                expand="block"
                onClick={adicionarNovoProcesso}
                class="btn-vertical-full no-border-radius"
              >
                {adicionarProcessos.carregando ? <IonSpinner name="crescent" /> : 'MONITORAR'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </form>
  );
};

export default BuscarProcessos;
