import { ActionInterface } from '../../../../interfaces';

export default (exibir: string, ocultar: string) => (state = false, action: ActionInterface) => {
  switch (action.type) {
    case exibir:
      return true;

    case ocultar:
      return false;

    default:
      return state;
  }
};