import { ActionInterface } from '../../../interfaces';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action: ActionInterface) => {
  switch (action.type) {
    case "ADD_AVENGERS_SERIE":
      return state;

    default:
      return state;
  }
};