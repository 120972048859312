import React, { FC } from 'react';

import { IonRow, IonImg, IonIcon } from '@ionic/react';

import { map, size } from 'lodash';

import { CardEmBranco } from '../../../components/Card';
import Texto from '../../../components/Texto';
import Acao from './Acao';
import Evento from './Evento';
import DocumentoSei from './DocumentoSei';

import './index.css';

const GrupoMinerario: FC<GrupoMinerarioType> = ({
  indice,
  tamanho,
  grupoMinerario,
}: GrupoMinerarioType) => {
  const { cor, nome, acoes, eventos, documentosSei } = grupoMinerario;
  const { eventosDou, eventosCm } = eventos;

  const getClassNameDoCard = (index: any) => {
    // Caso seja o primeiro ou último grupo, terá estilo diferente
    // Caso seja card único
    if (tamanho === 1) return 'gm-card-principal-unico';
    // Caso seja o primeiro
    else if (index === 0) return 'gm-card-principal-primeiro';
    // Caso seja o último
    else if (index === tamanho - 1) return 'gm-card-principal-ultimo';
    // Intermediário
    else return 'gm-card-principal';
  };

  return (
    <CardEmBranco className={getClassNameDoCard(indice)}>
      <div className='gm-linha-titulo'>
        <div
          style={{ backgroundColor: cor }}
          className='gm-coluna-titulo-espaco-minimo'
        >
          <IonIcon
            icon={`${process.env.PUBLIC_URL}/assets/icon/svg/icone_pasta.svg`}
            className='gm-icone-pasta-titulo'
            style={{ fill: cor }}
          />
        </div>

        <div className='gm-coluna-titulo-espaco-maximo'>
          <Texto
            texto={nome}
            tipo='titulo'
            style={{ color: cor }}
            className="titulo-grupo"
          />
        </div>

        <div className='gm-coluna-titulo-espaco-minimo'>
          <IonImg
            src={`${process.env.PUBLIC_URL}/assets/images/poligono-minerario.png`}
            className='estilo-icone'
            style={{ backgroundColor: cor }}
          />
        </div>
      </div>

      <IonRow className='gm-linha-acoes'>
        {size(acoes) > 0 && (
          <IonRow className='container-acoes-eventos-calendario'>
            <IonRow className='subtitulo-acoes-eventos'>
              <Texto texto='Ações' tipo='subtitulo' />
            </IonRow>

            {map(acoes, (acao: any, index: Number) => (
              <Acao acao={acao} cor={cor} key={acao.id || index} />
            ))}
          </IonRow>
        )}

        {size(eventosDou) > 0 && (
          <IonRow className='container-acoes-eventos-calendario'>
            <IonRow className='subtitulo-acoes-eventos'>
              <Texto texto='Eventos DOU' tipo='subtitulo' />
            </IonRow>

            {map(eventosDou, (eventoDou: any, index: Number) => (
              <Evento
                evento={eventoDou}
                cor={cor}
                key={eventoDou.id || index}
              />
            ))}
          </IonRow>
        )}

        {size(eventosCm) > 0 && (
          <IonRow className='container-acoes-eventos-calendario'>
            <IonRow className='subtitulo-acoes-eventos'>
              <Texto texto='Eventos Cadastro mineiro' tipo='subtitulo' />
            </IonRow>

            {map(eventosCm, (eventoCm: any, index: Number) => (
              <Evento evento={eventoCm} cor={cor} key={eventoCm.id || index} />
            ))}
          </IonRow>
        )}

        {size(documentosSei) > 0 && (
          <IonRow className='container-acoes-eventos-calendario'>
            <IonRow className='subtitulo-acoes-eventos'>
              <Texto texto='Documentos SEI' tipo='subtitulo' />
            </IonRow>

            {map(documentosSei, (tipo: any, index: Number) => (
              <DocumentoSei tipo={tipo} cor={cor} key={tipo.id || index} />
            ))}
          </IonRow>
        )}
      </IonRow>
    </CardEmBranco>
  );
};

type GrupoMinerarioType = {
  indice?: any;
  tamanho?: any;
  grupoMinerario: any;
};

export default GrupoMinerario;
