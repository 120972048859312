import { ActionInterface } from '../../../interfaces';

import { keyBy } from 'lodash';

import {
  OBTER_DETALHES_DO_PROCESSO_SUCCESS,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
} from '../../../types';

export default (state = {} as any, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_DETALHES_DO_PROCESSO_SUCCESS: {
      return {
        ...state,
        [action.processoId]: {
          ...state[action.processoId],
          ...action.payload,
        }
      };
    }

    case OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS: {
      return {
        ...state,
        ...keyBy(action.payload, 'processoId'),
      }
    }

    default:
      return state;
  }
};