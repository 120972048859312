import React, { FC } from 'react';

import Tag from './Tag';

import './index.css';
const Tags: FC<TagsTypes> = ({ tags, cor }: TagsTypes) => {
  let tagsParseadas: any = [];

  // Se tiver mais que uma tag
  if (tags.length > 1) {
    const tamanhoDuasPrimeirasTags = tags[0].processoAmbientalId
      ? tags[0]?.tag?.nome?.length + tags[1]?.tag?.nome?.length
      : tags[0]?.nome?.length + tags[1]?.nome?.length;

    if (tamanhoDuasPrimeirasTags >= 10) {
      tagsParseadas = [];
      [0, 1].forEach((numero: any) => {
        const tag = tags[numero];

        if (tag.processoAmbientalId) {
          tag.nome = tag?.tag?.nome
        }

        return tagsParseadas.push(tags[numero]);
      });

      tagsParseadas[1].nome = `+${tags.length - 1}...`;
    } else {
      tagsParseadas = tags;
    }
  } else {
    tagsParseadas = tags;
  }

  return (
    <div className='componente-tags-div'>
      {tagsParseadas.map((tag: any, index: number) => (
        <Tag tag={tag} cor={cor} key={index} />
      ))}
    </div>
  );
};

type TagsTypes = {
  tags: any;
  cor: any;
};

export default Tags;
