import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';

import { exibirModalDeDetalhesDoProcesso as exibirModalDeDetalhesDoProcessoAction } from '../../actions';

import Texto from '../Texto';

import './index.css';

const LinkProcesso: FC<LinkProcessoProps> = (props: LinkProcessoProps) => {
  const { style, processoMinerario, processoAmbiental, exibirModalDeDetalhesDoProcesso } = props;
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="btn-outline"
      onClick={() => {
        if (processoMinerario && processoMinerario.self_url) {
          dispatch(exibirModalDeDetalhesDoProcesso(processoMinerario));
        } else if (processoAmbiental && processoAmbiental.self_url) {
          dispatch(exibirModalDeDetalhesDoProcesso(processoAmbiental));
        }
      }}
    >
      {processoMinerario && (
        <Texto
          tipo="comum"
          style={style}
          texto={processoMinerario.processoIdFormatado || processoMinerario.idFormatado || ''}
        />
      )}

      {processoAmbiental && <Texto style={style} texto={processoAmbiental.numeroFormatado || ''} tipo="comum" />}
    </button>
  );
};

LinkProcesso.defaultProps = {
  style: {},
  processoMinerario: {
    processoId: '',
    idFormatado: '',
    processoIdFormatado: '',
  },
  processoAmbiental: {
    idFormatado: '',
    numeroFormatado: '',
  },
};

type ExibirModalDeDetalhesType = (processo: Record<string, any>) => any;
type ProcessoMinerarioType = {
  self_url?: string;
  processoId?: string;
  idFormatado?: string;
  eMinerario?: boolean;
  processoIdFormatado?: string;
};
type ProcessoAmbientalType = {
  self_url?: string;
  idFormatado: string;
  numeroFormatado: string;
};

interface LinkProcessoProps {
  style?: Record<string, unknown>;
  processoMinerario?: ProcessoMinerarioType;
  processoAmbiental?: ProcessoAmbientalType;
  exibirModalDeDetalhesDoProcesso: ExibirModalDeDetalhesType;
}

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  exibirModalDeDetalhesDoProcesso: exibirModalDeDetalhesDoProcessoAction,
});

export default connect(mapStateToProps, mapActionToProps)(LinkProcesso);
