import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IonContent, IonPage, IonSlides, IonSlide, useIonViewWillEnter } from '@ionic/react';

import { salvarDadosDoUsuario as salvarDadosDoUsuarioAction } from '../../actions';

import AuthService from '../../services/AuthService';
import OnboardingService from '../../services/OnboardingService';

import './index.css';

let slidesRef: any = {
  on: () => {},
  update: () => {},
  slideNext: () => {},
  slidePrev: () => {},
  allowSlideNext: false,
  allowSlidePrev: false,
  options: {
    allowSlideNext: false,
  },
};

const Onboarding: React.FC = (props: any) => {
  const { history, salvarDadosDoUsuario } = props;

  const dispatch = useDispatch();

  const Auth = new AuthService();
  const [final, setFinal] = useState(false);
  const [mostrarOnboarding, setMostrarOnborading] = useState(false);
  const OnboardingServ = new OnboardingService();

  useIonViewWillEnter(async () => {
    const isLogged = await Auth.isLogged();
    const verOnboarding = await OnboardingServ.getFirstAccess();

    if (isLogged) {
      history.replace('/calendar');
      salvarDadosDoUsuario({}, dispatch);
    } else if (verOnboarding) {
      history.replace('/login');
    } else {
      setMostrarOnborading(true);
    }

    if (window.firebase) {
      window.firebase.analytics().logEvent('onboarding');
    }
  });

  return (
    <IonPage className="onboarding">
      {mostrarOnboarding && (
        <IonContent fullscreen>
          <IonSlides
            onIonSlideDidChange={() => {
              setTimeout(async () => {
                const end = await slidesRef?.isEnd();

                setFinal(end);
              });
            }}
            ref={(node: any) => {
              slidesRef = node;
            }}
            class="slides"
            pager
          >
            <IonSlide>
              <div className="header">
                <i
                  className="icone icone-estrategico"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/icon/icone-jazida_estrategico-branco.png')`,
                  }}
                />
              </div>

              <div className="content">
                <p>
                  O objetivo do Jazida Calendário é proporcionar segurança e dinamismo no controle dos processos
                  minerários e ambientais monitorados por você no Jazida.com.
                </p>
              </div>
            </IonSlide>

            <IonSlide>
              <div className="header">
                <i
                  className="icone icone-organizado"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/icon/icone-jazida_organizado-branco.png')`,
                  }}
                />
              </div>

              <div className="content">
                {/* <h2>Caléndário</h2> */}
                <p>
                  Rapidez e praticidade ao alcance da mão. Fique ciente de prazos, publicações no D.O.U., novos
                  despachos (SEI) e eventos no Cadastro Mineiro.
                </p>
              </div>
            </IonSlide>

            <IonSlide>
              <div className="header">
                <i
                  className="icone icone-corporativo"
                  style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/assets/icon/icone-jazida_corporativo-branco.png')`,
                  }}
                />
              </div>

              <div className="content">
                <p>
                  O andamento dos processos de seu interesse em tempo real diretamente em um calendário semanal no
                  celular. E o melhor: automático e sincronizado com a sua conta no Jazida.com.
                </p>
              </div>
            </IonSlide>
          </IonSlides>

          <button
            type="button"
            onClick={async () => {
              await OnboardingServ.setFirstAccess();
              if (window.firebase) {
                window.firebase.analytics().logEvent('onboarding_closed');
              }
              props.history.replace('/login');
            }}
            id="pular"
          >
            {final ? 'Fechar' : 'Pular'}
          </button>
        </IonContent>
      )}
    </IonPage>
  );
};

const mapStateToProps = () => ({});

const mapActionToProps = () => ({
  salvarDadosDoUsuario: salvarDadosDoUsuarioAction,
});

export default connect(mapStateToProps, mapActionToProps)(Onboarding);
