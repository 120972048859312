import { ActionInterface } from '../../../../../interfaces';

import { map, uniq, remove } from 'lodash';

import {
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
  OBTER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS,
  REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE,
  LOGOUT_USUARIO_FAILURE,
  LOGOUT_USUARIO_SUCCESS,
} from '../../../../../types';

const INITIAL_STATE: unknown = {
  removendo: [],
  carregando: false,
};

export default (state = INITIAL_STATE as any, action: ActionInterface) => {
  switch (action.type) {
    case OBTER_PROCESSOS_DO_GRUPO_MINERARIO:
      return {
        ...state,
        carregando: true,
      };

    case OBTER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS: {
      const novosValores = map(action.payload, 'processoId');

      return {
        ...state,
        carregando: false,
        [action.grupoId]: [
          ...uniq(
            state[action.grupoId]
              ? [...state[action.grupoId], ...novosValores]
              : [...map(action.payload, 'processoId')],
          ),
        ],
      };
    }

    case OBTER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE:
      return {
        ...state,
        carregando: false,
      };

    case REMOVER_PROCESSOS_DO_GRUPO_MINERARIO:
      return {
        ...state,
        removendo: [...state.removendo, ...action.processosId],
      };

    case REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_SUCCESS: {
      const novoState = { ...state };

      remove(novoState.removendo, (id: string) => action.processosId.includes(id));
      remove(novoState[action.grupoId], (id: string) => action.processosId.includes(id));

      return novoState;
    }

    case REMOVER_PROCESSOS_DO_GRUPO_MINERARIO_FAILURE: {
      const novoState = { ...state };

      remove(novoState.removendo, (id: string) => action.processosId.includes(id));

      return novoState;
    }

    case LOGOUT_USUARIO_SUCCESS:
    case LOGOUT_USUARIO_FAILURE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const estaCarregandoProcessosDoGrupoMinerario = (state: any) => state.carregando;
export const obterProcessosDoGruposMinerario = (state: any, grupoId: number) => state[grupoId];

interface RemovendoProcessoDoGrupoMinerarioInterface {
  state: any;
  grupoId?: number;
  processoId: string;
}

export const estaRemovendoProcessoDoGrupoMinerario = ({
  state,
  processoId,
}: RemovendoProcessoDoGrupoMinerarioInterface) => {
  return state.removendo.includes(processoId);
};
