import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  IonRow,
  IonImg,
  IonGrid,
  IonIcon,
  IonModal,
  IonTitle,
  IonLabel,
  IonHeader,
  IonButton,
  IonToolbar,
  IonContent,
  IonButtons,
  IonSkeletonText,
} from '@ionic/react';

import moment from 'moment';
import { find } from 'lodash';
import template from 'url-template';
import { closeOutline } from 'ionicons/icons';

import { obterDetalhesDoProcesso as obterDetalhesDoProcessoAction } from '../../actions';
import {
  obterProcesso as obterProcessoReducer,
  obterDadosDaModalDetalhesDoProcesso as obterDadosDaModalDetalhesDoProcessoReducer,
} from '../../reducers';

import Texto from '../Texto';
import Carregando from './Carregando';

import './index.css';

type onFecharModalType = () => any;
type obterDetalhesDoProcessoType = (dados: any) => any;
type verticesTypes = {
  lat?: number;
  lng?: number;
  latGMS?: string;
  lngGMS?: string;
};

type ProcessosType = {
  processo: {
    cor: string;
    grupoId: string;
    processoId: string;
    eMinerario: boolean;
    processoIdFormatado: string;
    grupo: {
      cor: string;
    };
    grupos: Array<{
      grupoId: string;
    }>;
    processo: {
      mapa_url: string;
      eMinerario: boolean;
      nupFormatado: string;
      areaFormatada: string;
      ativoFormatado: string;
      dataDeProtocoloFormatada: string;
      processosAssociados: Array<unknown>;
      ultimoTipoDeRequerimentoFormatado: string;
      poligonos: Array<{
        vertices: Array<verticesTypes>;
      }>;
      ultimaFase: {
        nome: string;
      };
      regime: {
        nome: string;
      };
      substancias: Array<{
        substancia: unknown;
      }>;
      superintendencia: {
        estado: string;
      };
      municipios: Array<{
        estado: string;
        nomeFormatado: string;
      }>;
      titulos: Array<{
        numero: number;
        tipo: {
          nome: string;
        };
      }>;
      pessoas: Array<{
        relacaoId: number;
        pessoa: {
          nome: string;
          registroNacionalFormatada: string;
        };
      }>;
    };
    // Processo Ambiental
    criadoPor: unknown;
    idFormatado: string;
    eAmbiental: boolean;
    numeroFormatado: string;
  };
};
interface DetalhesDoProcessoProps {
  mostrarDetalhes: boolean;
  dados: ProcessosType;
  onFecharModal: onFecharModalType;
  obterProcesso: (id: string) => unknown;
  obterDetalhesDoProcesso: obterDetalhesDoProcessoType;
}

const DetalhesDoProcesso: FC<DetalhesDoProcessoProps> = ({
  dados,
  onFecharModal,
  mostrarDetalhes,
  obterDetalhesDoProcesso,
}: DetalhesDoProcessoProps) => {
  const { processo: processoDados } = dados;
  const dispatch = useDispatch();
  const [carregando, setCarregando] = useState(true);
  const [processo, setProcesso] = useState(processoDados);
  let grupo = {} as any;
  const { processo: dadosDoProcesso } = processo;
  let processoAmbiental;

  if (processo.eAmbiental) {
    processoAmbiental = processo;
  }

  if (processo.grupos) {
    grupo = find(processo.grupos, (grupo) => grupo.grupoId === processo.grupoId);
  }

  const onFetchDados = () => {
    setCarregando(true);
    obterDetalhesDoProcesso({
      dispatch,
      processo: processoDados,
    }).then(
      (resp: any) => {
        setProcesso({
          ...processoDados,
          ...resp.data,
        });

        setCarregando(false);
      },
      () => {
        //@TODO: tratar erro;
      },
    );
  };

  const dadosDaModal = (conteudoProcesso: any) => {
    const {
      regime,
      eAmbiental,
      municipios,
      ultimaFase,
      substancias,
      nupFormatado,
      areaFormatada,
      ativoFormatado,
      orgaoLicenciador,
      pessoaLicenciada,
      superintendencia,
      processosMinerarios,
      processosAssociados,
      informacoesAdicionais,
      dataDeProtocoloFormatada,
      ultimoTipoDeRequerimentoFormatado,
    } = conteudoProcesso;
    const { eMinerario } = processo;

    const mapaUrl = template.parse(conteudoProcesso.mapa_url || '').expand({
      size: '300x300',
      maptype: 'terrain',
    });

    let responsavelTecnico, requerente, quantidadeTitulos, titulo, conteudoUltimoTitulo;

    if (eMinerario) {
      responsavelTecnico = conteudoProcesso.pessoas.find((p: any) => p.relacaoId === 2 || p.relacaoId === 6);
      requerente = conteudoProcesso.pessoas.find((p: any) => p.relacaoId === 1);

      quantidadeTitulos = conteudoProcesso?.titulos?.length;
      titulo =
        quantidadeTitulos && conteudoProcesso?.titulos[0]?.numero === 0
          ? conteudoProcesso.titulos[1]
          : conteudoProcesso.titulos && conteudoProcesso?.titulos[0];
      conteudoUltimoTitulo = titulo ? [titulo.numero.toString(), titulo.tipo?.nome] : '-';
    }

    return (
      <>
        {eMinerario && (
          <IonGrid className="background-padrao">
            <IonRow style={{ justifyContent: 'center' }}>
              <IonImg className="poligono-imagem" src={mapaUrl} />
            </IonRow>
          </IonGrid>
        )}

        <IonGrid className="background-padrao">
          <div className="dados">
            {eMinerario && (
              <>
                <div className="item">
                  <IonLabel className="label">Nup</IonLabel>
                  <Texto className="dado" texto={nupFormatado || '-'} tipo="comum" />
                </div>
                <div className="item">
                  <IonLabel className="label">Responsável Técnico</IonLabel>
                  <Texto
                    className="dado"
                    texto={
                      responsavelTecnico
                        ? [responsavelTecnico.pessoa.nome, responsavelTecnico.pessoa.registroNacionalFormatada]
                        : '-'
                    }
                    tipo="comum"
                  ></Texto>
                </div>

                <div className="item">
                  <IonLabel className="label">Último Título</IonLabel>
                  <Texto className="dado" texto={conteudoUltimoTitulo || '-'} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Requerente</IonLabel>
                  <Texto
                    className="dado"
                    texto={requerente ? [requerente.pessoa.nome, requerente.pessoa.registroNacionalFormatada] : '-'}
                    tipo="comum"
                  />
                </div>

                <div className="item">
                  <IonLabel className="label">Área</IonLabel>
                  <Texto className="dado" texto={areaFormatada} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Ativo</IonLabel>
                  <Texto className="dado" texto={ativoFormatado} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Data de Protocolo</IonLabel>
                  <Texto className="dado" texto={dataDeProtocoloFormatada} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">{municipios.length === 1 ? 'Município' : 'Municípios'}</IonLabel>
                  <Texto
                    className="dado"
                    texto={
                      municipios.length ? municipios.map((m: any) => (m ? `${m.nomeFormatado}/${m.estado}` : '-')) : '-'
                    }
                    tipo="comum"
                  />
                </div>

                <div className="item">
                  <IonLabel className="label">Superintendência</IonLabel>
                  <Texto className="dado" texto={superintendencia ? superintendencia.estado : '-'} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">{substancias.length === 1 ? 'Substância' : 'Substâncias'}</IonLabel>
                  {substancias.map((substancia: any) => (
                    <div key={substancia.id} className="substancia">
                      <div className="poligono-svg">
                        <IonImg
                          className="estilo-icone"
                          src={`${process.env.PUBLIC_URL}/assets/images/poligono-minerario.png`}
                          style={{ backgroundColor: substancia.substancia.commodity.cor }}
                        />
                      </div>

                      <Texto className="dado" texto={substancia.substancia.nomeFormatado} tipo="comum" />
                    </div>
                  ))}
                </div>

                <div className="item">
                  <IonLabel className="label">Tipo de Requerimento</IonLabel>
                  <Texto className="dado" texto={ultimoTipoDeRequerimentoFormatado} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Fase</IonLabel>
                  <Texto className="dado" texto={ultimaFase?.nome} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Regime Minerário</IonLabel>
                  <Texto className="dado" texto={regime?.nome?.replace('Regime de', '')} tipo="comum" />
                </div>
              </>
            )}

            {eAmbiental && (
              <>
                {processosMinerarios?.length > 0 && (
                  <div className="item">
                    <IonLabel className="label">Processo minerário</IonLabel>
                    <Texto
                      className="dado"
                      texto={
                        processosMinerarios.map((minerario: any) => minerario.processoMinerario.idFormatado) || '-'
                      }
                      tipo="comum"
                    />
                  </div>
                )}

                <div className="item">
                  <IonLabel className="label">Empresa licenciada</IonLabel>

                  {pessoaLicenciada?.pessoaPersonalizada ? (
                    <Texto
                      className="dado"
                      texto={`${pessoaLicenciada.pessoaPersonalizada.nome} ${
                        pessoaLicenciada.pessoaPersonalizada.identificador
                          ? `- identificador: ${pessoaLicenciada.pessoaPersonalizada.identificador}`
                          : ''
                      }`}
                      tipo="comum"
                    />
                  ) : (
                    <Texto
                      className="dado"
                      texto={`${pessoaLicenciada.pessoa.nome} - ${pessoaLicenciada.pessoa.tipo}: ${pessoaLicenciada.pessoa.registroNacionalFormatada}`}
                      tipo="comum"
                    />
                  )}
                </div>

                <div className="item">
                  <IonLabel className="label">UF</IonLabel>
                  <Texto className="dado" texto={conteudoProcesso.estado} tipo="comum" />
                </div>

                <div className="item">
                  <IonLabel className="label">Data de Protocolo</IonLabel>
                  <Texto className="dado" texto={conteudoProcesso.dataDeProtocoloFormatada} tipo="comum" />
                </div>

                {orgaoLicenciador && (
                  <div className="item">
                    <IonLabel className="label">Orgão licenciador</IonLabel>
                    <Texto className="dado" texto={orgaoLicenciador.nome} tipo="comum" />
                  </div>
                )}

                {conteudoProcesso.descricao && (
                  <div className="item">
                    <IonLabel className="label">Descrição</IonLabel>
                    <Texto className="dado" texto={conteudoProcesso.descricao} tipo="comum" />
                  </div>
                )}

                {informacoesAdicionais &&
                  informacoesAdicionais.map((informacao: any) => (
                    <div className="item" key={`${informacao.id}-${informacao.campoTipadoId}-li`}>
                      <IonLabel className="label">{informacao.titulo}</IonLabel>
                      <Texto className="dado" texto={informacao.valorFormatado} tipo="comum" />
                    </div>
                  ))}
              </>
            )}
          </div>
        </IonGrid>

        {processosAssociados?.length > 0 && (
          <IonGrid className="background-padrao">
            <h3 className="titulo">Processos Associados</h3>

            {processosAssociados.map((associado: any) => {
              const eOriginal = associado.processoOriginalId === processo.processoId;
              const processoIdFormatado = eOriginal
                ? associado.processoAssociadoIdFormatado
                : associado.processoOriginalIdFormatado;

              return (
                <div className="dados" key={`associados-${associado.processoAssociadoIdFormatado}`}>
                  <div className="item">
                    <IonLabel className="label">Processo {eOriginal ? 'associado' : 'original'}</IonLabel>
                    <Texto className="dado" texto={processoIdFormatado} tipo="comum" />
                  </div>

                  <div className="item">
                    <IonLabel className="label">Data da Associação</IonLabel>
                    <Texto
                      className="dado"
                      texto={moment(associado.dataAssociacao).format('DD/MM/YYYY')}
                      tipo="comum"
                    />
                  </div>

                  <div className="item">
                    <IonLabel className="label">Tipo de Associação</IonLabel>
                    <Texto className="dado" texto={associado.tipoAssociacao.nome} tipo="comum" />
                  </div>
                </div>
              );
            })}
          </IonGrid>
        )}
      </>
    );
  };

  return (
    <IonModal
      swipeToClose
      cssClass="modal-detalhes"
      isOpen={mostrarDetalhes}
      onWillPresent={() => {
        onFetchDados();
      }}
      onWillDismiss={() => {
        onFecharModal();
      }}
    >
      <IonHeader style={{ backgroundColor: processoDados.cor || grupo?.grupo?.cor }} translucent>
        <IonToolbar style={{ '--background': processoDados.cor || grupo?.grupo?.cor }}>
          <span className="swipe-close"></span>
          {carregando ? (
            <IonSkeletonText animated className="skeleton-titulo" />
          ) : (
            <IonTitle className="texto-branco">
              {processo.eMinerario ? processo.processoIdFormatado : processo.numeroFormatado}
            </IonTitle>
          )}
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onFecharModal();
              }}
            >
              <IonIcon className="icon-color-white" slot="start" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="detalhes-conteudo">
        {carregando ? <Carregando /> : dadosDaModal(dadosDoProcesso || processoAmbiental)}
      </IonContent>
    </IonModal>
  );
};

const mapStateToProps = (state: State) => ({
  dados: obterDadosDaModalDetalhesDoProcessoReducer(state),
  obterProcesso: (id: string) => obterProcessoReducer(state, id),
});

const mapActionToProps = () => ({
  obterDetalhesDoProcesso: obterDetalhesDoProcessoAction,
});

export default connect(mapStateToProps, mapActionToProps)(DetalhesDoProcesso);
